<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
    :style="
      this.$store.state.auth.userType === 'PARTY'
        ? 'margin-left: 0px'
        : $route.name == 'Routing'
        ? 'margin-left: 0px'
        : this.$store.state.auth.userType === 'VENDOR'
        ? 'margin-left: 0px'
        : 'margin-left: 0px'
    "
  >
    <!-- <notifications></notifications> -->
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <!-- <user-menu></user-menu> -->
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'dashboard',
            path: '/party-dashboard',
          }"
        />

        <sidebar-item closed :link="{ name: 'Information', icon: 'I' }">
          <sidebar-item
            :link="{
              name: 'Party Details',
              path: '/party/party-details',
              icon: 'dashboard',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Update Info',
              path: '/party/update-info',
            }"
          />
          <sidebar-item
            :link="{
              name: 'Party Help Desk',
              path: '/customer/query',
            }"
          />
          <!-- <sidebar-item
            :link="{
              name: 'Upload Document',
              path: '/party/upload-document',
            }"
          /> -->
        </sidebar-item>

        <!-- <sidebar-item
          closed
          :link="{ name: 'Vendor', icon: 'V' }"
          v-if="partyType === 'BOTH' || partyType === 'VENDOR'"
        >
          <sidebar-item
            v-for="(menu, index) in vendorMenus"
            :link="{ name: menu.title, path: menu.url }"
            :key="`${index}-vendor`"
          />
        </sidebar-item> -->
        <sidebar-item
          closed
          :link="{ name: 'Vendor', icon: 'V' }"
          v-if="partyType === 'BOTH' || partyType === 'VENDOR'"
        >
          <sidebar-item
            v-for="(menu, index) in partyVendorMenus"
            :link="{ name: menu.title, path: menu.url }"
            :key="`${index}-vendor`"
          />
        </sidebar-item>
        <sidebar-item
          closed
          :link="{ name: 'Customer', icon: 'C' }"
          v-if="partyType === 'BOTH' || partyType === 'CUSTOMER'"
        >
          <sidebar-item
            v-for="(menu, index) in customerMenus"
            :link="{ name: menu.title, path: menu.url }"
            :key="index"
          />
        </sidebar-item>
        <!-- <sidebar-item closed :link="{ name: 'Document', icon: 'D' }">
          <sidebar-item
            v-for="(menu, index) in documentsMenu"
            :link="{ name: menu.title, path: menu.url }"
            :key="index"
          />
        </sidebar-item> -->
        <sidebar-item closed :link="{ name: 'Settings', icon: 'S' }">
          <sidebar-item
            v-for="(menu, index) in settings"
            :link="{ name: menu.title, path: menu.url }"
            :key="index"
          />
        </sidebar-item>
      </template>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <div :class="{ content: !$route.meta.hideContent }">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view />
        </zoom-center-transition>
      </div>
      <!-- <content-footer v-if="!$route.meta.hideFooter"></content-footer> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
// import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import SidebarItem from "../../../components/SidebarPlugin/SidebarItem.vue";
// import FixedPlugin from "../../FixedPlugin.vue";
// import UserMenu from "./Extra/UserMenu.vue";

export default {
  components: {
    TopNavbar,
    // ContentFooter,
    // FixedPlugin,
    MobileMenu,
    SidebarItem,
    // UserMenu,
  },
  data() {
    return {
      vendorMenus: [
        {
          // title: "Invoice Dashboard",
          title: "Invoice Report",
          url: "/vendor/invoice",
        },
        // {
        //   title: "Unit Details",
        //   url: "/vendor/unit-details",
        // },
        // {
        //   title: "Vendor Ledger",
        //   url: "/vendor/ledger",
        // },
        {
          // title: "Vendor Payment",
          title: "Payment Report",
          url: "/vendor/payment",
        },
        {
          // title: "PO Details",
          title: "PO Report",
          url: "/vendor/po-details",
        },
        {
          title: "Vendor Invoice Entry",
          url: "/vendor/invoice-entry",
        },
        // {
        //   title: "ASN",
        //   url: "/vendor/asn",
        // },
        {
          title: "Vendor Onboarding",
          url: "/vendor/party-onboarding",
        },
        {
          title: "Advance Report",
          url: "/vendor/advance-report",
        },
        // {
        //   title: "TDS Certificate",
        //   url: "/vendor/tds-certificate",
        // },
        // {
        //   title: "RFQ/Sourcing",
        //   url: "/vendor/sourcing",
        // },
      ],
      partyVendorMenus: [
        {
          title: "Invoice Dashboard",
          url: "/vendor-invoice",
        },
        {
          title: "Unit Details",
          url: "/vendor-unit-details",
        },
        {
          title: "Vendor Ledger",
          url: "/vendor-ledger",
        },
        // {
        //   title: "ASN",
        //   url: "/vendor-asn",
        // },
        {
          title: "TDS Certificate",
          url: "/vendor-tds-certificate",
        },
        {
          title: "PO Details",
          url: "/vendor-po-details",
        },
        {
          title: "RFQ/Sourcing",
          url: "/vendor-sourcing",
        },
        {
          title: "Recovery Invoice",
          url: "/vendor-recovery-invoice",
        },
      ],

      customerMenus: [
        {
          title: "Customer Dashboard",
          url: "/customer/dashboard",
        },
        // {
        //   title: "Unit Details",
        //   url: "/customer/unit-details",
        // },
        {
          title: "Ledger",
          url: "/customer/ledger",
        },
        {
          title: "TDS Certificate",
          url: "/customer/tds-certificate",
        },
        // {
        //   title: "Mutation",
        //   url: "/customer/mutation",
        // },
        // {
        //   title: "Dunning Notice",
        //   url: "/customer/dunning-notice",
        // },
        // {
        //   title: "Customer Query",
        //   url: "/customer/query",
        // },
      ],
      documentsMenu: [
        {
          title: "Document Upload",
          url: "/document/document-upload",
        },
      ],
      settings: [
        {
          title: "Change Password",
          url: "/settings/change-password",
        },
      ],
      partyType: null,
      sidebarBackgroundColor: "black",
      sidebarBackground: "green",
      sidebarBackgroundImage:
        process.env.VUE_APP_BASE_URL + "/img/sidebar-2.jpg",
      sidebarMini: true,
      sidebarImg: true,
      image: process.env.VUE_APP_BASE_URL + "/img/laravel-vue.svg",
      userType: null,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
  },
  updated() {
    this.userType = sessionStorage.getItem("userType");
    reinitScrollbar();
  },
  mounted() {
    this.userType = sessionStorage.getItem("userType");
    this.partyType = sessionStorage.getItem("partyType");
    reinitScrollbar();
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
