<template>
  <b-row>
    <md-card-content>
      <div class="overlayLoader" v-if="loader">
        <md-progress-spinner
          :md-diameter="100"
          class="md-accent spinner-postion"
          :md-stroke="7"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
      <b-alert
        style="width:50%;"
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
      >
        {{ responseMsg }}
        <!-- {{ "Please Select Below Certificates :-" }}
        <p v-for="(message, index) in responseMsg" :key="index">
          {{ message.id + ". " + message.iso_name }}
        </p> -->
      </b-alert>
      <div class="md-layout-item">
        <b-table
          style="max-height:400px;"
          striped
          hover
          outlined
          sticky-header
          :items="complianceData"
          :fields="complianceFields"
          show-empty
          class="bg-white"
        >
          <template #cell(iso_name)="data">
            <span class="mandatory">{{ data.item.iso_name }}</span>
          </template>
          <template #cell(is_applicable)="data">
            <md-field>
              <md-input
                v-model="data.item.is_applicable"
                :title="data.item.is_applicable"
                placeholder="Kindly Enter Is Applicable"
                readonly
                @click="openValueSetModal('PARTY_REQ_APPLICABLE', data.index)"
                :disabled="!editMode || data.item.document_det_id > 0"
              >
              </md-input>
            </md-field>
          </template>

          <template #cell(expiry_date)="data">
            <span v-if="data.item.document_det_id">{{
              data.item.expiry_date
            }}</span>
            <date-picker
              v-else-if="data.item.id !== 1 && data.item.serial_no !== 1"
              v-model="data.item.expiry_date"
              format="DD-MMM-YYYY"
              valueType="format"
              :disabled-date="disabledDates"
              :disabled="
                !data.item.is_applicable ||
                  data.item.is_applicable == 'NO' ||
                  !editMode
              "
            ></date-picker>
          </template>

          <template #cell(upload_document)="data">
            <div v-if="data.item.serial_no === 1 || data.item.id === 1">
              <md-field
                v-if="
                  data.item.is_applicable === 'YES' &&
                    data.item.compliance_id &&
                    !data.item.document_det_id
                "
                md-clearable
              >
                <md-file
                  v-model="data.item.upload_document"
                  @md-change="selectFile"
                />
              </md-field>
              <a
                style="cursor:pointer;"
                @click="
                  getOpenKmAuthenticate(
                    data.item.compliance_id,
                    data.item.document_det_id
                  )
                "
                >{{ data.item.original_file_name }}</a
              >
            </div>
            <div v-else-if="data.item.serial_no !== 1 || data.item.id !== 1">
              <md-field
                v-if="
                  data.item.expiry_date &&
                    data.item.compliance_id &&
                    !data.item.document_det_id
                "
                md-clearable
              >
                <md-file
                  v-model="data.item.upload_document"
                  @md-change="selectFile"
                />
              </md-field>
              <a
                v-if="data.item.document_det_id"
                style="cursor:pointer;"
                @click="
                  getOpenKmAuthenticate(
                    data.item.compliance_id,
                    data.item.document_det_id
                  )
                "
                >{{ data.item.original_file_name }}</a
              >
            </div>
          </template>
          <template #cell(save_document)="data">
            <a
              style="cursor:pointer;"
              v-if="data.item.upload_document"
              @click="getOpenKmAuthenticate(data.item.compliance_id)"
              >Upload</a
            >
          </template>

          <template
            #cell(delete)="data"
            v-if="approvalStatus === 'DRAFT' || approvalStatus === 'REJECTED'"
          >
            <span
              v-if="data.item.document_det_id"
              class="material-icons"
              style="cursor:pointer;"
              @click="getOpenKmAuthenticateDelete(data.item.document_det_id)"
            >
              delete
            </span>
          </template>
        </b-table>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-button
          class="md-raised md-primary search-btn"
          @click="editModeCompliance()"
          v-if="!editMode"
          >Edit</md-button
        >
        <md-button
          class="md-raised md-primary search-btn"
          style="margin-left:1rem;"
          @click="complianceValidation()"
          v-if="!isComplianceSaved"
          >Save</md-button
        >
      </div>

      <span style="color:red; float:right; margin-top:-3rem;">
        <b>*Maximum file size 15 MB</b><br />
        <b>*only pdf, jpg, jpeg, png, zip file allowed</b><br />
        <b>*Kindly enter Is Applicable for all certificates</b>
      </span>

      <valueSetModal
        v-if="showValueSetModal"
        @selectedvalueSet="selectedvalueSet"
        @closeValueSetModal="closeValueSetModal"
      ></valueSetModal>
    </md-card-content>
  </b-row>
</template>

<script>
import appStrings from "../../../utility/string.utility";
import URL_UTILITY from "../../../utility/url.utility";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  name: "PartyOnboardingCompliance",
  props: ["complianceRowDetails", "approvalStatus"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isComplianceSaved: false,
      complianceData: [
        {
          compliance_id: null,
          iso_name: null,
          is_applicable: null,
          expiry_date: null,
          upload_document: null,
        },
      ],
      complianceFields: [
        {
          key: "iso_name",
          label: "Certificate Name",
        },
        {
          key: "is_applicable",
        },
        {
          key: "expiry_date",
        },
        {
          key: "upload_document",
        },
        {
          key: "save_document",
        },
        {
          key: "delete",
        },
      ],
      openKmAuth: null,
      fileRef: null,
      menuId: 374,
      submenuId: 81,
      compId: null,
      isDocumentUploaded: false,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      isApplicable: null,
      showValueSetModal: false,
      specIndex: null,
      uploadDocList: [],
    };
  },
  mounted() {
    if (this.complianceRowDetails) {
      this.complianceFields.unshift({
        key: "serial_no",
        label: "Serial No.",
      });
      this.getVendorComplianceDetails();
      this.editMode = false;
      this.isComplianceSaved = true;
    } else {
      this.complianceFields.unshift({
        key: "id",
        label: "Serial No.",
      });
      this.getComplianceData();
      this.editMode = true;
    }
  },
  methods: {
    getOpenKmAuthenticateDelete(docDetailId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.deleteDocument(docDetailId, this.openKmAuth);
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.getVendorComplianceDetails();
              this.$emit("updatedList");
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.PARTY_REQ_APPLICABLE) {
        this.complianceData[this.specIndex].is_applicable = item.value_code;
        this.complianceData[this.specIndex].expiry_date = null;
      }
      this.complianceData = JSON.parse(JSON.stringify(this.complianceData));
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    complianceValidation() {
      const isApplicableNull = this.complianceData.filter(
        (data) => !data.is_applicable
      );
      if (isApplicableNull.length) {
        const certificateMsg = "Please Select Below Certificates :\n";
        let validationMsg = "";
        isApplicableNull.forEach((item) => {
          validationMsg += item.id + ". " + item.iso_name + "\n";
        });
        alert(certificateMsg + validationMsg);
      } else {
        const isExpiryDateNull = this.complianceData.filter(
          (elem, index) =>
            elem.is_applicable == "YES" && !elem.expiry_date && index !== 0
        );
        if (isExpiryDateNull.length) {
          const expiryMsg = "Please Enter Expiry Date For:\n";
          let validationMsg = "";
          isExpiryDateNull.forEach((item) => {
            validationMsg += item.id + ". " + item.iso_name + "\n";
          });
          alert(expiryMsg + validationMsg);
        } else this.addEditComplianceDetails();
      }
    },
    getOpenKmAuthenticate(complianceId, docDtlId) {
      this.compId = complianceId;
      if (docDtlId) {
        this.isDocumentUploaded = true;
      } else {
        this.isDocumentUploaded = false;
      }
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.complianceValidation();
              this.uploadFile();
            } else {
              window.location.href =
                this.downloadDocUrl +
                "/download/" +
                docDtlId +
                "?token=" +
                this.openKmAuth;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectFile(event) {
      if (event[0].size <= 15728640) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 5 MB.";
      }
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.compId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.isComplianceSaved = true;
              this.getVendorComplianceDetails();
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
    getVendorComplianceDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorComplianceDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.complianceData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    editModeCompliance() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isComplianceSaved = false;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    getComplianceData() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getComplianceData")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.complianceData = resp.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    removeExpiryDate(item) {
      if (!item.is_applicable) {
        item.expiry_date = null;
      }
    },
    addEditComplianceDetails() {
      const complianceDetails = this.complianceData.map((elem) => {
        return {
          compliance_id: elem.compliance_id ? elem.compliance_id : 0,
          iso_name: elem.iso_name,
          is_applicable: elem.is_applicable,
          expiry_date: elem.expiry_date,
        };
      });
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
        party_compliance_req_details: complianceDetails
          ? complianceDetails
          : null,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/addEditComplianceDetails", payload)
        .then((response) => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.complianceFields.shift();
            this.complianceFields.unshift({
              key: "serial_no",
              label: "Serial No.",
            });
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.complianceData = response.data.data;
            this.isComplianceSaved = true;
            this.$emit("updatedList");
            this.getVendorComplianceDetails();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    disabledDates(date) {
      const restrictedDate = new Date();
      restrictedDate.setHours(0, 0, 0, 0);
      return date < restrictedDate;
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 85% !important;
  height: 26px !important;
}
.md-field {
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  margin: 0px 0 0 !important;
  min-height: auto !important;
  width: 80% !important;
}
</style>
