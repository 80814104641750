<template>
  <div style="background-color: lightgrey">
    <md-card>
      <div style="margin-left: 70rem" v-if="$route.name !== componentName">
        <md-button
          class="md-raised md-success search-btn"
          @click="openAddressModal(true)"
          >Add Address</md-button
        >
      </div>
      <md-card-header
        v-if="$route.name !== componentName"
        class="md-card-header-text md-layout-item md-card-header-green"
        style="margin-top: -29px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Existing Address</h5>
        </div>
      </md-card-header>
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="gridData"
          :fields="gridField"
          show-empty
          class="bg-white"
          @row-clicked="rowSelected($event, 'exist')"
        >
        </b-table>
        <b-pagination
          v-model="existCurrentPage"
          :total-rows="existTotalRows"
          :per-page="existPerPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        v-model="showModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        title="Add Address"
        @close="closeHandle"
      >
        <addAddress
          :addressDetails="addressDetails"
          @updatedAddressResponse="updatedAddressResponse"
          @deleteAddressById="setPpIdAddress"
          :addressPpId="addressPpId"
          @saveButtonCloseModal="uploadEnabled = false"
          :recordType="recordType"
        />
      </b-modal>
    </md-card>
    <md-card v-if="$route.name !== componentName">
      <md-card-content>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
          style="margin-top: 3px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 160px; padding: 3px !important"
          >
            <h5 class="title">Updated Address</h5>
          </div>
        </md-card-header>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="updateData"
            :fields="updateField"
            show-empty
            class="bg-white"
            @row-clicked="rowSelected($event, 'update')"
          >
            <template #cell(upload)="data">
              <a class="info; pointer" @click="getOpenKmAuthenticate(data.item)"
                >View</a
              >
            </template>
          </b-table>
          <b-pagination
            v-model="updateCurrentPage"
            :total-rows="updateTotalRows"
            :per-page="updatePerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
        <b-modal
          v-model="showHistory"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
          size="xl"
          title="Upload Document"
          @close="closeHandleUploadDocument"
        >
          <!-- @hidden="showHideHistory(false, null)" -->
          <uploadDocument
            :openKmAuth="openKmAuth"
            @closeOpenKmModal="showHideHistory(false, null)"
            :propMenuId="menuId"
            :PropSubMenuId="subMenuId"
            :wfHdrId="ppPartyAddressId"
            :selectedInvoiceData="selectedInvoiceData"
            @uploadedDocLists="uploadedDocLists"
          ></uploadDocument>
        </b-modal>
      </md-card-content>
    </md-card>
  </div>
</template>
<script>
import addAddress from "./AddAddress.vue";
import commonHelper from "../../../../utility/common.helper.utility";
import uploadDocument from "../../../uploadDocument.vue";
export default {
  components: {
    addAddress,
    uploadDocument,
  },
  watch: {
    existCurrentPage: function() {
      this.getPartyAddress();
    },
    existPerPage: function() {
      this.existCurrentPage = 1;
      this.getPartyAddress();
    },
    updateCurrentPage: function() {
      this.getUpdatedAddress();
    },
    updatePerPage: function() {
      this.updateCurrentPage = 1;
      this.getUpdatedAddress();
    },
  },
  data() {
    return {
      componentName: "Party Details",
      existPerPage: commonHelper.perPageRecord,
      existCurrentPage: 1,
      existTotalRows: null,
      updatePerPage: commonHelper.perPageRecord,
      updateCurrentPage: 1,
      updateTotalRows: null,
      showModal: false,
      addressDetails: null,
      selected: [],
      tableData: [
        {
          name: "Dakota Rice",
          salary: "",
          country: "Niger",
          city: "Oud-Turnhout",
        },
        {
          name: "Minerva Hooper",
          salary: "",
          country: "Curaçao",
          city: "Sinaai-Waas",
        },
        {
          name: "Sage Rodriguez",
          salary: "",
          country: "Netherlands",
          city: "Baileux",
        },
        {
          name: "Philip Chaney",
          salary: "",
          country: "Korea, South",
          city: "Overland Park",
        },
      ],
      gridData: [],
      gridField: [
        {
          key: "address_type",
        },
        {
          key: "address",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
      ],
      updateData: [
        {
          party_id: null,
          approved_by: null,
          approval_date: null,
          posting_status: null,
          rejected_reason: null,
          address: null,
          address_id: null,
          pincode_name: null,
          city_name: null,
          address_2: null,
          other_state: null,
          state_name: null,
          city_id: null,
          address_3: null,
          pincode_id: null,
          country_id: null,
          other_city: null,
          address_name: null,
          country_name: null,
          address_1: null,
          pp_address_id: null,
          state_id: null,
          address_type_lookup: null,
          postsing_status_meaning: null,
        },
      ],
      updateField: [
        {
          key: "address_type",
          class: "addressType-col",
        },
        {
          key: "address_name",
          class: "address-col",
        },
        {
          key: "address_line",
        },
        {
          key: "city_name",
          label: "City",
          class: "city-col",
        },
        {
          key: "state_name",
          label: "State",
          class: "state-col",
        },
        {
          key: "country_name",
          label: "Country",
        },
        {
          key: "pincode_name",
          label: "Pincode",
        },
        {
          key: "approval_status",
          label: "Status",
        },
        {
          key: "rejected_reason",
          label: "Reason",
        },
        {
          key: "upload",
          label: "Document",
        },
      ],
      openKmAuth: null,
      showHistory: false,
      uploadEnabled: false,
      addressPpId: null,
      menuId: 326,
      subMenuId: null,
      ppPartyAddressId: null,
      selectedInvoiceData: null,
      numberOfUpload: 0,
      recordType: null,
    };
  },
  mounted() {
    this.getPartyAddress();
    this.addressDetails = null;
    this.getUpdatedAddress();
    this.getSubMenuById();
  },
  methods: {
    getPartyAddress() {
      const payload = {
        _page: this.existCurrentPage - 1,
        _limit: this.existPerPage,
      };
      this.$store.dispatch("party/getAddresses", payload).then((resp) => {
        if (resp.status === 200) {
          this.gridData = resp.data.data.page;
          this.existTotalRows = resp.data.data.total_elements;
        }
      });
    },
    rowSelected(item, addressType) {
      const rowData = {
        item: item,
        addressType: addressType,
      };
      if (this.componentName !== this.$route.name) {
        this.ppPartyAddressId = item.pp_address_id;
        this.recordType = addressType;
        this.openAddressModal(true, rowData, item);
      }
    },
    openAddressModal(flag, item) {
      this.addressDetails = item;
      if (!item) {
        this.recordType = "new";
      }
      this.showModal = flag;
      this.uploadEnabled = false;
    },
    getUpdatedAddress() {
      const payload = {
        _page: this.updateCurrentPage - 1,
        _limit: this.updatePerPage,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getUpdatedAddress", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.updateData = response.data.data.page;
            this.addressPpId = this.updateData[0].pp_address_id;
            this.updateTotalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    updatedAddressResponse(value) {
      this.uploadEnabled = value;
      this.getUpdatedAddress();
    },
    getOpenKmAuthenticate(item) {
      this.ppPartyAddressId = item.pp_address_id;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    deleteAddressById(ppId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/deleteAddressById", ppId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.uploadEnabled = false;
            this.showMoal = false;
          }
        })
        .then(() => this.getUpdatedAddress())
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    setPpIdAddress(value) {
      this.ppId = value;
    },
    closeHandle(bvModalEvent) {
      if (this.uploadEnabled) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deleteAddressById(this.ppId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    closeHandleUploadDocument(bvModalEvent) {
      if (this.numberOfUpload === 0) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deleteAddressById(this.ppPartyAddressId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    uploadedDocLists(item) {
      this.numberOfUpload = item.length;
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_address_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.city-col > div {
  width: 230px !important;
}
.state-col > div {
  width: 130px !important;
}
.addressType-col > div {
  width: 90px !important;
}
.address-col > div {
  width: 100px !important;
}
</style>
