<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- <div>
      <md-dialog-alert
        :md-active.sync="helpModal"
        :md-title="title"
        :md-content="helpText"
      />
    </div> -->
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout">
            <md-card-header
              class="md-card-header-text md-card-header-green search-icon"
            >
              <div class="card-icon vendor-icon">
                <md-icon style="font-size: 20px !important">search</md-icon>
              </div>
            </md-card-header>

            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label for="font">Cheque Number</label>
                <md-input v-model="checkNum"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Amount From</label>
                <md-input type="text" v-model="amountFrom"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-15">
              <md-field>
                <label>Amount To</label>
                <md-input type="text" v-model="amountTo"></md-input>
              </md-field>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-14 DateFormate"
            >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="From Date"
                v-model="fromDate"
                @change="toDate = null"
              ></date-picker>
            </div>
            <div
              class="md-layout-item md-small-size-100 md-size-14 DateFormate"
            >
              <date-picker
                md-immediately
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="To Date"
                v-model="toDate"
                :disabled-date="disabledDates"
                :disabled="!fromDate"
              ></date-picker>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-22">
              <div style="margin-top: 5%">
                <md-button
                  class="md-raised md-success search-btn"
                  style="margin-right: 5%"
                  @click="isVendorApproved()"
                  >Search</md-button
                >
                <md-button
                  class="md-raised md-info search-btn"
                  style="margin-right: 4%"
                  @click="clearSearch()"
                  >Clear</md-button
                >
                <md-button
                  v-if="vendorPaymentData.length"
                  style="height:28px;"
                  class="md-warning md-just-icon"
                  @click="handleOptionClick()"
                  title="Download Excel"
                  ><md-icon>file_download</md-icon></md-button
                >
              </div>
            </div>
          </div>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout-item">
            <b-table
              striped
              hover
              outlined
              sticky-header
              :items="vendorPaymentData"
              :fields="vendorPaymentFields"
              show-empty
              class="bg-white"
              style="max-height:calc(100vh - 230px)"
              @row-clicked="rowSelected"
            >
              <template #head(le)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(check_id)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(utr)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(check_date)="data">
                <div
                  :class="
                    `${
                      sortFlag.checkDate
                        ? 'sortedDivCheckDate'
                        : 'unsortedDivCheckDate'
                    }`
                  "
                >
                  <div
                    :class="
                      `${
                        sortFlag.checkDate
                          ? 'sortedCheckDate'
                          : 'unsortedCheckDate'
                      }`
                    "
                  >
                    <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
                  </div>
                  <span
                    class="ml-2"
                    @click="sortFunc('Cheque Date Low to High')"
                    ><b-icon
                      v-if="!arrowCheckDate"
                      icon="arrow-up-right-square-fill"
                      aria-hidden="true"
                      font-scale="1.5rem"
                    ></b-icon
                  ></span>
                  <span class="" @click="sortFunc('Cheque Date High to Low')"
                    ><b-icon
                      v-if="arrowCheckDate"
                      icon="arrow-down-right-square-fill"
                      aria-hidden="true"
                      font-scale="1.5rem"
                    ></b-icon
                  ></span>
                </div>
              </template>
              <template #head(check_amount)="data">
                <div
                  :class="
                    `${
                      sortFlag.checkAmt ? 'sortedDivCheckAmt' : 'unsortedDivAmt'
                    }`
                  "
                >
                  <div
                    :class="
                      `${
                        sortFlag.checkAmt
                          ? 'sortedInvAmount'
                          : 'unsortedCheckAmount'
                      }`
                    "
                  >
                    <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
                  </div>
                  <span
                    class="ml-2"
                    @click="sortFunc('Cheque Amount Low to High')"
                    ><b-icon
                      v-if="!arrowCheckAmt"
                      icon="arrow-up-right-square-fill"
                      aria-hidden="true"
                      font-scale="1.5rem"
                    ></b-icon
                  ></span>
                  <span class="" @click="sortFunc('Cheque Amount High to Low')"
                    ><b-icon
                      v-if="arrowCheckAmt"
                      icon="arrow-down-right-square-fill"
                      aria-hidden="true"
                      font-scale="1.5rem"
                    ></b-icon
                  ></span>
                </div>
              </template>
              <template #head(currency_code)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(select)="data">
                <div>
                  <b-form-checkbox
                    v-model="selectAllCheckBox"
                    :value="true"
                    :unchecked-value="false"
                    style="display: inline"
                  >
                  </b-form-checkbox>
                  <span>{{ data.label }}</span>
                </div>
              </template>
              <template #cell(select)="data">
                <b-form-checkbox
                  v-model="data.item.select"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
              </template>
              <template #cell(check_amount)="data">
                <span class="text-fix-pmt">
                  {{
                    data.item.check_amount !== 0
                      ? formattedCurrency(data.item.check_amount)
                      : data.item.check_amount
                  }}</span
                >
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
            <div class="md-layout pagination-count">
              <div style="width:18%;">
                <div v-if="vendorPaymentData.length && totalRows <= perPage">
                  <p>
                    Showing
                    {{
                      totalRows == 0
                        ? totalRows
                        : perPage * (currentPage - 1) + 1
                    }}
                    to {{ totalRows }} of {{ totalRows }} entries
                  </p>
                </div>
                <div v-else-if="vendorPaymentData.length">
                  <p>
                    Showing {{ perPage * (currentPage - 1) + 1 }} to
                    {{
                      perPage * currentPage >= totalRows
                        ? totalRows
                        : perPage * currentPage
                    }}
                    of {{ totalRows }}
                    entries
                  </p>
                </div>
              </div>
              <div
                v-if="vendorPaymentData.length"
                style="margin-top:-0.5%; width:7%"
              >
                <div class="md-layout-item">
                  <md-field>
                    <md-select v-model="perPage">
                      <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <div style="color:red; margin-left:50%;">
                <span>Click on the Field Header for more information</span>
              </div>
            </div>
          </div>
        </md-card>
      </div>

      <b-modal
        v-model="showPaymentDtlsModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="wide-modal"
        title="Vendor Payment Details"
        @hidden="showHidePaymentDtlsModal(false, null)"
      >
        <VendorPaymentDetails :vendorPaymentRowDtls="vendorPaymentRowDtls" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../../src/utility/common.helper.utility";
import VendorPaymentDetails from "./VendorPaymentDetails";
import DatePicker from "vue2-datepicker";
import HeaderSummary from "../HeaderSummary.vue";
export default {
  components: {
    VendorPaymentDetails,
    DatePicker,
    HeaderSummary,
  },
  name: "VendorPayment",
  watch: {
    currentPage: function() {
      this.getVendorPaymentData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPaymentData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      checkNum: null,
      amountFrom: null,
      amountTo: null,
      fromDate: null,
      toDate: null,
      vendorPaymentRowDtls: null,
      showPaymentDtlsModal: false,
      loader: false,
      helpModal: false,
      vendorPaymentData: [],
      vendorPaymentFields: [
        {
          key: "select",
          class: "stickyColumn col-sm-1",
        },
        {
          key: "le",
          label: "Legal Entity",
        },
        {
          key: "check_id",
          label: "Cheque Number",
        },
        {
          key: "utr",
          label: "UTR Number",
        },
        {
          key: "check_date",
          label: "Cheque Date",
        },
        {
          key: "check_amount",
          label: "Cheque Amount",
          class: "text-fix-pmt",
        },
        {
          key: "currency_code",
          label: "Currency",
        },
      ],
      totalPayload: null,
      menuId: null,
      vendorId: null,
      sortFlag: {
        checkAmt: false,
        checkDate: true,
      },
      arrowCheckAmt: false,
      arrowCheckDate: false,
      customDownload: [],
      selectAllCheckBox: false,
    };
  },

  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("VendorPayment"));
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.vendorId) {
      this.getDefaultDateFromTo();
      this.getVendorPaymentData();
    }
  },

  methods: {
    checkUncheckAll() {
      this.vendorPaymentData = this.vendorPaymentData.map((data) => {
        data.select = this.selectAllCheckBox;
        return data;
      });
    },
    handleOptionClick() {
      this.customDownload = [];
      this.vendorPaymentData.forEach((ele) => {
        if (ele.select) {
          this.customDownload.push({ check_id: ele.check_id });
        }
      });
      if (this.customDownload.length) {
        this.downloadPaymentReport();
      } else {
        const promt = confirm(
          "Are you sure, you want to download all records.?"
        );
        if (promt) {
          this.downloadCustomExcel();
        }
      }
    },
    downloadPaymentReport() {
      const payload = {
        vendor_id: this.vendorId,
        check_ids: this.customDownload,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/downloadPaymentReport", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result.length) {
              const customDownloadData = result.map((obj) => ({
                legal_entity: obj.le,
                cheque_number: obj.check_id,
                utr_number: obj.utr,
                cheque_date: obj.check_date,
                cheque_amount: obj.amount,
                invoice_type: obj.inv_type,
                document_sequence: obj.doc_sequence_value,
                invoice_number: obj.invoice_num,
                invoice_amount: obj.invoice_amount,
                payment_amount: obj.invoice_payment_amount,
                currency: obj.currency_code,
                invoice_date: obj.invoice_date,
              }));
              commonHelper.downloadExcel(
                customDownloadData,
                "vendor-payment-details"
              );
            } else {
              alert("No Data for Download");
              this.getVendorPaymentData();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    triggerApi(item) {
      if (item.select) {
        this.getVendorPaymentsDtlsById(item);
      } else if (!item.select) {
        const unSelectedItem = this.vendorPaymentData.filter(
          (elem) => !elem.select
        );
        this.customDownload = this.customDownload.filter(
          (item) => !unSelectedItem.some((obj) => obj.check_id == item.check_id)
        );
      }
    },

    getVendorPaymentsDtlsById(item) {
      const payload = {
        _page: 0,
        _limit: 2000,
        vendor_id: this.vendorId,
        check_id: item.check_id,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPaymentsDtlsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.customDownload.push(...result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedDownload() {
      // this.totalPayload._limit = this.totalRows;
      // this.loader = true;
      // this.$store
      //   .dispatch("vendor/getVendorPaymentData", this.totalPayload)
      //   .then((resp) => {
      //     this.loader = false;
      //     const result = resp.data.data.data.page.map((elem) => {
      //       elem.check_date = commonHelper.formatDate(elem.check_date);
      //       elem.check_amount =
      //         elem.check_amount !== 0
      //           ? commonHelper.formatAmount(elem.check_amount)
      //           : elem.check_amount;
      //       return elem;
      //     });
      if (this.customDownload.length) {
        const customDownloadData = this.customDownload.map((obj) => ({
          legal_entity: obj.le,
          cheque_number: obj.check_id,
          utr_number: obj.utr,
          cheque_date: obj.check_date,
          cheque_amount: +obj.amount,
          invoice_type: obj.inv_type,
          doc_sequence: obj.doc_sequence_value,
          invoice_number: obj.invoice_num,
          invoice_amount: obj.invoice_amount,
          payment_amount: obj.invoice_payment_amount,
          invoice_date: obj.invoice_date,
          currency: obj.currency_code,
        }));
        if (customDownloadData) {
          commonHelper.downloadExcel(
            customDownloadData,
            "Selected-Vendor-Payment"
          );
        }
      } else {
        const promt = confirm(
          "Are you sure, you want to download all records.?"
        );
        if (promt) {
          this.downloadCustomExcel();
        }
      }
    },
    getDefaultDateFromTo() {
      const today = new Date();
      this.fromDate = commonHelper.formattedDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)
      );
      this.toDate = commonHelper.formattedDate(new Date());
    },
    getKeySummaryDtls(columnName) {
      const payload = {
        tag: columnName,
        menu_id: this.menuId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getKeySummaryDtls", payload)
        .then((resp) => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.helpModal = true;
            if (result) {
              this.helpText = result;
            } else {
              this.helpText = "Help Text Not Available.";
            }
          } else {
            this.helpText = "Something Went Wrong.";
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = "Something Went Wrong.";
        });
    },
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = { ...this.totalPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        "vendor/getVendorPaymentData",
        "vendor-payment",
        () => (this.loader = false)
      );
    },
    isVendorApproved() {
      if (this.vendorId) {
        this.getVendorPaymentData();
      } else {
        alert("Vendor is Not Approved");
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.fromDate);
    },
    getVendorPaymentData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_id: this.vendorId,
        check_id: this.checkNum,
        amount_from: this.amountFrom,
        amount_to: this.amountTo,
        date_from: this.fromDate,
        date_to: this.toDate,
        order_by: this.orderBy ? this.orderBy : "DESC",
        order_name: this.orderName ? this.orderName : "check_date",
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPaymentData", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.vendorPaymentData = result.map((elem) => {
              elem.check_date = commonHelper.formatDate(elem.check_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPaymentData", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.check_date = commonHelper.formatDate(elem.check_date);
            elem.check_amount =
              elem.check_amount !== 0
                ? commonHelper.formatAmount(elem.check_amount)
                : elem.check_amount;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.le,
            cheque_number: obj.check_id,
            utr_number: obj.utr,
            cheque_date: obj.check_date,
            amount: obj.check_amount,
            currency: obj.currency_code,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "vendor-payment");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    clearSearch() {
      this.checkNum = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.fromDate = null;
      this.toDate = null;
      this.customDownload = [];
      this.getVendorPaymentData();
    },
    rowSelected(item) {
      this.vendorPaymentRowDtls = item;
      this.showHidePaymentDtlsModal(true, item);
    },
    showHidePaymentDtlsModal(flag, item) {
      this.vendorPaymentRowDtls = item;
      this.showPaymentDtlsModal = flag;
    },
    sortFunc(item) {
      if (item === "Cheque Amount Low to High") {
        this.sortFlag = {
          checkAmt: true,
          checkDate: false,
        };
        this.arrowCheckAmt = true;
        this.orderBy = "ASC";
        this.orderName = "amount";
      } else if (item === "Cheque Amount High to Low") {
        this.sortFlag = {
          checkAmt: true,
          checkDate: false,
        };
        this.arrowCheckAmt = false;
        this.orderBy = "DESC";
        this.orderName = "amount";
      } else if (item === "Cheque Date Low to High") {
        this.sortFlag = {
          checkAmt: false,
          checkDate: true,
        };
        this.arrowCheckDate = true;
        this.orderBy = "ASC";
        this.orderName = "check_date";
      } else if (item === "Cheque Date High to Low") {
        this.sortFlag = {
          checkAmt: false,
          checkDate: true,
        };
        this.arrowCheckDate = false;
        this.orderBy = "DESC";
        this.orderName = "check_date";
      }
      this.getVendorPaymentData();
    },
  },
};
</script>

<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}
.md-card .md-card-content {
  padding: 5px 0px !important;
}
.search-icon {
  margin: 0 5px !important;
}
.invocie-col > div {
  width: 160px !important;
}
.amount-col > div {
  width: 160px !important;
}
.DateFormate {
  margin-top: 17px !important;
}
.text-fix-pmt {
  margin-right: 40% !important;
  text-align: right !important;
}
.md-dialog {
  z-index: 1055;
}
.sortedDivCheckAmt {
  color: green;
  display: flex;
  margin-left: 25px;
}
.unsortedDivAmt {
  margin-left: 25px;
}
.unsortedDivAmt {
  display: flex;
}
.sortedInvAmount {
  color: green;
}
.sortedDivCheckAmt > div > div {
  width: 110px;
}
.unsortedCheckAmount > div > div {
  width: 110px;
}
.sortedDivCheckDate {
  color: green;
  display: flex;
  margin-left: -10px;
}
.sortedDivCheckDate > div > div {
  width: 85px;
}
.unsortedDivCheckDate {
  display: flex;
}
.unsortedDivCheckDate {
  margin-left: -10px;
}
.sortedCheckDate {
  color: green;
}
.unsortedCheckDate > div > div {
  width: 85px;
}
.md-menu-content.md-select-menu {
  width: 17.5% !important;
}
.stickyColumn.col-sm-1 {
  max-width: 50px !important;
}
</style>
