<template>
  <div style="background-color: lightgrey">
    <md-card>
      <div style="margin-left: 68.5rem" v-if="$route.name !== componentName">
        <md-button
          class="md-raised md-success search-btn"
          @click="openIndirectTaxModal(true)"
          >Add Indirect Tax</md-button
        >
      </div>
      <md-card-header
        v-if="$route.name !== componentName"
        class="md-card-header-text md-layout-item md-card-header-green"
        style="margin-top: -29px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Existing Indirect Tax</h5>
        </div>
      </md-card-header>
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="gridData"
          :fields="gridField"
          show-empty
          @row-clicked="rowSelected($event, 'exist')"
          class="bg-white"
        >
          <template #cell(active)="data">
            <span>{{ data.item.active ? "Yes" : "No" }}</span>
          </template>
        </b-table>
        <b-pagination
          v-model="existCurrentPage"
          :total-rows="existTotalRows"
          :per-page="existPerPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        v-model="showModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="md"
        title="Add Indirect Tax"
        @close="closeHandle"
      >
        <!-- @hidden="openIndirectTaxModal(false, null)" -->
        <addIndirectTax
          :indirectTaxDetails="indirectTaxDetails"
          @updatedindirectTaxResponse="updatedindirectTaxResponse"
          @deletePartyIndirectTaxById="setPpIdIndirectTax"
          :ppIndirectTaxId="ppIndirectTaxId"
          @saveButtonCloseModal="uploadEnabled = false"
          :recordType="recordType"
        />
      </b-modal>
    </md-card>
    <md-card v-if="$route.name !== componentName">
      <md-card-header
        class="md-card-header-text md-layout-item md-card-header-blue"
        style="margin-top: 8px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Updated Indirect Tax</h5>
        </div>
      </md-card-header>
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="updateData"
          :fields="updateField"
          show-empty
          class="bg-white"
          @row-clicked="rowSelected($event, 'update')"
        >
          <template #cell(active)="data">
            <span>{{ data.item.active ? "Yes" : "No" }}</span>
          </template>
          <template #cell(upload)="data">
            <a class="info; pointer" @click="getOpenKmAuthenticate(data.item)"
              >View</a
            >
          </template>
        </b-table>
        <b-pagination
          v-model="updateCurrentPage"
          :total-rows="updateTotalRows"
          :per-page="updatePerPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        v-model="showHistory"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        title="Upload Document"
        @close="closeHandleUploadDocument"
      >
        <uploadDocument
          :openKmAuth="openKmAuth"
          @closeOpenKmModal="showHideHistory(false, null)"
          :propMenuId="menuId"
          :PropSubMenuId="subMenuId"
          :wfHdrId="ppPartyIndirectTaxId"
          :selectedInvoiceData="selectedInvoiceData"
          @uploadedDocLists="uploadedDocLists"
        ></uploadDocument>
      </b-modal>
    </md-card>
  </div>
</template>
<script>
import addIndirectTax from "./AddIndirectTax.vue";
import commonHelper from "../../../../utility/common.helper.utility";
import uploadDocument from "../../../uploadDocument.vue";
export default {
  components: {
    addIndirectTax,
    uploadDocument,
  },
  watch: {
    existCurrentPage: function() {
      this.getPartyIndirectTax();
    },
    existPerPage: function() {
      this.existCurrentPage = 1;
      this.getPartyIndirectTax();
    },
    updateCurrentPage: function() {
      this.getUpdatedIndirectTax();
    },
    updatePerPage: function() {
      this.updateCurrentPage = 1;
      this.getUpdatedIndirectTax();
    },
  },
  data() {
    return {
      componentName: "Party Details",
      existPerPage: commonHelper.perPageRecord,
      existCurrentPage: 1,
      existTotalRows: null,
      updatePerPage: commonHelper.perPageRecord,
      updateCurrentPage: 1,
      updateTotalRows: null,
      gridData: [],
      gridField: [
        {
          key: "tax_group",
        },
        {
          key: "issuing_authority",
        },
        {
          key: "tax_num",
          label: "GSTIN / TAN No.",
        },
        {
          key: "state",
        },
        {
          key: "active",
        },
      ],
      showModal: false,
      indirectTaxDetails: null,
      updateData: [
        {
          active: false,
          posting_status: null,
          rejected_reason: null,
          approval_status: null,
          party_id: null,
          approval_date: null,
          approved_by: null,
          tax_group_id: null,
          tax_num: null,
          tax_group: null,
          party_indirect_tax_id: null,
          posting_status_meaning: null,
          issuing_authority: null,
          pp_party_indirect_tax_id: null,
        },
      ],
      updateField: [
        {
          key: "tax_group",
        },
        {
          key: "issuing_authority",
        },
        {
          key: "tax_num",
          label: "GSTIN / TAN No.",
        },
        {
          key: "state",
        },
        {
          key: "approval_status",
          label: "Status",
        },
        {
          key: "rejected_reason",
          label: "Reason",
        },
        {
          key: "active",
        },
        {
          key: "upload",
          label: "Document",
        },
      ],
      openKmAuth: null,
      showHistory: false,
      uploadEnabled: false,
      ppIndirectTaxId: null,
      menuId: 326,
      subMenuId: null,
      ppPartyIndirectTaxId: null,
      selectedInvoiceData: null,
      numberOfUpload: 0,
      recordType: null,
    };
  },
  mounted() {
    this.getPartyIndirectTax();
    this.indirectTaxDetails = null;
    this.getUpdatedIndirectTax();
    this.getSubMenuById();
  },
  methods: {
    getPartyIndirectTax() {
      const payload = {
        _page: this.existCurrentPage - 1,
        _limit: this.existPerPage,
      };
      this.$store
        .dispatch("party/getPartyIndirectTax", payload)
        .then((resp) => {
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.existTotalRows = resp.data.data.total_elements;
          }
        });
    },
    rowSelected(item, recordType) {
      if (this.componentName !== this.$route.name) {
        this.ppPartyIndirectTaxId = item.pp_party_indirect_tax_id;
        this.recordType = recordType;
        this.openIndirectTaxModal(true, item);
      }
    },
    openIndirectTaxModal(flag, item) {
      this.indirectTaxDetails = item;
      if (!item) {
        this.recordType = "new";
      }
      this.showModal = flag;
      this.uploadEnabled = false;
    },
    getUpdatedIndirectTax() {
      const payload = {
        _page: this.updateCurrentPage - 1,
        _limit: this.updatePerPage,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getUpdatedIndirectTax", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.updateData = response.data.data.page;
            this.ppIndirectTaxId = this.updateData[0].pp_party_indirect_tax_id;
            this.updateTotalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    updatedindirectTaxResponse(value) {
      this.uploadEnabled = value;
      this.getUpdatedIndirectTax();
    },
    getOpenKmAuthenticate(item) {
      this.ppPartyIndirectTaxId = item.pp_party_indirect_tax_id;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    deletePartyIndirectTaxById(ppId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/deletePartyIndirectTaxById", ppId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.uploadEnabled = false;
            this.showMoal = false;
          }
        })
        .then(() => this.getUpdatedIndirectTax())
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    setPpIdIndirectTax(value) {
      this.ppId = value;
    },
    closeHandle(bvModalEvent) {
      if (this.uploadEnabled) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deletePartyIndirectTaxById(this.ppId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    closeHandleUploadDocument(bvModalEvent) {
      if (this.numberOfUpload === 0) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deletePartyIndirectTaxById(this.ppPartyIndirectTaxId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    uploadedDocLists(item) {
      this.numberOfUpload = item.length;
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_indirect_tax_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
