<template>
  <div>
    <iframe
      v-if="fileExtension === 'pdf'"
      id="prev-docs"
      type="application/pdf"
      width="100%"
      style="height: 520px"
    ></iframe>
    <img
      id="prev-docs"
      oncontextmenu="return false"
      height="400px"
      width="100%"
      v-else
    />
    <b-pagination
      @change="getPageIndex"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="fill"
      size="sm"
      class="my-0"
    ></b-pagination>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
export default {
  props: [
    "docDtlId",
    "authToken",
    "uploadedFileName",
    "data",
    "currentPageIndex",
  ],
  data() {
    return {
      fileExtension: null,
      perPage: 1,
      totalRows: null,
      currentPage: this.currentPageIndex,
      specIndex: null,
    };
  },
  mounted() {
    if (this.data) {
      this.totalRows = this.data.length;
      this.specIndex = this.currentPageIndex;
    }
    this.previewOpenDoc();
    // document.addEventListener("keydown", this.handleKeyDown);
    // document.oncontextmenu = function() {
    //   return false;
    // };
  },
  methods: {
    previewOpenDoc() {
      this.fileExtension = commonHelper.getFileExtension(this.uploadedFileName);
      const payload = {
        docDetailId: this.docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension,
        totalRows: null,
        currentPage: this.currentPageIndex,
        specIndex: null,
        perPage: 1,
      };
      this.loader = true;
      commonHelper.previewImgDoc(payload);
    },
    getPageIndex(item) {
      this.specIndex = item;
      this.newPreviewOpenDoc(
        this.data[item - 1].original_file_name,
        this.data[item - 1].document_det_id
      );
    },
    newPreviewOpenDoc(uploadedFileName, docDtlId) {
      this.fileExtension = commonHelper.getFileExtension(uploadedFileName);
      const payload = {
        docDetailId: docDtlId,
        token: this.authToken,
        fileExtension: this.fileExtension,
      };
      this.loader = true;
      commonHelper.previewImgDoc(payload);
    },
    openDocumentNewTab() {
      this.fileExtension = commonHelper.getFileExtension(
        this.data[this.specIndex - 1].original_file_name
      );

      if (this.fileExtension === "xlsx") {
        alert("Not Allowed");
      } else {
        const payload = {
          docDetailId: this.data[this.specIndex - 1].document_det_id,
          token: this.authToken,
          fileExtension: this.fileExtension,
        };
        this.loader = true;
        commonHelper.previewImgDocParty(payload);
        this.loader = false;
      }
    },
    // handleKeyDown(event) {
    //   if (
    //     event.keyCode == "17" ||
    //     event.keyCode == "91" ||
    //     event.keyCode == "92" ||
    //     event.keyCode == "16" ||
    //     event.keyCode == "83"
    //   ) {
    //     event.preventDefault();
    //     alert("Not Allowed");
    //   }
    // },
  },
  //   beforeDestroy() {
  //     document.removeEventListener("keydown", this.handleKeyDown);
  //   },
};
</script>
