<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-50"
      @click="selectedTab('fmsUnit')"
    >
      <stats-card header-color="rose" class="pointer">
        <template slot="header">
          <div class="card-icon">
            <md-icon>perm_identity</md-icon>
          </div>
          <p class="category">FMS Unit</p>
          <p class="title">No. of FMS Units: {{ totalFmsUnitRows }}</p>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-50"
      @click="selectedTab('leaseUnit')"
    >
      <stats-card header-color="blue" class="pointer">
        <template slot="header">
          <div class="card-icon">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Lease Unit</p>
          <p class="title">No. of Lease Units: {{ totalLeaseUnitRows }}</p>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'fmsUnit'"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
        >
          <div class="card-text profile-card" style="padding: 7px !important">
            <h5 class="title">FMS Unit</h5>
            <!-- <p class="category">Last updated on 15th September, 2022</p> -->
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="fmsUnitGridData"
            :fields="fmsUnitGridField"
            show-empty
            class="bg-white"
          >
            <template #cell(fms_prj_code)="data">
              <span :title="data.item.fms_prj_name">{{
                data.item.fms_prj_code
              }}</span></template
            >
            <template #cell(fms_tower_code)="data">
              <span :title="data.item.fms_tower_name">{{
                data.item.fms_tower_code
              }}</span></template
            >
            <template #cell(fms_floor_code)="data">
              <span :title="data.item.fms_floor_name">{{
                data.item.fms_floor_code
              }}</span></template
            >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalFmsUnitRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'leaseUnit'"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
        >
          <div class="card-text profile-card" style="padding: 7px !important">
            <h5 class="title">Lease Unit</h5>
            <!-- <p class="category">Last updated on 15th September, 2022</p> -->
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="leaseUnitGridData"
            :fields="leaseUnitGridField"
            show-empty
            class="bg-white"
          >
            <template #cell(lease_prj_code)="data">
              <span :title="data.item.lease_prj_name">{{
                data.item.lease_prj_code
              }}</span></template
            >
            <template #cell(lease_tower_code)="data">
              <span :title="data.item.lease_tower_name">{{
                data.item.lease_tower_code
              }}</span></template
            >
            <template #cell(lease_floor_code)="data">
              <span :title="data.item.lease_floor">{{
                data.item.lease_floor_code
              }}</span></template
            >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalLeaseUnitRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import { StatsCard } from "@/components";
import commonHelper from "../../../utility/common.helper.utility";

export default {
  components: {
    StatsCard,
  },
  name: "UnitDetails",
  watch: {
    currentPage: function () {
      if (this.tabValue === "fmsUnit") {
        this.getFmsUnitData();
      } else if (this.tabValue === "leaseUnit") {
        this.getLeaseUnitData();
      }
    },
    perPage: function () {
      if (this.tabValue === "fmsUnit") {
        this.currentPage = 1;
        this.getFmsUnitData();
      } else if (this.tabValue === "leaseUnit") {
        this.currentPage = 1;
        this.getLeaseUnitData();
      }
    },
    tabValue: function () {
      if (this.tabValue === "fmsUnit") {
        this.getFmsUnitData();
      } else if (this.tabValue === "leaseUnit") {
        this.getLeaseUnitData();
      }
    },
  },

  data() {
    return {
      tableData: [
        {
          name: "Test#2",
        },
      ],
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalFmsUnitRows: 0,
      totalLeaseUnitRows: 0,
      pageNum: 1,
      fmsUnitGridData: [],
      fmsUnitGridField: [
        {
          key: "unit_display_name",
          label: "Unit",
        },
        {
          key: "fms_prj_code",
          label: "Project",
        },

        { key: "fms_tower_code", label: "Tower"},
        { key: "fms_floor_code", label: "Floor"},
        // { key: "version", class: "text-center" },
        { key: "billable_area", label: "Area" },
        {
          key: "billable_area_uom_vset_meaning",
          label: "UOM",
        },
        {
          key: "customer_name",
          label: "Customer",
        },
        {
          key: "sharing_ratio",
        },
      ],
      leaseUnitGridData: [],
      leaseUnitGridField: [
        {
          key: "mla_no",
          label: "MLA No.",
          class: "mla-col",
        },
        {
          key: "tla_no",
          label: "TLA No.",
          class: "tla-col"
        },
        {
          key: "lease_tenant_agreement_date",
          label: "Agreement Date",
        },
        {
          key: "period_from",
        },
        {
          key: "period_to",
        },
        {
          key: "lease_tenant_rental_start_date",
          label: "Start Date"
        },
        {
          key: "lease_prj_code",
          label: "Project",
        },
        {
          key: "lease_tower_code",
          label: "Tower",
        },
        {
          key: "lease_floor_code",
          label: "Floor",
        },
        {
          key: "lease_unit_name",
          label: "Unit",
        },
        {
          key: "billable_area",
          label: "Area"
        },
        {
          key: "billable_area_uom_vset_meaning",
          label: "UOM",
        },
      ],
      tabValue: "fmsUnit",
      product1: process.env.VUE_APP_BASE_URL + "/img/card-2.jpg",
      product2: process.env.VUE_APP_BASE_URL + "/img/card-3.jpg",
      product3: process.env.VUE_APP_BASE_URL + "/img/card-1.jpg",
      seq2: 0,
      selected: [],
    };
  },
  mounted() {
    if (this.tabValue === "fmsUnit") {
      this.getFmsUnitData();
    } else if (this.tabValue === "leaseUnit") {
      this.getLeaseUnitData();
    }
    this.getFmsUnitData();
    this.getLeaseUnitData();
  },
  methods: {
    getFmsUnitData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.fmsUnitGridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getFmsUnitData", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.fmsUnitGridData = resp.data.data.page;
            this.totalFmsUnitRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getLeaseUnitData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.le_id ? this.le_id : null,
      };
      this.leaseUnitGridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getLeaseUnitData", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.leaseUnitGridData = resp.data.data.page;
            this.totalLeaseUnitRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },

    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function (items) {
      this.selected = items;
    },
  },
};
</script>
<style>
.card-icon {
  margin-right: 0px !important;
}
.card-text {
  padding: 0px !important;
}
.mla-col > div {
  width: 140px !important;
}
.tla-col > div {
  width: 170px !important
}
</style>
