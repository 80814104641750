<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <md-dialog-alert
      :md-active.sync="helpModal"
      :md-title="title"
      :md-content="helpText"
      class="underlined-title"
    />
    <div>
      <span style="cursor: pointer;" @click="getKeySummaryDtls()">{{
        headerLabel
      }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VendorPayment",
  props: ["headerLabel", "menuId"],
  data() {
    return {
      loader: false,
      helpModal: false,
      title: "Help Text!",
      helpText: "",
    };
  },
  mounted() {},
  methods: {
    getKeySummaryDtls() {
      const payload = {
        tag: this.headerLabel,
        menu_id: this.menuId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getKeySummaryDtls", payload)
        .then((resp) => {
          this.loader = false;
          this.helpModal = true;
          if (resp.status === 200) {
            const result = resp.data.data;
            if (result) {
              this.helpText = result;
            } else {
              this.helpText = "Help Text Not Available.";
            }
          } else {
            this.helpText = "Something Went Wrong.";
          }
        })
        .catch(() => {
          this.helpModal = true;
          this.loader = false;
          this.helpText = "Something Went Wrong.";
        });
    },
  },
};
</script>
<style>
.md-dialog {
  z-index: 1055;
}
.underlined-title .md-dialog-title {
  text-decoration: underline;
  text-align: center;
}
</style>
