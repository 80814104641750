<template>
  <div class="md-layout">
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <stats-card header-color="rose">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('viewProfile')">
            <md-icon>perm_identity</md-icon>
          </div>
          <p class="category">View Profile</p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/partyPortal/party-details"
              >View Details</router-link
            >
          </div>
        </template>
      </stats-card>
    </div>
    <div v-if="partyType =='VENDOR' || partyType == 'BOTH'" class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-40">
      <stats-card header-color="blue">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('vendor')">
            <md-icon>equalizer</md-icon>
          </div>
          <div style="display: flex; justify-content: space-around">
            <div>
          <p class="category">Vendor O/S</p>
          <h6 class="title">{{ vendorCount }}</h6>
            </div>
            <div>
          <p class="category" >Security Deposit</p>
          <h6 class="title" >{{securityCount}}</h6>
            </div>
            <div>
          <p class="category" >Recovery Invoice</p>
          <h6 class="title" >{{recoveryCount}}</h6>
            </div>
          </div>
        </template>

        <template slot="footer">
          <div style="display: flex; gap:6rem; margin-left:4.5rem">
          <div class="stats" @click="navigateFromVendorOs()" >
            <router-link
              :to="{ path: '/vendor-invoice', query: { params: 'party-dashboard' } }"
              >View Details</router-link
            >
          </div>
          <div class="stats" @click="navigateFromVendorOs()" >
          <router-link to="/vendor-security-deposit">View Details</router-link>
          </div>
          <div class="stats" @click="navigateFromVendorOs()">
           <router-link to="/vendor-recovery-invoice"> View Details</router-link>
          </div>
          </div>
        </template>
        <!-- <template slot="footer">
        </template>
        <template slot="footer">
        </template> -->
      </stats-card>
    </div>
    <div v-if="partyType =='CUSTOMER' || partyType == 'BOTH'" class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <stats-card header-color="green">
        <template slot="header">
          <div class="card-icon" @click="selectedTab('customer')">
            <md-icon>equalizer</md-icon>
          </div>
          <p class="category">Customer O/S</p>
          <h6 class="title">{{ customerCount.customer_os }}</h6>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/customer/dashboard">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25">
      <!-- @click="selectedTab('notification')" -->
      <stats-card header-color="warning">
        <template slot="header">
          <div class="card-icon">
            <md-icon>notifications</md-icon>
          </div>
          <p class="category">Notification</p>
        </template>

        <template slot="footer">
          <div class="stats">
            <router-link to="/party/notifications">View Details</router-link>
          </div>
        </template>
      </stats-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'viewProfile'"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
          style="margin-top: 8px"
        >
          <div class="card-text profile-card" style="padding: 5px !important">
            <h5 class="title">Profile</h5>
            <p class="category">Last updated on {{ lastUpdateDate }}</p>
          </div>
        </md-card-header>
        <DetailsComponent
          partyDetailsVisible="false"
          @getDetails="getDetails"
        />
      </md-card>
    </div>

    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'vendor'"
      style="margin-top: 10px"
    >
      <chart-card
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        :chart-responsive-options="emailsSubscriptionChart.responsiveOptions"
        chart-type="Line"
        chart-inside-header
        background-color="blue"
      >
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated 10 days ago
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'customer'"
      style="margin-top: 10px"
    >
      <chart-card
        :chart-data="emailsSubscriptionChart.data"
        :chart-options="emailsSubscriptionChart.options"
        chart-type="Line"
        chart-inside-header
        background-color="green"
      >
        <template slot="footer">
          <div class="stats">
            <md-icon>access_time</md-icon>
            updated 4 minutes ago
          </div>
        </template>
      </chart-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      v-if="tabValue === 'notification'"
    >
      <md-card>
        <md-card-header>
          <h6 class="title">Notifications Style</h6>
        </md-card-header>
        <md-card-content style="padding: 5px 10px !important">
          <div class="alert alert-info">
            <span>This is a plain notification</span>
          </div>
          <div class="alert alert-info">
            <button type="button" aria-hidden="true" class="close">×</button>
            <span>This is a notification with close button.</span>
          </div>
          <div class="alert alert-info alert-with-icon" data-notify="container">
            <button type="button" aria-hidden="true" class="close">×</button>
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message"
              >This is a notification with close button and icon and have many
              lines. You can see that the icon and the close button are always
              vertically aligned. This is a beautiful notification. So you don't
              have to worry about the style.</span
            >
          </div>
          <div class="alert alert-rose alert-with-icon" data-notify="container">
            <button type="button" aria-hidden="true" class="close">×</button>
            <i data-notify="icon" class="material-icons">add_alert</i>
            <span data-notify="message"
              >This is a notification with close button and icon and is made
              with ".alert-rose". You can see that the icon and the close button
              are always vertically aligned. This is a beautiful notification.
              So you don't have to worry about the style.</span
            >
          </div>
        </md-card-content>
      </md-card>
    </div>
    <!-- <div style="margin-left: 1rem">
        <marquee style="background-color: black; width: 25rem">
          <p class="queries-bg">
            For any queries please write to us at :-<br/>
           <u> Accounts Team :-<br/></u>
            Santosh Kumar Prasad - acc.receivables.leasing@vatikagroup.com<br/>
            <u>Leasing Team :-<br/></u>
            Nisha Mehta - commleasingteam2@vatikagroup.com<br/>
          </p>
        </marquee>
      </div> -->
      <div style="margin-left: 1rem" v-if="partyType === 'VENDOR'">
      <p class="queries-bg">
        For any queries please write to us at :-<br/>
        <!-- <u> Accounts Team :-<br/></u>
        Santosh Kumar Prasad - acc.receivables.leasing@vatikagroup.com<br/> -->
        <u>FOR GST QUERIES</u> :- investorqueries@vatikaleasing.com<br/>
      </p>
    </div>
    <div style="margin-left: 1rem" v-if="partyType === 'CUSTOMER'">
          <p class="queries-bg">
          For any queries please write to us at :-<br/>
          <u> Accounts Team<br/></u> :-
          Santosh Kumar Prasad - acc.receivables.leasing@vatikagroup.com<br/>
          <u>Leasing Team<br/></u> :-
          Nisha Mehta - commleasingteam2@vatikagroup.com<br/>
          </p>
      </div>
      <b-modal
      v-model="showNotifyModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Notification"
      @hidden="showHideNotifyModal(false)"
    >
      <LoginNotify />
    </b-modal>
  </div>
</template>

<script>
import { StatsCard, ChartCard } from "@/components";
import { DetailsComponent } from "@/pages/Dashboard/Party/PartyInfo";
import LoginNotify from "../LoginNotify.vue";
export default {
  components: {
    StatsCard,
    ChartCard,
    DetailsComponent,
    LoginNotify
  },
  data() {
    return {
      partyType: sessionStorage.getItem("partyType"),
      showNotifyModal: false,
      tabValue: "viewProfile",
      seq2: 0,
      leId: null,
      finYear: null,
      currentFinYear: null,
      lastUpdateDate: "",
      selected: [],
      vendorCount: 0,
      customerCount: 0,
      securityCount: 0,
      recoveryCount: 0,
      emailsSubscriptionChart: {
        data: {
          labels: ["0-30", "31-60", "61-180", "> 180"],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          [
            "screen and (max-width: 640px)",
            {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function(value) {
                  return value[0];
                },
              },
            },
          ],
        ],
      },
    };
  },
  mounted() { 
    // <------------------------ FOR OPEN LOGINNOTIFY FORM ---------------------------->
    // if (this.$route.query.params == "grid") {
    //   this.showHideNotifyModal(true);
    // }
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.currentFinYear = sessionStorage.getItem("currentFinYear")
      ? sessionStorage.getItem("currentFinYear")
      : null;
    this.getVendorCount();
    // this.getVendorSecurityCount();
    // this.getCustomerCount();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getVendorCount();
        this.getCustomerCount();
        this.getVendorSecurityCount();
        this.getRecoveryInvoice();
      }
      if (mutation.type === "shared/setCurrentFinYear") {
        this.currentFinYear = state.shared.currentFinYear;
        this.getCustomerCount();
      }
    });
  },
  methods: {
    getVendorCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        // fin_year: this.finYear !== "All" ? this.finYear : null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getVendorCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.vendorCount = response.data.data;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getCustomerCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear && this.finYear !== "All" ? this.finYear : this.currentFinYear, // not null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getCustomerCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.customerCount = response.data.data; // login time to get customer dashboard count
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorSecurityCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getVendorSecurityCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.securityCount = response.data.data;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getRecoveryInvoice(){
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("dashboard/getRecoveryInvoiceCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
           this.recoveryCount = response.data.data
           }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function(items) {
      this.selected = items;
    },
    getDetails(item) {
      this.lastUpdateDate = item.latest_lud;
    },
    navigateFromVendorOs() {
      this.$store.dispatch("shared/setVendorOsFlag", true);
    },
    showHideNotifyModal(flag) {
      this.showNotifyModal = flag;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.queries-bg {
  background-image: linear-gradient( black);
  width: 29.5rem !important;
  border-radius: 5px !important;
  color: white;
  margin: 2px;
  font-weight: 500;
  font-style: italic;
  font-size: 16px;
}
</style>
