var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-25"},[_c('b-col',{attrs:{"md":"3"}},[_c('label',[_vm._v("Vendor Name")]),_c('p',[_vm._v(_vm._s(_vm.vendorDetails.name))])])],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-25"},[_c('b-col',[_c('label',[_vm._v("PAN No.")]),_c('p',[_vm._v(_vm._s(_vm.vendorDetails.pan_no))])])],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-25"},[_c('b-col',[_c('label',[_vm._v("Aproval Status")]),_c('p',[_vm._v(_vm._s(_vm.vendorDetails.approval_status))])])],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-25"},[_c('b-col',[_c('label',[_vm._v("PAN Document")]),_c('p',[_c('span',{staticStyle:{"margin-top":"1.8rem","cursor":"pointer"}},[_c('a',{staticStyle:{"margin-left":"0.5rem"},attrs:{"target":"_blank","href":_vm.downloadDocUrl +
                  '/download/' +
                  _vm.vendorDetails.document_det_id +
                  '?token=' +
                  _vm.openKmAuth}},[_c('b',[_vm._v(_vm._s(_vm.vendorDetails.original_file_name))])])])])])],1)]),_vm._m(0),_c('md-card-content',[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.vendorAddressData,"fields":_vm.vendorAddressField,"show-empty":""},scopedSlots:_vm._u([{key:"cell(gstn_not_avail)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.gstn_not_avail ? "Yes" : "No"))])]}},{key:"cell(sez)",fn:function(data){return [_c('span',[_vm._v(_vm._s(data.item.sez ? "Yes" : "No"))])]}},{key:"cell(original_file_name)",fn:function(data){return [_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":function($event){return _vm.getOpenKmAuthenticate(
              data.item.document_det_id,
              data.item.original_file_name
            )}}},[_vm._v(_vm._s(data.item.original_file_name))])]}}])})],1),_vm._m(1),_c('md-card-content',[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.vendorContactData,"fields":_vm.vendorContactField,"show-empty":""}})],1),_vm._m(2),_c('md-card-content',[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.vendorBankData,"fields":_vm.vendorBankFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(original_file_name)",fn:function(data){return [_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":function($event){return _vm.getOpenKmAuthenticate(
              data.item.document_det_id,
              data.item.original_file_name
            )}}},[_vm._v(_vm._s(data.item.original_file_name))])]}}])})],1),_vm._m(3),_c('md-card-content',[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.vendorComplianceData,"fields":_vm.vendorComplianceFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(original_file_name)",fn:function(data){return [_c('a',{staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":function($event){return _vm.getOpenKmAuthenticate(
              data.item.document_det_id,
              data.item.original_file_name
            )}}},[_vm._v(_vm._s(data.item.original_file_name))])]}}])})],1),_vm._m(4),_c('md-card-content',[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","sticky-header":"","items":_vm.vendorAdditionalData,"fields":_vm.vendorAdditionalFields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(add_info_value)",fn:function(data){return [(
            data.item.add_info_vset === 'MSME' ||
              data.item.add_info_vset === 'E-Invoice'
          )?_c('span',[_vm._v(" "+_vm._s(data.item.add_info_value === "true" ? "Yes" : "No"))]):_c('span',[_vm._v(" "+_vm._s(data.item.add_info_value))])]}}])})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticStyle:{"margin-top":"0rem","margin-bottom":"-0.2rem","margin-left":"0.5rem"}},[_c('b',[_vm._v("Address Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticStyle:{"margin-top":"0rem","margin-bottom":"-0.2rem","margin-left":"0.5rem"}},[_c('b',[_vm._v(" Contact Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticStyle:{"margin-top":"0rem","margin-bottom":"-0.2rem","margin-left":"0.5rem"}},[_c('b',[_vm._v(" Bank Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticStyle:{"margin-top":"0rem","margin-bottom":"-0.2rem","margin-left":"0.5rem"}},[_c('b',[_vm._v(" Compliance Details")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticStyle:{"margin-top":"0rem","margin-bottom":"-0.2rem","margin-left":"0.5rem"}},[_c('b',[_vm._v(" Additional Details")])])}]

export { render, staticRenderFns }