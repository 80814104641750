<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-green"
        >
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">search</md-icon>
          </div>
          <p class="title"><strong>Invoice Search</strong></p>
        </md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-16">
            <md-field>
              <label for="font">Request Type</label>
              <md-select v-model="font" name="font" id="font" md-dense>
                <md-option value="arial">Arial</md-option>
                <md-option value="calibri">Calibri</md-option>
                <md-option value="cambria">Cambria</md-option>
                <md-option value="comic-sans">Comic Sans</md-option>
                <md-option value="consolas">Consolas</md-option>
                <md-option value="courier">Courier</md-option>
                <md-option value="droid-sans">Droid Sans</md-option>
                <md-option value="georgia">Georgia</md-option>
                <md-option value="helvetica">Helvetica</md-option>
                <md-option value="impact">Impact</md-option>
                <md-option value="roboto">Roboto</md-option>
                <md-option value="segoe-ui">Segoe UI</md-option>
                <md-option value="times-new-roman">Times New Roman</md-option>
                <md-option value="ubuntu">Ubuntu</md-option>
                <md-option value="verdana">Verdana</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <md-field>
              <label>Value</label>
              <md-input type="text"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <md-datepicker md-immediately
              ><label>From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-16">
            <md-datepicker md-immediately><label>To Date</label></md-datepicker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-button class="md-raised md-success">Search</md-button>
          </div>
          <!-- <div class="md-layout-item md-small-size-100 md-size-15">
              <md-button class="md-raised md-info">Search</md-button>
            </div> -->
        </div>
      </md-card>
    </div>

    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Finantial Year">{{
                item.invoice
              }}</md-table-cell>
              <md-table-cell md-label="Quarter">{{
                item.invoiceNumber
              }}</md-table-cell>
              <md-table-cell md-label="View">{{
                item.invoiceDate
              }}</md-table-cell>
            </md-table-row>
          </md-table>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import // StatsCard,
// ChartCard,
// NavTabsCard
// Notification
"@/components";

export default {
  components: {
    // StatsCard,
    // ChartCard,
    // NavTabsCard
    // Notification
  },

  data() {
    return {
      selected: [],
      tableData: [
        {
          invoice: "2022",
        },
        {
          invoice: "2022",
        },
        {
          invoice: "2022",
        },
        {
          invoice: "2022",
        },
      ],
      tabValue: "viewProfile",
    };
  },
  methods: {
    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function (items) {
      this.selected = items;
    },
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
}
</style>
