<template>
  <div style="background-color: lightgrey">
    <md-card>
      <div style="margin-left: 71.5rem" v-if="$route.name !== componentName">
        <md-button
          class="md-raised md-success search-btn"
          @click="openBankModal(true)"
          >Add Bank</md-button
        >
      </div>
      <md-card-header
        v-if="$route.name !== componentName"
        class="md-card-header-text md-layout-item md-card-header-green"
        style="margin-top: -29px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Existing Bank</h5>
        </div>
      </md-card-header>
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="gridData"
          :fields="gridField"
          show-empty
          @row-clicked="rowSelected($event, 'exist')"
          class="bg-white"
        >
          <template #cell(active)="data">
            <span>{{ data.item.active ? "Yes" : "No" }}</span>
          </template>
        </b-table>
        <b-pagination
          v-model="existCurrentPage"
          :total-rows="existTotalRows"
          :per-page="existPerPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        v-model="showModal"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        title="Add Bank"
        @close="closeHandle"
      >
        <!-- @hidden="openBankModal(false, null)" -->
        <addBank
          :bankDetails="bankDetails"
          @updatedBankResponse="updatedBankResponse"
          @deleteBankById="setPpIdBank"
          :ppBankId="ppBankId"
          @saveButtonCloseModal="uploadEnabled = false"
          :recordType="recordType"
        />
        <template #cell(upload)="data">
          <a class="info; pointer" @click="getOpenKmAuthenticate(data.item)"
            >View</a
          >
        </template>
      </b-modal>
    </md-card>
    <md-card v-if="$route.name !== componentName">
      <md-card-header
        class="md-card-header-text md-layout-item md-card-header-blue"
        style="margin-top: 8px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Updated Bank</h5>
        </div>
      </md-card-header>
      <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="updateData"
          :fields="updateField"
          show-empty
          class="bg-white"
          @row-clicked="rowSelected($event, 'update')"
        >
          <template #cell(active)="data">
            <span>{{ data.item.active ? "Yes" : "No" }}</span>
          </template>
          <template #cell(upload)="data">
            <a class="info; pointer" @click="getOpenKmAuthenticate(data.item)"
              >View</a
            >
          </template>
        </b-table>
        <b-pagination
          v-model="updateCurrentPage"
          :total-rows="updateTotalRows"
          :per-page="updatePerPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </div>
      <b-modal
        v-model="showHistory"
        no-close-on-esc
        no-close-on-backdrop
        hide-footer
        size="xl"
        title="Upload Document"
        @close="closeHandleUploadDocument"
      >
        <uploadDocument
          :openKmAuth="openKmAuth"
          @closeOpenKmModal="showHideHistory(false, null)"
          :propMenuId="menuId"
          :PropSubMenuId="subMenuId"
          :wfHdrId="bankPpId"
          :selectedInvoiceData="selectedInvoiceData"
          @uploadedDocLists="uploadedDocLists"
        ></uploadDocument>
      </b-modal>
    </md-card>
  </div>
</template>
<script>
import addBank from "./AddBank.vue";
import commonHelper from "../../../../utility/common.helper.utility";
import uploadDocument from "../../../uploadDocument.vue";
export default {
  components: {
    addBank,
    uploadDocument,
  },
  watch: {
    existCurrentPage: function() {
      this.getPartyBank();
    },
    existPerPage: function() {
      this.existCurrentPage = 1;
      this.getPartyBank();
    },
    updateCurrentPage: function() {
      this.getUpdatedBank();
    },
    updatePerPage: function() {
      this.updateCurrentPage = 1;
      this.getUpdatedBank();
    },
  },
  data() {
    return {
      componentName: "Party Details",
      existPerPage: commonHelper.perPageRecord,
      existCurrentPage: 1,
      existTotalRows: null,
      updatePerPage: commonHelper.perPageRecord,
      updateCurrentPage: 1,
      updateTotalRows: null,
      showModal: false,
      bankDetails: null,
      gridData: [],
      gridField: [
        {
          key: "bank_name",
        },
        {
          key: "bank_branch",
        },
        {
          key: "account_type",
        },
        {
          key: "account_num",
          label: "Account Number",
        },
        {
          key: "ifsc_code",
          label: "IFSC Code",
        },
        {
          key: "swift_code",
          label: "SWIFT Code",
        },
        {
          key: "active",
        },
      ],
      updateData: [
        {
          active: false,
          party_id: null,
          approved_by: null,
          approval_date: null,
          approval_status: null,
          rejected_reason: null,
          posting_status: null,
          ifsc_code: null,
          swift_code: null,
          main: null,
          bank_vset: null,
          bank_branch: null,
          bank_acc_type_vset_meaning: null,
          bank_name: null,
          account_num: null,
          bank_acc_type_vset: null,
          posting_status_meaning: null,
          pp_party_bank_id: null,
          erp_bank_account_id: null,
        },
      ],
      updateField: [
        {
          key: "bank_name",
        },
        {
          key: "bank_branch",
        },
        {
          key: "bank_acc_type_vset_meaning",
          label: "Account Type",
        },
        {
          key: "account_num",
          label: "Account Number",
        },
        {
          key: "ifsc_code",
          label: "IFSC Code",
        },
        {
          key: "swift_code",
          label: "SWIFT Code",
        },
        {
          key: "active",
        },
        {
          key: "approval_status",
          label: "Status",
        },
        {
          key: "rejected_reason",
          label: "Reason",
        },
        {
          key: "upload",
          label: "Document",
        },
      ],
      openKmAuth: null,
      showHistory: false,
      uploadEnabled: false,
      ppBankId: null,
      menuId: 326,
      subMenuId: null,
      bankPpId: null,
      selectedInvoiceData: null,
      numberOfUpload: 0,
      recordType: null,
    };
  },
  mounted() {
    this.getPartyBank();
    this.bankDetails = null;
    this.getUpdatedBank();
    this.getSubMenuById();
  },
  methods: {
    getPartyBank() {
      const payload = {
        _page: this.existCurrentPage - 1,
        _limit: this.existPerPage,
      };
      this.$store.dispatch("party/getPartyBank", payload).then((resp) => {
        if (resp.status === 200) {
          this.gridData = resp.data.data.page;
          this.existTotalRows = resp.data.data.total_elements;
        }
      });
    },
    rowSelected(item, recordType) {
      if (this.componentName !== this.$route.name) {
        this.ppBankId = item.pp_party_bank_id;
        this.recordType = recordType;
        this.openBankModal(true, item);
      }
    },
    openBankModal(flag, item) {
      this.bankDetails = item;
      if (!item) {
        this.recordType = "new";
      }
      this.showModal = flag;
      this.uploadEnabled = false;
    },
    getUpdatedBank() {
      const payload = {
        _page: this.updateCurrentPage - 1,
        _limit: this.updatePerPage,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getUpdatedBank", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.updateData = response.data.data.page;
            this.ppBankId = this.updateData[0].pp_party_bank_id;
            this.updateTotalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    updatedBankResponse(value) {
      this.uploadEnabled = value;
      this.getUpdatedBank();
    },
    getOpenKmAuthenticate(item) {
      this.bankPpId = item.pp_party_bank_id;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    deleteBankById(ppId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/deleteBankById", ppId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.uploadEnabled = false;
            this.showMoal = false;
          }
        })
        .then(() => this.getUpdatedBank())
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    setPpIdBank(value) {
      this.ppId = value;
    },
    closeHandle(bvModalEvent) {
      if (this.uploadEnabled) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deleteBankById(this.ppId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    closeHandleUploadDocument(bvModalEvent) {
      if (this.numberOfUpload === 0) {
        const input = confirm("Do you want upload Document?");
        if (!input) {
          this.deleteBankById(this.bankPpId);
        } else {
          // Prevent modal from closing
          bvModalEvent.preventDefault();
        }
      }
    },
    uploadedDocLists(item) {
      this.numberOfUpload = item.length;
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_drawee_bank_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
