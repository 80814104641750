<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div>
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label for="font">Legal Entity</label>
            <md-input
              v-model="poHdrDetails.legel_entity"
              :title="poHdrDetails.legel_entity"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label for="font">PO Number</label>
            <md-input
              v-model="poHdrDetails.po_number"
              :title="poHdrDetails.po_number"
              disabled
            >
            </md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label>Company GSTN</label>
            <md-input
              v-model="poHdrDetails.first_party_reg"
              :title="poHdrDetails.first_party_reg"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label>Third Party GSTN</label>
            <md-input
              v-model="poHdrDetails.third_party_reg"
              :title="poHdrDetails.third_party_reg"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label>Total Value</label>
            <md-input
              v-model="poHdrDetails.total_value"
              :title="poHdrDetails.total_value"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-14">
          <md-field>
            <label>Open Value</label>
            <md-input
              v-model="poHdrDetails.open_value"
              :title="poHdrDetails.open_value"
              disabled
            ></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-16">
          <md-field>
            <label>Location Code</label>
            <md-input
              v-model="poHdrDetails.location_code"
              :title="poHdrDetails.location_code"
              disabled
            ></md-input>
          </md-field>
        </div>
      </div>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <div>
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="poGridData"
          :fields="poGridField"
          show-empty
          class="bg-white"
        >
          <template #head(item_code)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(item_description)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(hsn)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(line_quantity)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(need_by_date)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(promised_date)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(quantity_received)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(quantity_billed)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(tax_rate)="data">
            <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
          </template>
          <template #head(total_value)="data">
            <div style="text-align:center !important;">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </div>
          </template>
          <template #cell(total_value)="data">
            <span class="text-fix-po-line">
              {{ formatCurr(data.item.total_value) }}</span
            >
          </template>
          <template #head(gst_amount)="data">
            <div style="text-align:center !important;">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </div>
          </template>
          <template #cell(gst_amount)="data">
            <span class="text-fix-po-line">
              {{ formatCurr(data.item.gst_amount) }}</span
            >
          </template>
          <template #head(unit_price)="data">
            <div style="text-align:center !important;">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </div>
          </template>
          <template #cell(unit_price)="data">
            <span class="text-fix-po-line">
              {{ formatCurr(data.item.unit_price) }}</span
            >
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
        <div
          v-if="poGridData.length && totalRows <= perPage"
          class="pagination-count"
        >
          <p>
            Showing
            {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
            to {{ totalRows }} of {{ totalRows }} entries
          </p>
        </div>
        <div v-else-if="poGridData.length" class="pagination-count">
          <p>
            Showing {{ perPage * (currentPage - 1) + 1 }} to
            {{
              perPage * currentPage >= totalRows
                ? totalRows
                : perPage * currentPage
            }}
            of {{ totalRows }} entries
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import HeaderSummary from "../HeaderSummary.vue";
export default {
  name: "PoLineDetails",
  components: { HeaderSummary },
  watch: {
    currentPage: function() {
      this.getVendorPoLineDetailsById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPoLineDetailsById();
    },
  },
  props: ["poLineRowDetails"],
  data() {
    return {
      loader: false,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      poHdrDetails: {
        po_number: null,
        currency_code: null,
        first_party_reg: null,
        third_party_reg: null,
        location_code: null,
        total_value: null,
        open_value: null,
      },
      poGridField: [
        {
          key: "item_code",
        },
        {
          key: "item_description",
          class: "party-col-fix",
        },
        {
          key: "hsn",
          label: "HSN/SAC",
        },
        {
          key: "line_quantity",
        },
        {
          key: "need_by_date",
        },
        {
          key: "promised_date",
        },
        {
          key: "quantity_received",
        },
        {
          key: "quantity_billed",
        },
        {
          key: "unit_price",
          class: "text-fix-po-line",
        },
        {
          key: "total_value",
          label: "Basic Value",
          class: "text-fix-po-line",
        },
        {
          key: "gst_amount",
          label: "GST Amount",
          class: "text-fix-po-line",
        },
        {
          key: "tax_rate",
        },
      ],
      poGridData: [],
      poHdrId: null,
      menuId: null,
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("PODetails"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.poLineRowDetails) {
      this.poHdrId = this.poLineRowDetails.po_header_id;
      this.fillRecordForPoDetails(this.poLineRowDetails);
      this.getVendorPoLineDetailsById();
    }
  },
  methods: {
    fillRecordForPoDetails(result) {
      this.poHdrDetails = result;
    },
    formatCurr(num) {
      return commonHelper.formatAmount(Number(num));
    },
    getVendorPoLineDetailsById() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        po_release_id: this.poLineRowDetails.po_release_id,
        po_header_id: this.poHdrId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPoLineDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.poGridData = result.map((elem) => {
              elem.need_by_date = commonHelper.formatDate(elem.need_by_date);
              elem.promised_date = commonHelper.formatDate(elem.promised_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>

<style>
.party-col-fix > div {
  width: 220px !important;
}
.text-fix-po-line {
  margin-right: 23%;
  text-align: right;
}
</style>
