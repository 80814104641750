<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Legal Entity</label>
              <md-input type="text" v-model="legalEntity"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>PAN</label>
              <md-input type="text" v-model="pan"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Finantial Year</label>
              <md-input type="text" v-model="finantialYear"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Quater</label>
              <md-input type="text" v-model="finantialYear"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Customer TAN</label>
              <md-input type="text" v-model="finantialYear"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-success search-btn"
                @click="searchData()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      style="margin-top: 5px"
    >
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
          >
            <div class="card-text profile-card" style="padding: 5px !important">
              <h6 class="title">TDS Certificate</h6>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <div>
              <md-button
                class="md-raised md-info search-btn"
                @click="showModal(true)"
                >Add TDS</md-button
              >
            </div>
          </div>
          <!-- <md-card-header class="md-card-header-text md-card-header-blue">
            <div
              class="card-text profile-card"
              style="padding: 5px !important; cursor: pointer"
              @click="addTds(true)"
            >
              <h4 class="title">Add TDS</h4>
            </div>
          </md-card-header> -->
        </div>
        <md-card-content>
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
          >
            <template #cell(le_short_name)="data">
              <span :title="data.item.le_full_name">{{
                data.item.le_short_name
              }}</span></template
            >
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </md-card-content>
      </md-card>
    </div>
    <b-modal
      v-model="showTdsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      @hidden="showModal(false)"
      title="Add TDS"
    >
      <AddTdsCertificateVue />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../../utility/common.helper.utility";
import AddTdsCertificateVue from "./AddTdsCertificate.vue";
export default {
  components: {
    AddTdsCertificateVue,
  },
  watch: {
    currentPage: function () {
      this.getBillInfo();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getBillInfo();
    },
  },
  data() {
    return {
      showTdsModal: false,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      legalEntity: null,
      pan: null,
      finantialYear: null,
      gridData: [],
      gridField: [
        {
          key: "sno",
          label: "S.No",
          class: "text-center",
        },
        {
          key: "le",
          label: "LE",
          class: "text-center",
        },

        { key: "pan", label: "PAN", class: "text-center" },
        { key: "FY", label: "FY", class: "text-center" },
        { key: "Quater", class: "text-center" },
        { key: "customer_tan", class: "text-center" },
        {
          key: "tds_certificate",
          label: "TDS Certificate",
          class: "text-center",
        },
        {
          key: "cn_no",
          label: "CN NO",
          class: "text-center",
        },
        {
          key: "Amount_inr",
          label: "Amount INR",
          class: "text-center",
        },
        {
          key: "date",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    searchData(tab) {
      this.tabValue = tab;
    },
    clearSearch() {},
    showModal(flag) {
      this.showTdsModal = flag;
    },
  },
};
</script>
<style>
</style>
