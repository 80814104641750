<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>

    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-100">
        <!-- <md-field> -->
        <label for="text">To</label>
        <!-- <md-input></md-input> -->
        <b-form-input></b-form-input>
        <!-- </md-field> -->
      </div>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field>
          <label for="text">Subject</label>
          <md-input></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-100">
        <md-field>
          <label for="text">Body</label>
          <md-textarea></md-textarea>
        </md-field>
      </div>
    </div>
    <div style="margin-top:15%;">
      <md-button
        @click="showHideEmailModal(true)"
        class="md-info md-just-icon send-mail"
        style="height:30px; width:40px; margin-right:1%;"
        title="Upload Document"
        ><md-icon>attach_file</md-icon></md-button
      >
      <md-button
        @click="showHideEmailModal(true)"
        class="md-info send-mail"
        title="Download Excel"
        >Send Mail</md-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "VendorInvoiceEmail",
  watch: {},
  components: {},
  props: [""],
  data() {
    return {
      //   pageOptions: commonHelper.getPageOption(),
      //   perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style>
.send-mail {
  border-radius: 25px !important;
}
</style>
