<template>
  <div class="md-layout text-center login-fix-page">
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <!-- <div style="margin-left: 127%;">
      <md-button
        :style="
          showLoginForm ? 'margin-top: -10.2rem;' : 'margin-top: -17.5rem;'
        "
        class="md-rose"
        @click="showHideLoginFrom()"
        >Login</md-button
      >
    </div> -->
    <div
      class="
        md-layout-item
        md-size-33
        md-medium-size-50
        md-small-size-70
        md-xsmall-size-100
      "
      style="display: flex; justify-content: center"
    >
      <!-- style="display: flex; justify-content: center; margin-left: 77rem; margin-top: -8rem" -->
      <!-- <form @submit.prevent="login" v-if="showLoginForm"> -->
      <form @submit.prevent="login">
        <login-card header-color="rose">
          <h4 slot="title" class="title">Log in to access your Dashboard</h4>
          <md-field
            v-if="
              status != 'setPassword' ||
                (status === 'success' && status != 'success')
            "
            class="form-group md-invalid"
            slot="inputs"
          >
            <md-icon>email</md-icon>
            <label>Email...</label>
            <md-input
              v-model="email"
              type="email"
              @blur="validateEmail"
              v-on:focus="
                error = {
                  message: '',
                }
              "
            />
            <div
              v-if="error.reset"
              class="error"
              role="alert"
              style="position: absolute;
              margin-top: 2.5rem;"
            >
              {{ error.reset }}
            </div>
            <validation-error v-else :errors="apiValidationErrors.email" />
          </md-field>
          <div
            class="form-group md-invalid"
            style="display: flex; flex-direction: column; margin-top: 1rem;"
            slot="inputs"
          >
            <div
              style="margin-bottom: 10px"
              v-if="status === 'login' || status === 'success'"
            >
              <md-icon style="float: left">lock_outline</md-icon>
              <label style="float: left">Password</label>
            </div>
            <div
              style="display: flex; flex-direction: row"
              class="mt-1"
              v-if="status === 'login' || status === 'success'"
            >
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator="-"
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                input-disabled="true"
                input-type="password"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />
            </div>
            <div class="form-group px-24" v-if="status === 'verify'">
              <label for="password">OTP</label>
              <div style="display: flex; flex-direction: row" class="mt-1">
                <v-otp-input
                  ref="verifyOtp"
                  input-classes="otp-input"
                  separator="-"
                  :num-inputs="6"
                  :should-auto-focus="true"
                  :is-input-num="true"
                  input-disabled="true"
                  input-type="password"
                  @on-change="handleOnVerifyOTP"
                  @on-complete="handleOnCompleteOTP"
                />
              </div>

              <div class="alert alert-danger" v-if="error.verify">
                <p>OTP is incorrect</p>
              </div>
            </div>
            <div v-if="status === 'setPassword'">
              <div class="form-group px-24">
                <div v-if="error.newPassword">
                  <div class="alert alert-danger">
                    {{ error.newPassword }}
                  </div>
                </div>
              </div>

              <div class="form-group px-24">
                <label for="password">New Password</label>
                <div style="display: flex; flex-direction: row" class="mt-1">
                  <v-otp-input
                    ref="password"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="6"
                    :should-auto-focus="true"
                    :is-input-num="true"
                    input-disabled="true"
                    input-type="password"
                    @on-change="handleOnChange"
                  />
                </div>
              </div>
              <div class="form-group px-24">
                <label for="password">Verify Password</label>
                <div style="display: flex; flex-direction: row" class="mt-1">
                  <v-otp-input
                    ref="verifyPassword"
                    input-classes="otp-input"
                    separator="-"
                    :num-inputs="6"
                    :is-input-num="true"
                    input-disabled="true"
                    input-type="password"
                    @on-complete="handleOnCompleteSetPassword"
                  />
                </div>
              </div>
            </div>
            <p
              v-if="
                status != 'verify' &&
                  status != 'success' &&
                  status != 'setPassword'
              "
            >
              <span
                class="pointer"
                style="color: deeppink"
                href="#"
                @click="resetPassword"
                :class="isValid ? 'btn' : 'btn disabled'"
                >Forgot Password</span
              >
            </p>
          </div>
        </login-card>
      </form>
    </div>
  </div>
</template>

<script>
import { LoginCard, ValidationError } from "@/components";
import { validateEmail } from "../../../services/api";
import formMixin from "@/mixins/form-mixin";
import CryptoJS from "crypto-js";

export default {
  components: {
    LoginCard,
    ValidationError,
  },

  mixins: [formMixin],

  data: () => ({
    loader: false,
    email: "",
    password: null,
    error: {},
    isValid: false,
    status: "login",
    reset: false,
    otp: " ",
    message: " ",
    verifyPassword: " ",
    // showLoginForm: false,
    loginResponse: {
      token: null,
      user_ref_id: null,
      party_type: null,
      user_type: null,
      is_new_vendor: null,
    },
    loginToken:null,
  }),
  mounted() {
    let vendorToken = sessionStorage.getItem("encryptedToken");
    let secretKey = "CON_DSL_123";
    sessionStorage.clear();
    if (vendorToken) {
      try {
        const bytes = CryptoJS.AES.decrypt(vendorToken, secretKey);
        const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);   
        this.loginToken=decryptedToken;
        if(this.loginToken){
          this.login()
        }        
        this.loginToken=null
      } catch (error) {
        this.$bvToast.toast("Error decrypting token:", error, {
        title: "Alert",
        variant: 'danger',
        solid: true,
      });
      }
    } else {
      this.$bvToast.toast("No token found in the URL", {
        title: "Alert",
        variant: 'danger',
        solid: true,
      });
    }
  },
  methods: {
    validateEmail() {
      const email = this.email;
      const isEmailValid = validateEmail(email);
      isEmailValid
        .then((resp) => {
          if (resp.status == 200) this.isValid = true;
          else {
            this.$store.dispatch("alerts/error", resp.data.message);
            this.error = {
              message: "User not found",
              user: null,
            };
          }
        })
        .catch((err) => {
          this.$store.dispatch("alerts/error", resp.data.message);
          this.error = {
            message: "User not found",
            user: err,
          };
        });
    },
    handleOnComplete: function(value) {
      this.password = value;
      this.login();
    },
    handleOnChange: function(value) {
      this.verifyPassword = value;
      this.error = {
        setPassword: "",
      };
      this.reset = false;
    },
    handleOnCompleteOTP: function(value) {
      this.otp = value;
      this.forgotPassword();
    },
    handleOnVerifyOTP: function(value) {
      this.error = {
        verify: "",
      };
      this.otp = value;
    },
    handleOnCompleteSetPassword: function(value) {
      this.password = value;
      if (this.password != this.verifyPassword) {
        this.error = {
          setPassword: "Password not match",
        };
        this.$store.dispatch("alerts/error", "Password not match");
      } else {
        this.setPassword();
      }
    },
    async login() {
            if(this.loginToken!==null){
        var payload = {
        // user_email: this.email,
        // password: this.password,
        token:this.loginToken
      };
      this.loginToken=null
      }else{
        var payload = {
        user_email: this.email,
        password: this.password,
      };
      }
      this.loader = true;
      this.$store
        .dispatch("login", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.loginResponse = response.data.data;
            sessionStorage.setItem("setUserAdminFlag", response.data.data.external_admin)
            sessionStorage.setItem("authToken", response.data.data.token);
            this.$store.dispatch("setAuthToken", response.data.data.token);
            if (this.loginResponse.vendor_portal_admin == "PARTY_REQ_ADMIN") {
              sessionStorage.setItem("userType", response.data.data.user_type);
              this.$store.dispatch("setUserType", response.data.data.user_type);
              sessionStorage.setItem("userId", response.data.data.user_id);
              this.$store.dispatch("setUserId", response.data.data.user_id);
              sessionStorage.setItem(
                "vendorPortalAdmin",
                response.data.data.vendor_portal_admin
              );
              this.$store.dispatch(
                "setVendorPortalAdmin",
                response.data.data.vendor_portal_admin
              );
              this.$router.push({ name: "Admin Page" });
            } else {
              sessionStorage.setItem("partyId", response.data.data.user_ref_id);
              this.$store.dispatch(
                "setPartyId",
                response.data.data.user_ref_id
              );
              sessionStorage.setItem(
                "partyType",
                response.data.data.party_type
              );
              this.$store.dispatch(
                "setPartyType",
                response.data.data.party_type
              );
              sessionStorage.setItem(
                "isNewVendor",
                response.data.data.is_new_vendor
              );
              this.$store.dispatch(
                "setIsNewVendor",
                response.data.data.is_new_vendor
              );
              sessionStorage.setItem("userType", response.data.data.user_type);
              this.$store.dispatch("setUserType", response.data.data.user_type);
              sessionStorage.setItem("userId", response.data.data.user_id);
              this.$store.dispatch("setUserId", response.data.data.user_id);
              sessionStorage.setItem(
                "vendorPortalAdmin",
                response.data.data.vendor_portal_admin
              );
              this.$store.dispatch(
                "setVendorPortalAdmin",
                response.data.data.vendor_portal_admin
              );
              if (response.data.data.user_type == "PARTY") {
                this.$router.push({ path: "/party-dashboard" });
              } else if (response.data.data.user_type == "VENDOR") {
                this.$router.push({ name: "Home" });
                this.getName();
              } else if (response.data.data.user_type == "BOTH") {
                sessionStorage.setItem("bothTypeUser", "BOTH");
                this.$router.push({ name: "Routing" });
              }
            }
            this.getMenuSubMenuId();
          } else {
            this.$store.dispatch("alerts/error", response.data.message);
            this.$refs.otpInput?.clearInput();
            // this.setApiValidation(response.data.errors);
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("alerts/error", "Something Went Wrong!");
        });
    },
    // showHideLoginFrom() {
    //   this.showLoginForm = !this.showLoginForm;
    // },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorId = result.ora_party_id;
            if (this.loginResponse.is_new_vendor) {
              this.$router.push({ name: "Change Password" });
            } else if (!this.loginResponse.is_new_vendor) {
              if (this.vendorId) {
                this.$router.push({ name: "Home" });
              } else {
                this.$router.push({ name: "Party Onboarding" });
              }
            }
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getMenuSubMenuId() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getMenuSubMenuId")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            result.forEach((ele) => {
              if (ele.menu_name === "OnBoarding") {
                if (ele.sub_menu_name === "ADDRESS") {
                  this.$store.dispatch("setAddress", ele);
                  // sessionStorage.setItem("ADDRESS", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "BANK DETAILS") {
                  this.$store.dispatch("setBankDetails", ele);
                  // sessionStorage.setItem("BANK DETAILS", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "COMPLIANCE") {
                  this.$store.dispatch("setCompliance", ele);
                  // sessionStorage.setItem("COMPLIANCE", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "ONBOARDING") {
                  this.$store.dispatch("setOnBoarding", ele);
                  // sessionStorage.setItem("ONBOARDING", JSON.stringify(ele));
                } else if (ele.sub_menu_name === "ADDITIONAL DETAILS") {
                  this.$store.dispatch("setAddItionalDtls", ele);
                  // sessionStorage.setItem("ADDITIONAL DETAILS", JSON.stringify(ele));
                }
              } else if (ele.menu_name === "VendorInvoiceEntry") {
                this.$store.dispatch("setVendorInvoiceEntry", ele);
                sessionStorage.setItem(
                  "VendorInvoiceEntry",
                  JSON.stringify(ele)
                );
              } else if (ele.menu_name === "InvoiceDashboard") {
                this.$store.dispatch("setInvoiceDashboard", ele);
                sessionStorage.setItem("InvoiceDashboard", JSON.stringify(ele));
              } else if (ele.menu_name === "VendorPayment") {
                this.$store.dispatch("setVendorPayment", ele);
                sessionStorage.setItem("VendorPayment", JSON.stringify(ele));
              } else if (ele.menu_name === "PODetails") {
                this.$store.dispatch("setPODetails", ele);
                sessionStorage.setItem("PODetails", JSON.stringify(ele));
              } else if (ele.menu_name === "InvoiceNotBooked") {
                this.$store.dispatch("setInvoiceNotBooked", ele);
                sessionStorage.setItem("InvoiceNotBooked", JSON.stringify(ele));
              } else if (ele.menu_name === "OpenAdvance") {
                this.$store.dispatch("setOpenAdvance", ele);
                sessionStorage.setItem("OpenAdvance", JSON.stringify(ele));
              }
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    resetPassword: function() {
      const user_email = this.email;
      if (this.email != "") {
        this.validateEmail();
        if (this.isValid) {
          this.$store
            .dispatch("passwordReset", { user_email })
            .then((resp) => {
              if (resp.status === 200) {
                this.title = "Forgot Password";
                this.message = "Request completed successfully";
                this.status = "verify";
              }
            })
            .catch((err) => {
              this.error = {
                reset: err,
              };
              this.status = "reset";
            });
        }
      } else {
        this.error = {
          reset: "Email field is required",
        };
      }
    },
    forgotPassword: function() {
      const user = {
        user_email: this.email,
        otp: this.otp,
      };
      this.$store
        .dispatch("confirmOTP", user)
        .then((resp) => {
          if (resp.status === 200) {
            this.status = "setPassword";
            this.$store.dispatch("alerts/error", resp.data.message);
          } else {
            this.$store.dispatch("alerts/error", resp.data.message);
          }
        })
        .catch((err) => {
          this.error = {
            message: err,
          };
        });
    },
    setPassword: function() {
      if (this.password != "" && this.verifyPassword != "") {
        const user = {
          new_password: this.password,
          user_email: this.email,
        };
        this.$store
          .dispatch("setNewPassword", user)
          .then((resp) => {
            if (resp.status === 200) {
              this.status = "login";
              this.reset = true;
              this.$store.dispatch(
                "alerts/error",
                "Password Change Successfully"
              );
            } else {
              this.password = null;
              this.verifyPassword = null;
              this.$store.dispatch("alerts/error", resp.data.message);
            }
          })
          .catch((err) => {
            this.error = {
              newPassword: err,
            };
            this.status = "setPassword";
          });
      } else {
        this.error = {
          required: "Password field(s) cannot be empty",
        };
      }
    },
  },
};
</script>
<style>
.login-fix-page {
  padding-bottom: 7em;
  padding-top: 4em;
}

.otp-input {
  width: 50px;
  height: 40px;
  padding: 5px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;
}

.spinner-postion {
  position: absolute;
  left: 45%;
  top: 30%;
}
/* .LoginBtn {
  font-size: 0.9rem;
  background: #034d34;
  padding: 0px 1px 2px;
  text-decoration: none;
  color: #fff;
}
.LoginBtn:hover {
  color: #fff;
  text-decoration: none;
  background: #666;
} */
</style>
