<template>
  <md-card-content>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <b-alert
      style="width:50%;"
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Name</label>
          <md-input
            v-model="contactName"
            :title="contactName"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.contactName.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field md-clearable>
          <label for="search">Mobile</label>
          <md-input
            v-model="contactMobile"
            maxlength="10"
            v-numericOnly
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field md-clearable>
          <label for="search">Email</label>
          <md-input
            v-model="contactEmail"
            :title="contactEmail"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.contactEmail.$error">
          Please enter valid email
        </div>
      </div>
      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="addEditVendorContactDetails()"
        v-if="!isContactSaved"
        >Save</md-button
      >
      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="editModeContact()"
        v-if="!editMode"
        >Edit</md-button
      >
    </div>
  </md-card-content>
</template>

<script>
import appStrings from "../../../utility/string.utility";
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "PartyOnboardingContact",
  props: ["contactRowDetails", "approvalStatus"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isContactSaved: false,
      contactId: 0,
      contactName: null,
      contactMobile: null,
      contactEmail: null,
    };
  },
  validations: {
    contactName: { required },
    contactEmail: { email },
  },
  mounted() {
    if (this.contactRowDetails) {
      this.fillRecordForContactDetails(this.contactRowDetails);
      this.editMode = false;
      this.isContactSaved = true;
    } else {
      this.editMode = true;
      this.getVendorDetails();
    }
  },
  methods: {
    validateEmail() {
      if (this.contactEmail) {
        if (
          /^[a-z0-9._-]+@[a-z0-9._-]+\.[a-z]{2,6}$/.test(
            this.contactEmail.toLowerCase()
          )
        ) {
          this.addEditVendorContactDetails();
        } else {
          alert("Please Enter Valid Email Id");
        }
      } else {
        this.addEditVendorContactDetails();
      }
    },
    addEditVendorContactDetails() {
      this.contactMobile = this.contactMobile.replace(/[^0-9]/g, "");
      if (this.contactMobile.length == 10) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          const payload = {
            contact_id: this.contactId,
            party_req_id: this.$store.state.auth.partyId,
            name: this.contactName,
            mob: this.contactMobile,
            email: this.contactEmail ? this.contactEmail.toLowerCase() : null,
          };
          this.loader = true;
          this.$store
            .dispatch("vendor/addEditVendorContactDetails", payload)
            .then((response) => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                const result = response.data.data;
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.isContactSaved = true;
                this.fillRecordForContactDetails(result);
                this.$emit("updatedList");
              } else {
                this.isSuccess = false;
                this.responseMsg = response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      } else {
        alert("Please Enter Correct Mobile Number.");
      }
    },
    editModeContact() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isContactSaved = false;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    fillRecordForContactDetails(result) {
      this.contactId = result.contact_id;
      this.contactName = result.name;
      this.contactMobile = result.mob;
      this.contactEmail = result.email;
    },
    getVendorDetails() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.contactEmail = result.email;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
