<template>
  <div class="md-layout" style="width: 125%">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-40"
      style="margin-top: 12rem;"
    >
      <stats-card class="border cardBorder" header-color="rose">
        <template slot="header">
          <div style="margin-top:3%; text-align: center;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/party-dashboard"
              ><h3
                style="text-decoration: underline;"
                @click="setUserType('party')"
              >
                <b>Party Portal</b>
              </h3>
            </router-link>
          </div>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-40"
      style="margin-top: 12rem;"
    >
      <stats-card class="border cardBorder stats-height" header-color="rose">
        <template slot="header">
          <div style="margin-top:3%; text-align: center;" class="stats">
            <router-link
              style="font-size:13px; color:#007bff !important"
              to="/dashboard"
              ><h3
                style="text-decoration: underline;"
                @click="setUserType('vendor')"
              >
                <b>Vendor Portal</b>
              </h3>
            </router-link>
          </div>
        </template>
      </stats-card>
    </div>
  </div>
</template>
<script>
import { StatsCard } from "@/components";
export default {
  components: {
    StatsCard,
  },
  name: "BillInfo",
  // watch: {
  //   currentPage: function() {
  //   },
  //   perPage: function() {
  //     this.currentPage = 1;
  //   },
  // },
  data() {
    return {
      loader: false,
      // perPage: commonHelper.perPageRecord,
      // pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      gridData: [],
      gridField: [],
      userType: null,
      partyName: null,
    };
  },
  mounted() {
    this.userType = sessionStorage.getItem("userType");
  },
  methods: {
    setUserType(type) {
      if (type === "party") {
        sessionStorage.setItem("userType", "PARTY");
        this.getPartyName();
      } else if (type === "vendor") {
        sessionStorage.setItem("userType", "VENDOR");
        this.getName();
      }
    },
    getPartyName() {
      this.$store.dispatch("party/getDetails", "details").then((resp) => {
        if (resp.status === 200) {
          this.eventBus.$emit(
            "partyName",
            resp.data.data.party_details.party_name
          );
        }
      });
    },
    getName() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.eventBus.$emit("vendorName", result.name);
            // this.$store.commit("vendor/setVendorId", result.ora_party_id);
            // sessionStorage.setItem("VendorId", JSON.stringify(result.ora_party_id));
            // this.$store.commit("vendor/setVendorName", result.name);
            // sessionStorage.setItem("VendorName", JSON.stringify(result.name));
          }
        })
        .catch(() => {
          this.loader = false;
          this.$store.dispatch("shared/setLoader", false);
        });
    }
  },
  beforeDestroy() {},
};
</script>
<style>
.border:hover {
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.cardBorder {
  border: 5px solid lightsteelblue !important;
  border-radius: 40px !important;   
}
.header-report {
  font-size: 16px !important;
  color: #007bff !important;
}
</style>
