<template>
  <b-row>
    <md-card-content>
      <div class="overlayLoader" v-if="loader">
        <md-progress-spinner
          :md-diameter="100"
          class="md-accent spinner-postion"
          :md-stroke="7"
          md-mode="indeterminate"
        ></md-progress-spinner>
      </div>
      <b-alert
        style="width:50%;"
        :variant="isSuccess ? 'primary' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}
      </b-alert>
      <div class="md-layout-item">
        <b-table
          style="max-height:25rem;"
          striped
          hover
          outlined
          sticky-header
          :items="additionalData"
          :fields="additionalFields"
          show-empty
          class="bg-white"
        >
          <template #cell(add_info_desc_meaning)="data">
            <span
              :class="
                data.item.add_info_desc !== 'E-Invoice' ? 'mandatory' : ''
              "
              >{{ data.item.add_info_desc_meaning }}</span
            >
            <br />
            <span
              v-if="data.item.add_info_desc == 'Major Clients'"
              style="color:red; font-size:13px !important;"
              >*Multiple entries can be done separated by enter<br />
              (Please capture complete company name)</span
            >
            <span
              v-if="data.item.add_info_desc == 'Product Portfolio'"
              style="color:red; font-size:13px !important;"
              >*Multiple entries can be done separated by enter<br />(Please
              capture complete Product name)</span
            >
            <span
              v-if="data.item.add_info_desc == 'Name of Directors'"
              style="color:red; font-size:13px !important;"
              >*Multiple entries can be done separated by enter<br />(Please
              capture complete Director name)</span
            >
          </template>
          <template #cell(add_info_value)="data">
            <b-form-checkbox
              v-if="data.item.add_info_desc == 'E-Invoice'"
              v-model="data.item.add_info_value"
              :value="true"
              :unchecked-value="false"
              :disabled="!editMode"
            ></b-form-checkbox>
            <md-chips
              v-else-if="data.item.add_info_desc == 'Major Clients'"
              @md-insert="upperCaseClients(majorClients)"
              class="md-primary shake-on-error"
              v-model="majorClients"
              title="New value separated by enter"
              md-placeholder="New value separated by enter"
              md-check-duplicated
              :md-static="!editMode"
            >
            </md-chips>
            <md-chips
              v-else-if="data.item.add_info_desc == 'Product Portfolio'"
              @md-insert="upperCaseProduct(productPortfolio)"
              class="md-primary shake-on-error"
              v-model="productPortfolio"
              title="New value separated by enter"
              md-placeholder="New value separated by enter"
              md-check-duplicated
              :md-static="!editMode"
            ></md-chips>
            <md-chips
              v-else-if="data.item.add_info_desc == 'Name of Directors'"
              @md-insert="upperCaseDirector(directorsName)"
              class="md-primary shake-on-error"
              v-model="directorsName"
              title="New value separated by enter"
              md-placeholder="New value separated by enter"
              md-check-duplicated
              :md-static="!editMode"
            ></md-chips>
            <date-picker
              v-else-if="data.item.add_info_desc == 'Incorporation Date'"
              v-model="data.item.add_info_value"
              format="DD-MMM-YYYY"
              valueType="format"
              style="max-width:60%;"
              :disabled="!editMode"
              @change="validateDate()"
              :disabled-date="disabledDates"
            ></date-picker>
            <b-form-input
              v-else-if="data.item.add_info_desc == 'Company Type'"
              v-model="data.item.add_info_value"
              :title="data.item.add_info_value"
              readonly
              @click="openValueSetModal('PARTY_REQ_COMPANY_TYPE', data.index)"
            ></b-form-input>
            <b-form-input
              v-else
              v-model="data.item.add_info_value"
              v-numericOnly
              @blur="validateYear(data.item)"
              :disabled="!editMode || data.item.document_det_id > 0"
            ></b-form-input>
          </template>

          <template #cell(upload_document)="data">
            <md-field
              v-if="
                data.item.gl_calendar_dtl_id &&
                  data.item.add_info_value &&
                  data.item.add_info_value != '0' &&
                  data.item.add_info_id &&
                  !data.item.document_det_id
              "
              md-clearable
            >
              <md-file
                v-model="data.item.upload_document"
                @md-change="selectFile"
              />
            </md-field>

            <a
              v-if="data.item.document_det_id"
              style="cursor:pointer;"
              @click="
                getOpenKmAuthenticate(
                  data.item.add_info_id,
                  data.item.document_det_id
                )
              "
              >{{ data.item.original_file_name }}</a
            >
          </template>
          <template #cell(save_document)="data">
            <a
              style="cursor:pointer;"
              v-if="data.item.upload_document"
              @click="getOpenKmAuthenticate(data.item.add_info_id)"
              >Upload</a
            >
          </template>
          <template
            #cell(delete)="data"
            v-if="approvalStatus === 'DRAFT' || approvalStatus === 'REJECTED'"
          >
            <span
              v-if="data.item.document_det_id"
              class="material-icons"
              style="cursor:pointer;"
              @click="getOpenKmAuthenticateDelete(data.item.document_det_id)"
            >
              delete
            </span>
          </template>
        </b-table>
      </div>
      <valueSetModal
        v-if="showValueSetModal"
        @selectedvalueSet="selectedvalueSet"
        @closeValueSetModal="closeValueSetModal"
      ></valueSetModal>

      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="validateData()"
        v-if="!isAdditionalDetailsSaved"
        >Save</md-button
      >
      <md-button
        style="margin-top:20px; margin-left:15px;"
        class="md-raised md-primary search-btn"
        @click="editModeAdditionalDetails()"
        v-if="!editMode"
        >Edit</md-button
      >
      <span style="color:red; float:right;">
        <b>*Maximum file size 15 MB</b><br />
        <b>*only pdf, jpg, jpeg, png, zip file allowed</b>
      </span>
      <br />
      <span style="color:red; float:right;">
        <b
          >*Please enter 0 for the respective Financial Year if the company is
          not registered before 3 years</b
        >
      </span>
    </md-card-content>
  </b-row>
</template>

<script>
import URL_UTILITY from "../../../utility/url.utility";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  name: "PartyOnboardingAddDetails",
  props: ["additionalRowDetails", "approvalStatus"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isAdditionalDetailsSaved: false,
      additionalData: [
        {
          add_info_id: null,
          add_info_vset: null,
          add_info_value: null,
          // certificate_no: null,
          gl_calendar_dtl_id: null,
        },
      ],
      additionalFields: [
        {
          key: "add_info_desc_meaning",
          label: "Additional Info",
        },
        {
          key: "add_info_value",
          label: "Additional Value",
        },
        {
          key: "upload_document",
        },
        {
          key: "save_document",
        },
        {
          key: "delete",
        },
      ],
      openKmAuth: null,
      fileRef: null,
      menuId: 374,
      submenuId: 83,
      addInfoId: null,
      isDocumentUploaded: false,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      tagValue: [],
      productPortfolio: [],
      majorClients: [],
      directorsName: [],
      showValueSetModal: false,
      specIndex: null,
    };
  },
  mounted() {
    if (this.additionalRowDetails) {
      this.getAdditionalDetailsById();
      this.editMode = false;
      this.isAdditionalDetailsSaved = true;
    } else {
      this.editMode = true;
      this.getDefaultDataAdditionalDetails();
    }
  },
  methods: {
    getOpenKmAuthenticateDelete(docDetailId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (this.openKmAuth) {
              this.deleteDocument(docDetailId, this.openKmAuth);
            }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.getAdditionalDetailsById();
              this.$emit("updatedList");
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
          })
          .catch(() => {});
      }
    },
    getDefaultDataAdditionalDetails() {
      this.loader = true;
      this.$store
        .dispatch("vendor/getDefaultDataAdditionalDetails")
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.additionalData = result;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    upperCaseClients(majorClients) {
      if (majorClients) {
        this.majorClients = majorClients.map((ele) => ele.toUpperCase());
      }
    },
    upperCaseProduct(productPortfolio) {
      if (productPortfolio) {
        this.productPortfolio = productPortfolio.map((ele) =>
          ele.toUpperCase()
        );
      }
    },
    upperCaseDirector(directorsName) {
      if (directorsName) {
        this.directorsName = directorsName.map((ele) => ele.toUpperCase());
      }
    },
    validateData() {
      const directorsLength = this.directorsName.length;
      const clientsLength = this.majorClients.length;
      const productLength = this.productPortfolio.length;
      const isEmpAvail = this.additionalData.filter(
        (elem) =>
          elem.add_info_vset == "EMP_COUNT" &&
          (elem.add_info_value == "" || elem.add_info_value == null)
      );
      const isIncorpDateAvail = this.additionalData.filter(
        (data) =>
          data.add_info_vset == "INC_CORP_DATE" && data.add_info_value == null
      );
      const isCompanyAvail = this.additionalData.filter(
        (ele) =>
          ele.add_info_vset == "COMPANY_TYPE" &&
          (ele.add_info_value == "" || ele.add_info_value == null)
      );
      const isWorkExpAvail = this.additionalData.filter(
        (ele) =>
          ele.add_info_vset == "WORK_EXPERIENCE" &&
          (ele.add_info_value == "" || ele.add_info_value == null)
      );
      const isSimilarExpAvail = this.additionalData.filter(
        (ele) =>
          ele.add_info_vset == "SIMILAR_EXPERIENCE" &&
          (ele.add_info_value == "" || ele.add_info_value == null)
      );
      if (isIncorpDateAvail.length) {
        return alert("Please Enter Incorporation Date");
      } else if (directorsLength < 1) {
        return alert("Please Enter Atleast 1 Director Name");
      } else if (clientsLength < 1) {
        return alert("Please Enter Atleast 1 Major Clients");
      } else if (isEmpAvail.length) {
        return alert("Please Enter No. of Employees");
      } else if (productLength < 1) {
        return alert("Please Enter Atleast 1 Product Portfolio");
      } else if (isCompanyAvail.length) {
        return alert("Please Enter Company Type");
      } else if (isWorkExpAvail.length) {
        return alert("Please Enter Work Experience");
      } else if (isSimilarExpAvail.length) {
        return alert("Please Enter Similar Experience");
      } else {
        this.addEditAdditionalDetails();
      }
    },
    addEditAdditionalDetails() {
      const additionalDetails = this.additionalData.map((elem) => {
        return {
          add_info_id: elem.add_info_id ? elem.add_info_id : 0,
          add_info_vset: elem.add_info_vset,
          add_info_value:
            elem.add_info_vset == "MAJOR_CLIENTS"
              ? this.majorClients.toString()
              : elem.add_info_vset == "PRODUCT_PORTFOLIO"
              ? this.productPortfolio.toString()
              : elem.add_info_vset == "NAME_OF_DIRECTORS"
              ? this.directorsName.toString()
              : elem.add_info_value,
          gl_calendar_dtl_id: elem.gl_calendar_dtl_id,
          // add_info_value:
          //   (elem.add_info_vset == "MSME" ||
          //     elem.add_info_vset == "EINVOICE") &&
          //   elem.add_info_value
          //     ? true
          //     : (elem.add_info_vset == "MSME" ||
          //         elem.add_info_vset == "EINVOICE") &&
          //       !elem.add_info_value
          //     ? false
          //     : elem.add_info_value,
          // certificate_no: elem.certificate_no ? elem.certificate_no : null,
        };
      });
      const filterData = additionalDetails.filter(
        (elem) =>
          elem.gl_calendar_dtl_id &&
          (elem.add_info_value == null || elem.add_info_value == "")
      );
      if (!filterData.length) {
        const payload = {
          party_req_id: this.$store.state.auth.partyId,
          additional_info_party_req_dtls: additionalDetails
            ? additionalDetails
            : null,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/addEditAdditionalDetails", payload)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isAdditionalDetailsSaved = true;
              this.$emit("updatedList");
              this.getAdditionalDetailsById();
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert("Please Enter Last 3 Years Turnover.");
      }
    },
    getAdditionalDetailsById() {
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdditionalDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.additionalData = result.map((elem) => {
              if (elem.add_info_vset == "PRODUCT_PORTFOLIO") {
                this.productPortfolio = elem.add_info_value.split(",");
              } else if (elem.add_info_vset == "MAJOR_CLIENTS") {
                this.majorClients = elem.add_info_value.split(",");
              } else if (elem.add_info_vset == "NAME_OF_DIRECTORS") {
                this.directorsName = elem.add_info_value.split(",");
              }
              return elem;
            });
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    editModeAdditionalDetails() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isAdditionalDetailsSaved = false;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    getOpenKmAuthenticate(add_info_id, docDtlId) {
      this.addInfoId = add_info_id;
      if (docDtlId) {
        this.isDocumentUploaded = true;
      } else {
        this.isDocumentUploaded = false;
      }
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            if (!this.isDocumentUploaded) {
              this.addEditAdditionalDetails();
              this.uploadFile();
            } else {
              window.location.href =
                this.downloadDocUrl +
                "/download/" +
                docDtlId +
                "?token=" +
                this.openKmAuth;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectFile(event) {
      if (event[0].size <= 15728640) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 5 MB.";
      }
    },
    validateDate() {
      this.additionalData.map((elem) => {
        if (elem.gl_calendar_dtl_id) {
          elem.add_info_value = null;
        }
      });
    },
    validateYear(item) {
      const fyEndDate = "31-Mar-" + item.add_info_desc_meaning.slice(23);
      // const fyEndDate = item.add_info_id
      //   ? "31-Mar-" + item.fy_display.split("-")[1]
      //   : null;
      const incorpDate = this.additionalData[1].add_info_value;
      if (item.gl_calendar_dtl_id) {
        if (item.add_info_value && item.add_info_value != 0) {
          if (incorpDate) {
            if (new Date(incorpDate) > new Date(fyEndDate)) {
              alert("Turnover not allowed as per Incorporation Date.");
              item.add_info_value = "0";
            }
          } else {
            alert("Please Enter Incorporation Date");
            item.add_info_value = null;
          }
        }
      }
    },
    disabledDates(date) {
      const restrictedDate = new Date();
      restrictedDate.setHours(0, 0, 0, 0);
      return date > restrictedDate;
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.addInfoId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.isAdditionalDetailsSaved = true;
              this.getAdditionalDetailsById();
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.specIndex = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === "PARTY_REQ_COMPANY_TYPE") {
        this.additionalData[this.specIndex].add_info_value = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
};
</script>
<style scoped>
.mx-datepicker {
  width: 85% !important;
  height: 26px !important;
}
.md-field {
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  margin: 0px 0 0 !important;
  min-height: auto !important;
  width: 80% !important;
}
.shake-on-error /deep/ .md-duplicated {
  animation-name: shake;
  animation-duration: 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(15px);
  }
  20% {
    transform: translate(-15px);
  }
  40% {
    transform: translate(7px);
  }
  60% {
    transform: translate(-7px);
  }
  80% {
    transform: translate(3px);
  }
  100% {
    transform: translate(0px);
  }
}
</style>
