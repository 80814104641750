<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="font">Invoice No.</label>
              <md-input v-model="invNum"> </md-input>
            </md-field>
          </div>
          <div
            style="min-width:12%;"
            class="md-layout-item md-small-size-100 md-size-15"
          >
            <md-field>
              <label for="label">PO Number</label>
              <md-input v-model="poNum"> </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-10">
            <md-checkbox style="margin-top:25px;" v-model="tds"
              >TDS</md-checkbox
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <date-picker
              v-model="invDtFrom"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="From Date"
              @change="invDtTo = null"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <date-picker
              v-model="invDtTo"
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="To Date"
              :disabled-date="disabledDates"
              :disabled="!invDtFrom"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-22">
            <div style="margin-top: 4%">
              <md-button
                class="md-raised md-success search-btn"
                @click="isVendorApproved()"
                style="margin-right: 5%"
                >Search</md-button
              >
              <md-button
                class="md-raised md-info search-btn"
                style="margin-right: 4%"
                @click="clearSearch()"
                >Clear</md-button
              >
              <md-menu style="margin-top:5px;">
                <md-button
                  style="height:28px;"
                  class="md-warning md-just-icon"
                  title="Download Excel"
                  md-menu-trigger
                  ><md-icon>file_download</md-icon>
                </md-button>
                <md-menu-content>
                  <md-menu-item @click="handleOptionClick('invoice_details')"
                    >Invoice Details
                  </md-menu-item>
                  <md-menu-item @click="handleOptionClick('payment_details')"
                    >Payment Details
                  </md-menu-item>
                </md-menu-content>
              </md-menu>
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="vendorInvoiceData"
            :fields="vendorInvoiceField"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 230px)"
            @row-clicked="rowSelected"
          >
            <template #head(select)="data">
              <div>
                <b-form-checkbox
                  v-model="selectAllCheckBox"
                  :value="true"
                  :unchecked-value="false"
                  style="display: inline"
                >
                </b-form-checkbox>
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #cell(select)="data">
              <b-form-checkbox
                v-model="data.item.select"
                :value="true"
                :unchecked-value="false"
              ></b-form-checkbox>
              <!-- @change="triggerApi(data.item)" -->
            </template>
            <template #head(legal_entity)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(inovice_date)="data">
              <div :class="`${sortFlag.invDate ? 'sortedDiv' : 'unsortedDiv'}`">
                <div
                  :class="
                    `${sortFlag.invDate ? 'sortedAmount' : 'unsortedAmount'}`
                  "
                >
                  <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
                </div>
                <span class="ml-2" @click="sortFunc('Invoice Date Low to High')"
                  ><b-icon
                    v-if="!arrowDate"
                    icon="arrow-up-right-square-fill"
                    aria-hidden="true"
                    font-scale="1.5rem"
                  ></b-icon
                ></span>
                <span class="" @click="sortFunc('Invoice Date High to Low')"
                  ><b-icon
                    v-if="arrowDate"
                    icon="arrow-down-right-square-fill"
                    aria-hidden="true"
                    font-scale="1.5rem"
                  ></b-icon
                ></span>
              </div>
            </template>
            <template #head(Invoice_type)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(po_number)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(invoice_num)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(invoice_amount)="data">
              <div
                :class="
                  `${sortFlag.invAmt ? 'sortedDivAmt' : 'unsortedDivAmt'}`
                "
              >
                <div
                  :class="
                    `${
                      sortFlag.invAmt ? 'sortedInvAmount' : 'unsortedInvAmount'
                    }`
                  "
                >
                  <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
                </div>
                <span
                  class="ml-2"
                  @click="sortFunc('Invoice Amount Low to High')"
                  ><b-icon
                    v-if="!arrowAmt"
                    icon="arrow-up-right-square-fill"
                    aria-hidden="true"
                    font-scale="1.5rem"
                  ></b-icon
                ></span>
                <span class="" @click="sortFunc('Invoice Amount High to Low')"
                  ><b-icon
                    v-if="arrowAmt"
                    icon="arrow-down-right-square-fill"
                    aria-hidden="true"
                    font-scale="1.5rem"
                  ></b-icon
                ></span>
              </div>
            </template>
            <template #head(due_date)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #head(first_party_req)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(third_party_req)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #cell(invoice_amount)="data">
              <span class="text-fix-inv">
                {{
                  data.item.invoice_amount !== 0
                    ? formattedCurrency(data.item.invoice_amount)
                    : data.item.invoice_amount
                }}
              </span>
            </template>
            <template #head(amount_paid)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(amount_paid)="data">
              <a
                v-if="data.item.amount_paid !== 0"
                href="#"
                style="color:#007bff; cursor:pointer"
                class="text-fix-inv"
                @click="showHidePaymentDtlsModal(true, data.item)"
                >{{ formattedCurrency(data.item.amount_paid) }}</a
              >
              <span v-else class="text-fix-inv">
                {{ data.item.amount_paid }}
              </span>
            </template>
            <template #head(invoice_currency_code)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #head(tds_amount)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(tds_amount)="data">
              <span class="text-align-tds">
                {{
                  data.item.tds_amount !== 0
                    ? formattedCurrency(data.item.tds_amount)
                    : data.item.tds_amount
                }}</span
              >
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>

          <div class="md-layout pagination-count">
            <div style="width:18%;">
              <div v-if="vendorInvoiceData.length && totalRows <= perPage">
                <p>
                  Showing
                  {{
                    totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1
                  }}
                  to {{ totalRows }} of {{ totalRows }} entries
                </p>
              </div>
              <div v-else-if="vendorInvoiceData.length">
                <p>
                  Showing {{ perPage * (currentPage - 1) + 1 }} to
                  {{
                    perPage * currentPage >= totalRows
                      ? totalRows
                      : perPage * currentPage
                  }}
                  of {{ totalRows }}
                  entries
                </p>
              </div>
            </div>
            <div
              v-if="vendorInvoiceData.length"
              style="margin-top:-0.5%; width:7%"
            >
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="perPage">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div style="color:red; margin-left:50%;">
              <span>Click on the Field Header for more information</span>
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showVendorInvoiceDetailsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Vender Invoice Details"
      @hidden="showHideVendorInvoiceDetailsModal(false, null)"
    >
      <VendorInvoiceDetails :invoiceDetails="invoiceDetails" />
    </b-modal>
    <b-modal
      v-model="showPaymentDtlsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Payment Details"
      @hidden="showHidePaymentDtlsModal(false, null)"
    >
      <VendorInvoicePaymentDetails :invoiceDetails="invoiceDetails" />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import VendorInvoiceDetails from "./VendorInvoiceDetails.vue";
import VendorInvoicePaymentDetails from "./VendorInvoicePaymentDetails.vue";
import DatePicker from "vue2-datepicker";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  components: {
    VendorInvoiceDetails,
    DatePicker,
    HeaderSummary,
    VendorInvoicePaymentDetails,
  },
  name: "VendorInvoice",
  watch: {
    currentPage: function() {
      this.getVendorInvoicesData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorInvoicesData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      loader: false,
      totalRows: null,
      invoiceDetails: null,
      vendorInvoiceData: [],
      invNum: null,
      invDtFrom: null,
      invDtTo: null,
      poNum: "",
      showVendorInvoiceDetailsModal: false,
      showPaymentDtlsModal: false,
      vendorInvoiceField: [
        {
          key: "select",
          class: "stickyColumn col-sm-2",
        },
        {
          key: "legal_entity",
        },
        {
          key: "Invoice_type",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        {
          key: "invoice_num",
          label: "Invoice No.",
        },
        {
          key: "invoice_amount",
          class: "text-fix-inv",
        },
        {
          key: "invoice_currency_code",
          label: "Currency",
          class: "text-align-curr",
        },
        {
          key: "inovice_date",
          label: "Invoice Date",
        },
        // {
        //   key: "due_date",
        // },
        {
             key: 'submission_date'
        },
        {
          key: "amount_paid",
          class: "text-fix-inv",
        },
        {
          key: "tds_amount",
          label: "TDS",
          class: "text-fix-inv",
        },
        {
          key: "first_party_req",
          label: "Company GSTN",
        },
        {
          key: "third_party_req",
          label: "Third Party GSTN",
        },
      ],
      tds: false,
      totalPayload: null,
      menuId: null,
      vendorId: null,
      sortFlag: {
        invAmt: false,
        invDate: true,
      },
      arrowAmt: false,
      arrowDate: false,
      invoiceTypeLookupCode: null,
      paidUnpaidAmount: null,
      customDownload: [],
      selectAllCheckBox: false,
    };
  },
  mounted() {
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    const menuItem = JSON.parse(sessionStorage.getItem("InvoiceDashboard"));
    if (this.vendorId) {
      this.getVendorInvoicesData();
    }
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
  },
  methods: {
    checkUncheckAll() {
      this.vendorInvoiceData = this.vendorInvoiceData.map((data) => {
        data.select = this.selectAllCheckBox;
        return data;
      });
    },
    handleOptionClick(reportType) {
      this.customDownload = [];
      this.vendorInvoiceData.forEach((ele) => {
        if (ele.select) {
          this.customDownload.push({ inv_id: ele.invoice_id });
        }
      });
      if (this.customDownload.length) {
        this.downloadInvoiceReport(reportType);
      } else {
        if (reportType === "invoice_details") {
          if (this.invDtFrom && this.invDtTo) {
            this.validateDateRange(this.invDtFrom, this.invDtTo);
          } else {
            alert("Please Select From Date and To Date");
          }
        } else {
          alert("Please Select Any Invoice");
        }
      }
    },
    validateDateRange(invDateFrom, invDtTo) {
      const fromDate = new Date(invDateFrom);
      const toDate = new Date(invDtTo);
      const differenceInMillis = toDate - fromDate;
      const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);
      if (differenceInDays > 365) {
        alert("Date range cannot greater than 365 Days.");
      } else {
        this.invoiceTypeLookupCode = "X";
        this.paidUnpaidAmount = "-999999999999999999";
        this.getVendorInvoicesData("download");
      }
    },
    downloadInvoiceReport(reportType) {
      const payload = {
        data_type: reportType,
        invoice_ids: this.customDownload,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/downloadInvoiceReport", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result.length) {
              if (reportType == "invoice_details") {
                const customDownloadData = result.map((obj) => ({
                  legal_entity: obj.legal_entity,
                  invoice_type: obj.Invoice_type,
                  invoice_no: obj.invoice_num,
                  invoice_amount: obj.invoice_amount,
                  currency: obj.invoice_currency_code,
                  invoice_date: obj.inovice_date,
                  amount_paid: obj.amount_paid,
                  tds: obj.tds_amount,
                  company_gstn: obj.first_party_req,
                  third_party_gstn: obj.third_party_req,
                  invoice_line_number: obj.line_number,
                  item: obj.item,
                  po_number: obj.po_num,
                  hsn: obj.hsn,
                  basic_amount: obj.amount,
                  gst: obj.gst,
                  rcm: obj.rcm,
                }));
                commonHelper.downloadExcel(
                  customDownloadData,
                  reportType === "invoice_details"
                    ? "vendor-invoice-details"
                    : reportType === "payment_details"
                    ? "vendor-payment-details"
                    : ""
                );
              }
              if (reportType == "payment_details") {
                const customDownloadData = result.map((ele) => ({
                  legal_entity: ele.le_name,
                  invoice_number: ele.invoice_num,
                  invoice_amount: ele.invoice_amount,
                  invoice_date: ele.invoice_date,
                  payment_type: ele.PMT_TYPE,
                  cheque_number: ele.check_id,
                  document_sequence: ele.doc_sequence_value,
                  utr: ele.utr,
                  cheque_amount: ele.check_amount,
                  cheque_date: ele.check_date,
                  amount_paid: ele.amount,
                }));
                commonHelper.downloadExcel(
                  customDownloadData,
                  reportType === "invoice_details"
                    ? "vendor-invoice-details"
                    : reportType === "payment_details"
                    ? "vendor-payment-details"
                    : ""
                );
              }
            } else {
              alert("No Data for Download");
              this.getVendorInvoicesData();
            }
            // this.customDownload.push(...result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = { ...this.totalPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        "vendor/getVendorInvoices",
        "vendor-invoice",
        () => (this.loader = false)
      );
    },
    isVendorApproved() {
      if (this.vendorId) {
        this.invoiceTypeLookupCode = "X";
        this.paidUnpaidAmount = "-999999999999999999";
        this.getVendorInvoicesData();
      } else {
        alert("Vendor is Not Approved");
      }
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.invDtFrom);
    },
    showHidePaymentDtlsModal(flag, item) {
      this.showPaymentDtlsModal = flag;
      this.invoiceDetails = item;
    },
    getVendorInvoicesData(type) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_num: this.invNum,
        invoice_date_from: commonHelper.formattedDate(this.invDtFrom),
        invoice_date_to: commonHelper.formattedDate(this.invDtTo),
        vendor_id: this.vendorId,
        tds: this.tds ? "Y" : "N",
        po_num: this.poNum,
        order_by: this.orderBy ? this.orderBy : "DESC",
        order_name: this.orderName ? this.orderName : "invoice_date",
        invoice_type_lookup_code: this.invoiceTypeLookupCode,
        paid_unpaid_amount: this.paidUnpaidAmount,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoices", payload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page;
          this.vendorInvoiceData = result.map((elem) => {
            elem.inovice_date = commonHelper.formatDate(elem.inovice_date);
            elem.due_date = commonHelper.formatDate(elem.due_date);
            return elem;
          });
          this.totalRows = resp.data.data.data.total_elements;
          if (type === "download") {
            const promt = confirm(
              "Are you sure, you want to download all records.?"
            );
            if (promt) {
              this.downloadCustomExcel();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoices", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.inovice_date = commonHelper.formatDate(elem.inovice_date);
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.legal_entity,
            invoice_type: obj.Invoice_type,
            po_number: obj.po_number,
            invoice_no: obj.invoice_num,
            invoice_amount: obj.invoice_amount,
            currency: obj.invoice_currency_code,
            invoice_date: obj.inovice_date,
            amount_paid: obj.amount_paid,
            tds: obj.tds_amount,
            company_gstn: obj.first_party_req,
            third_party_gstn: obj.third_party_req,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "vendor-invoice");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearch() {
      this.invNum = null;
      this.poNum = "";
      this.tds = false;
      this.invDtFrom = null;
      this.invDtTo = null;
      this.customDownload = [];
      this.getVendorInvoicesData();
    },
    rowSelected(item) {
      this.invoiceDetails = item;
      this.showHideVendorInvoiceDetailsModal(true);
    },
    showHideVendorInvoiceDetailsModal(flag) {
      this.showVendorInvoiceDetailsModal = flag;
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    triggerApi(item) {
      if (item.select) {
        this.customDownload.push({ inv_id: item.invoice_id });
      } else if (!item.select) {
        this.customDownload = this.customDownload.filter(
          (obj) => obj.inv_id !== item.invoice_id
        );
      }
      // if (item.select) {
      //   this.getVendorInvoiceLineDetailsById(item);
      // } else if (!item.select) {
      //   const unSelectedItem = this.vendorInvoiceData.filter(
      //     (elem) => !elem.select
      //   );
      //   this.customDownload = this.customDownload.filter(
      //     (item) =>
      //       !unSelectedItem.some((obj) => obj.po_number == item.po_number)
      //   );
      // }
    },
    getVendorInvoiceLineDetailsById(item) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_id: item.invoice_id,
        org_id: item.org_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorInvoiceLineDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.customDownload.push(...result);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedDownload() {
      if (this.customDownload.length) {
        const customDownloadData = this.customDownload.map((obj) => ({
          legal_entity: obj.legal_entity,
          invoice_type: obj.Invoice_type,
          po_number: obj.po_number,
          invoice_no: obj.invoice_num,
          invoice_amount: obj.invoice_amount,
          currency: obj.invoice_currency_code,
          inovice_date: obj.inovice_date,
          amount_paid: obj.amount_paid,
          tds: obj.tds_amount,
          company_gstn: obj.first_party_req,
          third_party_gstn: obj.third_party_req,
          invoice_line_no: obj.line_number,
          item: obj.item,
          hsn_sac: obj.hsn,
          amount: obj.amount,
          gst: obj.gst,
          rcm: obj.rcm,
        }));
        if (customDownloadData) {
          commonHelper.downloadExcel(
            customDownloadData,
            "Selected-Vendor-Invoice"
          );
        }
      } else {
        const promt = confirm(
          "Are you sure, you want to download all records.?"
        );
        if (promt) {
          this.downloadCustomExcel();
        }
      }
    },
    sortFunc(item) {
      if (item === "Invoice Amount Low to High") {
        this.sortFlag = {
          invAmt: true,
          invDate: false,
        };
        this.arrowAmt = true;
        this.orderBy = "ASC";
        this.orderName = "invoice_amount";
      } else if (item === "Invoice Amount High to Low") {
        this.sortFlag = {
          invAmt: true,
          invDate: false,
        };
        this.arrowAmt = false;
        this.orderBy = "DESC";
        this.orderName = "invoice_amount";
      } else if (item === "Invoice Date Low to High") {
        this.sortFlag = {
          invAmt: false,
          invDate: true,
        };
        this.arrowDate = true;
        this.orderBy = "ASC";
        this.orderName = "invoice_date";
      } else if (item === "Invoice Date High to Low") {
        this.sortFlag = {
          invAmt: false,
          invDate: true,
        };
        this.arrowDate = false;
        this.orderBy = "DESC";
        this.orderName = "invoice_date";
      }
      this.getVendorInvoicesData();
    },
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}
.md-card .md-card-content {
  padding: 5px 0px !important;
}
.search-icon {
  margin: 0 5px !important;
}
.invocie-col > div {
  width: 160px !important;
}
.DateFormate {
  margin-top: 17px !important;
}
.text-fix-inv {
  margin-right: 18% !important;
  text-align: right !important;
}
.text-align-amt {
  text-align: right !important;
  margin-right: 61% !important;
}
.text-align-check-amt {
  text-align: right !important;
  margin-right: 44% !important;
}
.text-align-tds {
  text-align: center !important;
  margin-right: 40% !important;
}
.inv-col-fix > div {
  width: 10rem !important;
}
.sortedInvAmount,
.sortedAmount {
  color: green;
}

.sortedDivAmt {
  color: green;
  margin: auto;
  display: flex;
  margin-left: 25px;
}
.sortedDiv {
  color: green;
  margin: auto;
  display: flex;
}

.unsortedDiv {
  display: flex;
}
.unsortedDivAmt {
  display: flex;
  margin-left: 25px;
}

.sortedDivAmt > div > div,
.unsortedInvAmount > div > div {
  width: 107px;
}
.sortedDiv > div > div,
.unsortedAmount > div > div {
  width: 82px;
}
.text-align-curr {
  text-align: center;
}
.stickyColumn.col-sm-2 {
  max-width: 75px !important;
}
</style>
