<template>
  <md-card-content>
    <b-alert
      :variant="isSuccess ? 'success' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>

    <div class="md-layout">
      <div class="md-layout-item md-size-33">
        <md-field>
          <label class="model-select-label">Address Type</label>
          <model-select
            v-model="addressType"
            :options="addressTypeList"
            :isDisabled="!editMode"
          >
          </model-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Address Name</label>
          <md-input
            type="text"
            v-model="addressName"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Address 1</label>
          <md-input
            type="text"
            v-model="address1"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Address 2</label>
          <md-input
            type="text"
            v-model="address2"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label>Address 3</label>
          <md-input
            type="text"
            v-model="address3"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="model-select-label">Country</label>
          <basic-select
            :selectedOption="country"
            :options="countryList"
            @select="selectedCountry($event)"
            :isDisabled="!editMode"
          >
          </basic-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="model-select-label">State</label>
          <basic-select
            :selectedOption="state"
            :options="stateList"
            @select="selectedState($event)"
            :isDisabled="!editMode"
          >
          </basic-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="model-select-label">City</label>
          <basic-select
            :selectedOption="city"
            :options="cityList"
            @select="selectedCity($event)"
            :isDisabled="!editMode"
          >
          </basic-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="model-select-label">Pincode</label>
          <model-select
            v-model="pincode"
            :options="pincodeList"
            :isDisabled="!editMode"
          >
          </model-select>
        </md-field>
      </div>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-20">
      <b-row>
        <md-button
          class="md-raised md-info search-btn"
          v-if="showNextButton"
          @click="editButton()"
          >{{ !addFlag ? "edit" : "next" }}</md-button
        >
        <md-button
          style="margin-left: 10px"
          class="md-raised md-info search-btn"
          v-if="showUploadButton"
          @click="uploadButton()"
          >Upload</md-button
        >
        <md-button
          style="margin-left: 10px"
          class="md-raised md-info search-btn"
          v-if="showSaveButton && !addFlag"
          @click="saveButton()"
          >Save</md-button
        >
      </b-row>
    </div>
    <b-modal
      v-model="showHistory"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideHistory(false, null)"
    >
      <uploadDocument
        :openKmAuth="openKmAuth"
        @closeOpenKmModal="showHideHistory(false, null)"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :wfHdrId="ppId"
        :selectedInvoiceData="selectedInvoiceData"
        @updateStatusFlag="changeUploadFlag"
      ></uploadDocument>
    </b-modal>
  </md-card-content>
</template>
<script>
import { BasicSelect, ModelSelect } from "vue-search-select";
import store from "../../../../store";
import appStrings from "../../../../utility/string.utility";
import uploadDocument from "../../../uploadDocument.vue";
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  components: {
    BasicSelect,
    ModelSelect,
    uploadDocument,
  },
  watch: {
    addressPpId: function() {
      this.ppId = this.addressPpId;
    },
  },
  name: "addEditAddress",
  props: ["addressDetails", "addressPpId", "recordType"],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      addressType: {
        value: null,
        text: null,
      },
      addressTypeList: [],
      addressName: null,
      addressId: null,
      address1: null,
      address2: null,
      address3: null,
      country: {
        value: null,
        text: null,
      },
      countryList: [],
      state: {
        value: null,
        text: null,
      },
      stateList: [],
      city: {
        value: null,
        text: null,
      },
      cityList: [],
      pincode: {
        text: null,
        value: null,
      },
      pincodeList: [],
      ppAddressId: 0,
      editMode: false,
      isRecordSaved: false,
      addFlag: false,
      openKmAuth: null,
      showHistory: false,
      ppId: null,
      menuId: 326,
      subMenuId: null,
      selectedInvoiceData: null,
      uploadStatusFlag: false,
      showUploadButton: false,
      showSaveButton: false,
      showNextButton: true,
      taxRecordType: null,
    };
  },
  mounted() {
    this.taxRecordType = this.recordType;
    this.getAddressType();
    this.getCountryLov();
    if (this.addressDetails) {
      this.fillAddressDetails(this.addressDetails.item);
    } else {
      this.editMode = true;
      this.addFlag = true;
    }
    this.getCountryState(this.country.value);
    this.getStateCity(this.state.value);
    this.getCityPincode(this.state.value);
    this.getSubMenuById();
  },
  methods: {
    fillAddressDetails(item) {
      this.addressType = {
        value: item.address_type_lookup,
        text: item.address_type,
      };
      this.addressName = item.address_name;
      this.addressId = item.address_id;
      this.ppAddressId =
        this.recordType === "update" ? item.pp_address_id : null;
      this.address1 = item.address_1;
      this.address2 = item.address_2;
      this.address3 = item.address_3;
      this.country = {
        value: item.country_id,
        text: item.country_name,
      };
      this.state = {
        value: item.state_id,
        text: item.state_name,
      };
      this.stateList = [
        {
          value: item.state_id,
          text: item.state_name,
        },
      ];
      this.city = {
        value: item.city_id,
        text: item.city_name,
      };
      this.cityList = [
        {
          value: item.city_id,
          text: item.city_name,
        },
      ];
      this.pincode = {
        value: item.pincode_id,
        text: item.pincode_name,
      };
      this.pincodeList = [
        {
          value: item.pincode_id,
          text: item.pincode_name,
        },
      ];
      this.ppId = item.pp_address_id;
    },
    editButton() {
      if (this.taxRecordType === "exist" || this.taxRecordType === "new") {
        this.isRecordSaved = true;
        this.addEditAddress();
      } else {
        this.editMode = true;
        this.showNextButton = false;
        this.showUploadButton = true;
      }
      // this.isRecordSaved = true;
      // this.addEditAddress();
      // if (this.addressDetails) {
      //   this.getPpIdAddressByDetails();
      // }
    },
    saveButton() {
      this.isRecordSaved = false;
      this.addEditAddress();
    },
    uploadButton() {
      if (this.addressDetails && this.recordType === "exist") {
        this.getPpIdAddressByDetails();
      }
      this.getOpenKmAuthenticate();
    },
    addEditAddress() {
      const payload = {
        pp_address_id: this.ppAddressId,
        party_id: store.state.auth.partyId,
        address_id: this.addressId,
        address_type_lookup: this.addressType.value,
        address_1: this.address1,
        address_2: this.address2,
        address_3: this.address3,
        city_id: this.city.value,
        state_id: this.state.value,
        country_id: this.country.value,
        pincode_id: this.pincode.value,
        address_name: this.addressName,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditAddress", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            if (!this.showSaveButton) {
              this.showUploadButton = true;
            }
            this.showNextButton = false;
            if (this.isRecordSaved) {
              this.editMode = true;
            } else {
              this.editMode = false;
            }
            this.isSuccess = true;
            if (!this.editMode) {
              this.showAlert = true;
            } else {
              this.showAlert = false;
            }
            this.responseMsg = response.data.message;
            // if (!this.ppAddressId) {
            //   this.ppAddressId = this.addressPpId;
            // }
            const uploadEnableFlag = this.showSaveButton ? false : true;
            this.$emit("updatedAddressResponse", uploadEnableFlag);
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getAddressType() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch(
          "party/getLovByLookupType",
          appStrings.LOOKUPTYPE.ADDRESS_TYPE
        )
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.lookup_code,
                text: type.meaning,
              };
            });
            this.addressTypeList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getCountryLov() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getCountryLov")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.country_id,
                text: type.country,
              };
            });
            this.countryList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedCountry(event) {
      this.country = event;
      this.getCountryState(event.value);
    },
    getCountryState(countryId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getCountryState", countryId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.state_id,
                text: type.state,
              };
            });
            this.stateList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedState(event) {
      this.state = event;
      this.getStateCity(event.value);
      this.getCityPincode(event.value);
    },
    getStateCity(stateId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getStateCity", stateId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.city_id,
                text: type.city,
              };
            });
            this.cityList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedCity(event) {
      this.city = event;
    },
    getCityPincode(stateId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getCityPincode", stateId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.pincode_id,
                text: type.pincode,
              };
            });
            this.pincodeList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    selectedPincode(event) {
      this.pincode = event;
    },
    getOpenKmAuthenticate() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.subMenuId,
        record_id: this.ppId,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getPpIdAddressByDetails() {
      const payload = {
        address_id: this.addressDetails.item.address_id,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getPpIdAddressByDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.ppId = response.data.data.pp_id;
            this.$emit("deleteAddressById", this.ppId);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    changeUploadFlag(value) {
      this.uploadStatusFlag = value;
      this.showSaveButton = true;
      this.showUploadButton = false;
      this.$emit("saveButtonCloseModal");
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_address_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
<style></style>
