<template>
  <div class="md-layout">
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 30px !important">summarize</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="filter">Invoice No.</label>
              <md-input v-model="invNum"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate ">
            <!-- <md-datepicker md-immediately v-model="fromDate"
              ><label>Invoice From Date</label></md-datepicker
            > -->
            <date-picker
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="Invoice From Date"
              v-model="fromDate"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <!-- <md-datepicker md-immediately v-model="toDate"
              ><label>Invoice To Date</label></md-datepicker  
            > -->
            <date-picker
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="Invoice To Date"
              v-model="toDate"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-primary search-btn"
                @click="getRecoveryInvoiceData()"
              >
                Search
              </md-button>
              <md-button
                class="md-raised md-rose search-btn"
                style="margin-left: 10px"
                @click="clear()"
              >
                Clear
              </md-button>
              <!-- <md-button
                class="md-raised md-rose search-btn"
                style="margin-left: 10px"
                @click="downloadExcelFile()"
              >
                Download
              </md-button> -->
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
        >
          <div class="card-text">
            <h6 class="title">Vendor Recovery Invoice</h6>
          </div>
        </md-card-header>
        <div class="md-layout-item" style="margin-top: 15px ; width:auto">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            md-height
          >
            <template #cell(download)="data">
              <div>
                <md-button
                  v-if="!data.item.status"
                  class="md-raised search-btn"
                  @click="generateRequestData(data.item)"
                  >Generate
                </md-button>
                <md-button
                  class="md-raised search-btn"
                  v-if="data.item.status && data.item.status !== 'COMPLETED'"
                  @click="getSubmitRequest(data.item)"
                  >Refresh
                </md-button>
 
                <a :href="downloadReport + '/' + invoiceReqId" target="_blank">
                  <md-button
                    v-if="data.item.status === 'COMPLETED'"
                    class="md-raised search-btn"
                  >
                    Download
                  </md-button>
                </a>
              </div>
            </template>
          </b-table>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </md-card>
    </div>
  </div>
</template>
<script>
import commonHelper from "../../../../utility/common.helper.utility";
import URL_UTILITY from "../../../../utility/url.utility";
import DatePicker from "vue2-datepicker";
// import downloadExcel from "../../../utility/downloadExcel";
import XLSX from "xlsx";
export default {
  components: {
    DatePicker
  },
  name: "RecoveryInvoice",
  watch: {
    currentPage: function() {
      this.getRecoveryInvoiceData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getRecoveryInvoiceData();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      gridData: [],
      invNum: null,
      leId: null,
      finYear: null,
      fromDate: null,
      toDate: null,
      unsubscribe: null,
      loader: false,
 
      gridField: [
        {
          key: "party_id",
          label: "Party Id",
        },
        {
          key: "invoice_amount",
          label: "Invoice Amount",
        },
        {
          key: "le_id",
          label: "Le Id",
        },
        {
          key: "vendor_name",
          label: "Vendor Name",
        },
        {
          key: "le_name",
          class: "col-fix",
        },
        {
          key: "description",
          label: "Description",
          class: "col-fix",
        },
        {
          key: "amount",
          label: "Amount",
        },
        {
          key: "sharing_ratio",
          label: "Sharing Ratio",
        },
        {
          key: "ou_name",
          class: "col-fix",
        },
        {
          key: "lease_prj",
          label: "Lease Project",
          class: "col-fix",
        },
        {
          key: "Share_ratio",
          label: "Share Ratio",
        },
        {
          key: "area_sub_unit",
          label: "Area SubUnit",
        },
        {
          key: "invoice_type",
          label: "Invoice Type",
        },
        {
          key: "tds",
          label: "TDS",
        },
        {
          key: "tax",
          label: "Tax",
        },
        {
          key: "paid_amount",
          label: "Paid Amount",
        },
        {
          key: "basic",
          label: "Basic",
        },
        {
          key: "invoice_num",
          label: "Invoice Number",
        },
        {
          key: "invoice_date",
          label: "Invoice Date",
        },
        {
          key: "ou_id",
          label: "Ou Id",
        },
        {
          key: "subunit",
          label: "Sub Unit",
        },
        {
          key: "amount_remaining",
          label: "Amount Remaining",
          class: "col-fix",
        },
        {
          key: "status",
        },
        {
          key: "download",
        },
      ],
      downloadReport: URL_UTILITY.getDownloadReportUrl,
      invoiceId: null,
      templateId: null,
      requestId: null,
      requestStatus: null,
      invoiceReqId: null,
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
      }
    });
    this.getRecoveryInvoiceData();
    this.getInvoiceRequestId();
  },
  methods: {
    getRecoveryInvoiceData(i, data) {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leId !== "All" ? this.leId : null,
        invoice_no: this.invNum ? this.invNum : null,
        invoice_date_from: commonHelper.formattedDate(this.fromDate),
        invoice_date_to: commonHelper.formattedDate(this.toDate),
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getRecoveryInvoiceData", this.payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page;
            this.gridData = result.map((data) => {
              data.status = "";
              return data;
            });
            this.invoiceId = resp.data.data[0].id;
            this.totalRows = resp.data.data.total_elements;
            this.gridData[i].status = data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       *
       */
 
      const downloadpayload = { ...this.payload };
      // downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        "vendor/getRecoveryInvoiceData",
        "RecoveryInvoice",
        () => (this.loader = false)
      );
    },
    getInvoiceRequestId() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getInvoiceRequestId")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.templateId = resp.data.data.template_id;
            this.requestId = resp.data.data.request_id;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    generateRequestData(item) {
      const payload = {
        request_num: 0,
        request_id: this.requestId,
        template_id: this.templateId,
        schedule_type: "asap", //pass hardcode
        request_start_date: null,
        request_end_date: null,
        resubmit_interval: null,
        resubmit_interval_unit: null,
        day_of_month: null,
        day_of_week: null,
        output_format_id: "PDF", //pass hardcode
        no_of_args: 25,
        request_parameter:
          item.le_id +
          "," +
          item.ou_id +
          "," +
          item.invoice_date +
          "," +
          item.invoice_date +
          "," +
          item.invoice_num +
          "," +
          null +
          "," +
          null +
          "," +
          null,
        sms_flag: false,
        email_flag: false,
        whatsapp_flag: false,
        child_req_count: null,
        email_comm_template_id: null,
        sms_comm_template_id: null,
        whatsapp_comm_template_id: null,
        argument1: item.le_id,
        argument2: item.ou_id,
        argument3: item.invoice_date,
        argument4: item.invoice_date,
        argument5: item.invoice_num,
        argument6: null,
        argument7: null,
        argument8: null,
        argument9: null,
        argument10: null,
        argument11: null,
        argument12: null,
        argument13: null,
        argument14: null,
        argument15: null,
        argument16: null,
        argument17: null,
        argument18: null,
        argument19: null,
        argument20: null,
        argument21: null,
        argument22: null,
        argument23: null,
        argument24: null,
        argument25: null,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/generateCustomerData", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 201) {
            this.invoiceReqId = resp.data.data[0].id;
            item.status = resp.data.data[0].status;
          }
        });
    },
    getSubmitRequest(item) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getSubmitRequest", this.invoiceReqId)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            item.status = resp.data.data[0].status;
            this.generateRequest = resp.data.data;
            this.requestStatus = resp.data.data[0].status;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
 
    clear() {
      this.fromDate = null;
      this.toDate = null;
      this.invNum = null;
      this.gridData = null;
    },
    beforeDestroy() {
      this.unsubscribe();
    },
  },
};
</script>
 
<style>
.col-fix > div {
  width: 220px !important;
}
</style>