<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout">
            <md-card-header
              class="md-card-header-text md-card-header-green search-icon"
            >
              <div class="card-icon vendor-icon">
                <md-icon style="font-size: 20px !important">search</md-icon>
              </div>
            </md-card-header>
            <div class="md-layout-item md-small-size-100 md-size-11">
              <md-field>
                <label>Invoice Number</label>
                <md-input type="text" v-model="invNum"></md-input>
              </md-field>
            </div>
            <div
              style="margin-left:-1.5%"
              class="md-layout-item md-small-size-100 md-size-11"
            >
              <md-field>
                <label>Open Advance</label>
                <md-select v-model="openAdvance">
                  <md-option value="OPEN">Open</md-option>
                  <md-option value="ALL">All</md-option>
                </md-select>
              </md-field>
            </div>
            <div
              style="margin-left:-1.5%"
              class="md-layout-item md-small-size-100 md-size-11"
            >
              <md-field>
                <label>Amount From</label>
                <md-input
                  v-numericOnly
                  type="text"
                  v-model="amountFrom"
                ></md-input>
              </md-field>
            </div>
            <div
              style="margin-left:-1.5%"
              class="md-layout-item md-small-size-100 md-size-11"
            >
              <md-field>
                <label>Amount To</label>
                <md-input
                  v-numericOnly
                  type="text"
                  v-model="amountTo"
                ></md-input>
              </md-field>
            </div>
            <div
              style="margin-left:-1.5%"
              class="md-layout-item md-small-size-100 md-size-12 DateFormate"
            >
              <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="From Date"
                v-model="fromDate"
                @change="toDate = null"
              ></date-picker>
            </div>
            <div
              style="margin-left:-1.5%"
              class="md-layout-item md-small-size-100 md-size-12 DateFormate"
            >
              <date-picker
                md-immediately
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="To Date"
                v-model="toDate"
                :disabled-date="disabledDates"
                :disabled="!fromDate"
              ></date-picker>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-20">
              <div style="margin-top: 5%">
                <md-button
                  class="md-raised md-success search-btn"
                  style="margin-right: 3%"
                  @click="getAdvanceReportData()"
                  >Search</md-button
                >
                <md-button
                  class="md-raised md-info search-btn"
                  style="margin-right: 3%"
                  @click="clearSearch()"
                  >Clear</md-button
                >
                <md-button
                  v-if="advanceReportData.length"
                  style="height:28px;"
                  class="md-warning md-just-icon"
                  @click="handleOptionClick()"
                  title="Download Excel"
                  ><md-icon>file_download</md-icon>
                </md-button>
                <!-- @click="downloadCustomExcel()" -->
              </div>
            </div>
          </div>
        </md-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <md-card>
          <div class="md-layout-item">
            <b-table
              striped
              hover
              outlined
              sticky-header
              :items="advanceReportData"
              :fields="advanceReportFields"
              show-empty
              class="bg-white"
              style="max-height:calc(100vh - 230px)"
            >
              <template #head(legal_entity)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(invoice_num)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(invoice_amount)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(amount_adjusted)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(amount_unadjusted)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(invoice_currency_code)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(tds)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #head(invoice_date)="data">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </template>
              <template #cell(invoice_amount)="data">
                <span class="inv-amt">
                  {{
                    data.item.invoice_amount !== 0
                      ? formattedCurrency(data.item.invoice_amount)
                      : data.item.invoice_amount
                  }}</span
                >
              </template>
              <template #head(amount_paid)="data">
                <div style="text-align:center !important;">
                  <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
                </div>
              </template>
              <template #cell(amount_paid)="data">
                <span class="amt-paid">
                  {{
                    data.item.amount_paid !== 0
                      ? formattedCurrency(data.item.amount_paid)
                      : data.item.amount_paid
                  }}</span
                >
              </template>
              <template #cell(amount_adjusted)="data">
                <a
                  v-if="data.item.amount_adjusted !== 0"
                  href="#"
                  style="color:#007bff; cursor:pointer"
                  class="amt-adj"
                  @click="showHideAdvAdjDtlsModal(true, data.item)"
                  >{{ formattedCurrency(data.item.amount_adjusted) }}</a
                >
                <span v-else class="amt-adj">
                  {{ data.item.amount_adjusted }}
                </span>
              </template>
              <template #cell(amount_unadjusted)="data">
                <span class="text-fix-adv-un">
                  {{
                    data.item.amount_unadjusted !== 0
                      ? formattedCurrency(data.item.amount_unadjusted)
                      : data.item.amount_unadjusted
                  }}</span
                >
              </template>
              <template #head(select)="data">
                <div>
                  <b-form-checkbox
                    v-model="selectAllCheckBox"
                    :value="true"
                    :unchecked-value="false"
                    style="display: inline"
                  >
                  </b-form-checkbox>
                  <span>{{ data.label }}</span>
                </div>
              </template>
              <template #cell(select)="data">
                <b-form-checkbox
                  v-model="data.item.select"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>

            <div class="md-layout pagination-count">
              <div style="width:18%;">
                <div v-if="advanceReportData.length && totalRows <= perPage">
                  <p>
                    Showing
                    {{
                      totalRows == 0
                        ? totalRows
                        : perPage * (currentPage - 1) + 1
                    }}
                    to {{ totalRows }} of {{ totalRows }} entries
                  </p>
                </div>
                <div v-else-if="advanceReportData.length">
                  <p>
                    Showing {{ perPage * (currentPage - 1) + 1 }} to
                    {{
                      perPage * currentPage >= totalRows
                        ? totalRows
                        : perPage * currentPage
                    }}
                    of {{ totalRows }}
                    entries
                  </p>
                </div>
              </div>
              <div
                v-if="advanceReportData.length"
                style="margin-top:-0.5%; width:7%"
              >
                <div class="md-layout-item">
                  <md-field>
                    <md-select v-model="perPage">
                      <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                        {{ item }}
                      </md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <div style="color:red; margin-left:50%;">
                <span>Click on the Field Header for more information</span>
              </div>
            </div>
          </div>
        </md-card>
      </div>
    </div>
    <b-modal
      v-model="showAdvAdjDtlsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Advance Adjusted Details"
      @hidden="showHideAdvAdjDtlsModal(false, null)"
    >
      <AdvanceReportAdjustedDetails :advanceDetails="advanceDetails" />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../../src/utility/common.helper.utility";
import DatePicker from "vue2-datepicker";
import AdvanceReportAdjustedDetails from "./AdvanceReportAdjustedDetails.vue";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  components: {
    DatePicker,
    AdvanceReportAdjustedDetails,
    HeaderSummary,
  },
  name: "AdvanceReport",
  watch: {
    currentPage: function() {
      this.getAdvanceReportData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAdvanceReportData();
    },
    selectAllCheckBox: function() {
      this.checkUncheckAll();
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: false,
      advanceReportData: [],
      advanceReportFields: [
        {
          key: "select",
          class: "stickyColumn sticy-col-fix",
        },
        {
          key: "legal_entity",
        },
        {
          key: "invoice_num",
          label: "Adv. Invoice Number",
          class: "adv-width-fix",
        },
        {
          key: "invoice_amount",
          class: "inv-amt inv-amt1",
          label: "Adv. Invoice Amount",
        },
        {
          key: "amount_paid",
          class: "amt-paid",
        },
        {
          key: "amount_adjusted",
          label: "Amt. Adjusted",
          class: "amt-adj",
        },
        {
          key: "amount_unadjusted",
          label: "Amt. Unadjusted",
          class: "text-fix-adv-un",
        },
        {
          key: "invoice_currency_code",
          label: "Currency",
        },
        {
          key: "tds",
          label: "TDS",
        },
        {
          key: "invoice_date",
        },
      ],
      fromDate: null,
      toDate: null,
      invNum: null,
      amountFrom: null,
      amountTo: null,
      vendorId: null,
      totalPayload: null,
      showAdvAdjDtlsModal: false,
      advanceDetails: null,
      menuId: null,
      openAdvance: null,
      customDownload: [],
      selectAllCheckBox: false,
    };
  },
  mounted() {
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    const menuItem = JSON.parse(sessionStorage.getItem("OpenAdvance"));
    if (this.vendorId) {
      this.getAdvanceReportData();
    }
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
  },
  methods: {
    checkUncheckAll() {
      this.advanceReportData = this.advanceReportData.map((data) => {
        data.select = this.selectAllCheckBox;
        return data;
      });
    },
    handleOptionClick() {
      this.customDownload = [];
      this.advanceReportData.forEach((ele) => {
        if (ele.select) {
          this.customDownload.push({ inv_num: ele.invoice_num });
        }
      });
      if (this.customDownload.length) {
        this.downloadAdvanceReport();
      } else {
        const promt = confirm(
          "Are you sure, you want to download all records.?"
        );
        if (promt) {
          this.downloadCustomExcel();
        }
      }
    },
    downloadAdvanceReport() {
      const payload = {
        invoice_nums: this.customDownload,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/downloadAdvanceReport", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result.length) {
              const customDownloadData = result.map((obj) => ({
                legal_entity: obj.LE,
                advance_invoice_number: obj.invoice_num,
                advance_invoice_amount: obj.INVOICE_AMOUNT,
                amount_paid: obj.AMOUNT_PAID,
                amount_adjusted: obj.amount_adjusted,
                amount_unadjusted: obj.amount_unadjusted,
                tds: obj.TDS,
                advance_invoice_date: obj.invoice_date,
                invoice_number: obj.STANDARD_INVOICE_NUMBER,
                invoice_amount: obj.STANDARD_INVOICE_AMOUNT,
                invoice_date: obj.STANDARD_INVOICE_DATE,
                applied_amount: obj.applied_amount,
                currency: obj.invoice_currency_code,
              }));
              commonHelper.downloadExcel(
                customDownloadData,
                "vendor-advance-details"
              );
            } else {
              alert("No Data for Download");
              this.getAdvanceReportData();
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.fromDate);
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
    getAdvanceReportData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        vendor_id: this.vendorId,
        invoice_num: this.invNum,
        invoice_amt_from: this.amountFrom,
        invoice_date_to: this.amountTo,
        invoice_date_from: this.fromDate,
        invoice_date_to: this.toDate,
        open_advance:
          this.openAdvance === "ALL"
            ? 0
            : this.openAdvance === "OPEN"
            ? 0.001
            : null,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceReportDtls", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.advanceReportData = result.map((elem) => {
              elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceReportDtls", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
            elem.invoice_amount =
              elem.invoice_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_amount)
                : elem.invoice_amount;
            elem.amount_paid =
              elem.amount_paid !== 0
                ? commonHelper.formatAmount(elem.amount_paid)
                : elem.amount_paid;
            elem.amount_adjusted =
              elem.amount_adjusted !== 0
                ? commonHelper.formatAmount(elem.amount_adjusted)
                : elem.amount_adjusted;
            elem.amount_unadjusted =
              elem.amount_unadjusted !== 0
                ? commonHelper.formatAmount(elem.amount_unadjusted)
                : elem.amount_unadjusted;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.legal_entity,
            invoice_number: obj.invoice_num,
            invoice_amount: obj.invoice_amount,
            amount_paid: obj.amount_paid,
            amount_adjusted: obj.amount_adjusted,
            amount_unadjusted: obj.amount_unadjusted,
            currency: obj.invoice_currency_code,
            tds: obj.tds,
            invoice_date: obj.invoice_date,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "advance-report");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearch() {
      this.invNum = null;
      this.fromDate = null;
      this.toDate = null;
      this.amountFrom = null;
      this.amountTo = null;
      this.openAdvance = null;
      this.getAdvanceReportData();
    },
    showHideAdvAdjDtlsModal(flag, item) {
      this.showAdvAdjDtlsModal = flag;
      this.advanceDetails = item;
    },
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.search-icon {
  margin: 0 5px !important;
}
.md-card .md-card-content {
  padding: 5px 0px !important;
}
.text-fix-adv {
  margin-right: 39% !important;
  text-align: right !important;
}
.text-fix-adv-un {
  margin-right: 28% !important;
  text-align: right !important;
}
.inv-amt {
  text-align: right !important;
  margin-right: 20% !important;
}
.amt-paid {
  text-align: right !important;
  margin-right: 23% !important;
}
.amt-adj {
  text-align: right !important;
  margin-right: 35% !important;
}
.adv-width-fix > div {
  width: 140px !important;
}
.inv-amt1 > div {
  width: 140px !important;
}
.stickyColumn.sticy-col-fix {
  max-width: 75px !important;
}
</style>
