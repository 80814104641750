import XLSX from "xlsx";
import store from "../store";
import commonHelper from "../utility/common.helper.utility";
const downloadData = (payload, apiUrl, compName, callback) => {
  payload._limit =
    payload._limit < commonHelper.perPageRecord
      ? commonHelper.perPageRecord
      : payload._limit;
  store
    .dispatch(apiUrl, payload)
    .then((response) => {
      if (response.status === 200) {
        const result = response.data.data.data
          ? response.data.data.data
          : response.data.data;
        const dataToDownload = result.page.map((data) => {
          let newData = {};
          for (const key in data) {
            const formatedKeyName = key.replace(/_/g, " ").toUpperCase();
            Object.assign(newData, { [formatedKeyName]: data[key] });
          }
          return newData;
        });
        const data = XLSX.utils.json_to_sheet(dataToDownload);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(
          wb,
          `${compName}-${new Date().toLocaleDateString()}.xlsx`
        );
      }
    })
    .catch(() => {
      alert("something went wrong, download not completed");
    })
    .finally(() => {
      callback();
    });
};

export default {
  downloadData,
};
