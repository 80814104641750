<template>
  <md-card-content>
    <b-alert
      style="width:50%;"
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <div class="md-layout-item">
      <b-table
        style="max-height:400px;"
        striped
        hover
        outlined
        sticky-header
        :items="uploadDocumentData"
        :fields="uploadDocumentFields"
        show-empty
        class="bg-white"
      >
        <template #cell(expiry_date)="data">
          <date-picker
            v-model="data.item.expiry_date"
            format="DD-MMM-YYYY"
            valueType="format"
          ></date-picker>
        </template>

        <template #cell(upload_document)="data">
          <md-field>
            <md-file v-model="data.item.upload_document" />
            <span
              aria-hidden="true"
              class="clearable-icon-grid"
              v-if="data.item.upload_document"
              @click="clearVsetValues('doc', data.index)"
              >×</span
            >
          </md-field>
        </template>
        <template #cell(save_document)="data">
          <a v-if="data.item.upload_document" href="#">Save</a>
        </template>
        <template #cell(view_document)="data">
          <a v-if="data.item.upload_document" href="#">View</a>
        </template>
      </b-table>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-25">
      <md-button
        class="md-raised md-primary search-btn"
        @click="addEditDocumentDetails()"
        >Save</md-button
      >
    </div>
  </md-card-content>
</template>

<script>
import appStrings from "../../../utility/string.utility";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  name: "PartyOnboardingDocument",
  props: ["documentRowDetails"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      uploadDocumentData: [
        {
          doc_type: null,
          upload_document: null,
        },
      ],
      uploadDocumentFields: [
        {
          key: "add",
        },
        {
          key: "remove",
        },
        {
          key: "id",
          label: "Serial No.",
        },
        {
          key: "doc_type",
          label: "Document Type",
        },
        {
          key: "upload_document",
        },
        {
          key: "save_document",
        },
        {
          key: "view_document",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    getComplianceData() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getComplianceData")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.complianceData = resp.data.data;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    addEditComplianceDetails() {
      const complianceDetails = this.complianceData.map((elem) => {
        return {
          compliance_id: elem.compliance_id ? elem.compliance_id : 0,
          iso_name: elem.iso_name,
          is_applicable: elem.is_applicable,
          expiry_date: elem.expiry_date,
        };
      });
      const payload = {
        party_req_id: this.$store.state.auth.partyId,
        party_compliance_req_details: complianceDetails
          ? complianceDetails
          : null,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditComplianceDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.complianceData = response.data.data;
            this.$emit("updatedList");
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === "doc") {
        this.uploadDocumentData[index].upload_document = null;
      }
    },
  },
};
</script>
<style scoped>
.md-field {
  padding-bottom: 0px !important;
  padding-top: 0 !important;
  margin: 0px 0 0 !important;
  min-height: auto !important;
  width: 80% !important;
}
</style>
