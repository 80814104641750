<template>
  <div class="md-layout">
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
      @click="selectedTab('billInfo')"
    >
      <stats-card header-color="rose" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>assignment</md-icon>
          </div>
          <p class="category">Bill Info</p>
          <h4 class="title currency">{{ customerInvoiceCount.bill_info }}</h4>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
      @click="selectedTab('receipt')"
    >
      <stats-card header-color="blue" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>receipt</md-icon>
          </div>
          <p class="category">Receipt</p>
          <h4 class="title currency">{{ customerInvoiceCount.receiptInfo }}</h4>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
      @click="selectedTab('osInfo')"
    >
      <stats-card header-color="green" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>pending_actions</md-icon>
          </div>
          <p class="category">O/S Info</p>
          <h4 class="title currency">{{ customerInvoiceCount.customer_os }}</h4>
        </template>
      </stats-card>
    </div>
    <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-25"
      @click="selectedTab('securityDeposit')"
    >
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>timeline</md-icon>
          </div>
          <p class="category">Security Deposit</p>
          <h4 class="title currency">
            {{ customerInvoiceCount.security_deposit }}
          </h4>
        </template>
      </stats-card>
    </div>
    <!-- <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-20"
      @click="selectedTab('intetestDue')"
    >
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>timeline</md-icon>
          </div>
          <p class="category">Interest Due</p>
          <h4 class="title currency">{{ customerInvoiceCount.interest_due }}</h4>
        </template>
      </stats-card>
    </div> -->
    <!-- <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-20"
      @click="selectedTab('paymentGway')"
    >
      <stats-card header-color="primary" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>payment</md-icon>
          </div>
          <p class="category">Payment Gway</p>
          <h4 class="title currency">{{ customerInvoiceCount.payment_gway }}</h4>
        </template>
      </stats-card>
    </div> -->
    <!-- <div
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-50"
      @click="selectedTab('interestRate')"
    >
      <stats-card header-color="primary" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon>payment</md-icon>
          </div>
          <p class="category" style="margin-right: 26rem;">Total Unbilled</p>
          <h4 class="title currency" style="margin-right: 25rem;">{{ customerInvoiceCount.total_pi_amount }}</h4>
          <p class="category" style="margin-right: 16rem; margin-top: -3rem;">Unbilled Amt</p>
          <h4 class="title currency" style="margin-right: 15rem;">{{ customerInvoiceCount.undefined_pi_bill}}</h4>
          <p class="category" style="margin-top: -3rem; margin-right: 5rem;">Unbilled Interest</p>
          <h4 class="title currency" style="margin-right: 5rem;">{{ customerInvoiceCount.interest_pi_bill}}</h4>
        </template>
      </stats-card>
    </div> -->
    <div class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-50">
      <stats-card header-color="primary" class="pointer">
        <template slot="header">
          <table border="2px solid" style="margin-top: 7px; width:90% ">
            <tr>
              <td
                colspan="2"
                style="padding-right:220px"
              >
                Previous Outstanding
              </td>
            </tr>
 
            <tr>
              <td style="padding-right: 10px;">
                <b> PMC Paid Upto </b>
              </td>
              <td style=" padding-right:180px">
                {{ billingPeriod }}
              </td>
            </tr>
            <tr>
              <td style="padding-right:10px ;">
                <b>Amt (P.M) * </b>
              </td>
              <td style="padding-right: 180px;">
                {{ countBaseAmount }}
              </td>
            </tr>
            <tr>
              <td colspan="2" style="padding-right: 50px;">
                <b>* (Exclusive any tax & Interest for delayed payments) </b>
              </td>
            </tr>
          </table>
        </template>
      </stats-card>
    </div>

    <BillInfo v-if="tabValue === 'billInfo'" />
    <ReceiptInfo v-if="tabValue === 'receipt'" />
    <CustomerOutstandingInfo v-if="tabValue === 'osInfo'" />
    <SecurityDeposit v-if="tabValue === 'securityDeposit'"/>
    <!-- <InterestDue v-if="tabValue === 'interestDue'"/>
    <PaymentGaway v-if="tabValue === 'paymentGway'"/> -->
    <!-- <InterestRate v-if="tabValue === 'interestRate'"/> -->
    <comingSoon v-if="tabValue === 'intetestDue' || tabValue === 'paymentGway'"></comingSoon>
  </div>
</template>

<script>
import {
  StatsCard
} from "@/components";
import BillInfo from "./BillInfo.vue";
import ReceiptInfo from "./ReceiptInfo.vue";
import CustomerOutstandingInfo from "./CustomerOutstandingInfo.vue";
import SecurityDeposit from "./SecurityDeposit.vue";
// import InterestRate from "./InterestRate.vue";
import comingSoon from './../comingSoon.vue';
export default {
  components: {
    StatsCard,
    BillInfo,
    ReceiptInfo,
    CustomerOutstandingInfo,
    SecurityDeposit,
    // InterestRate,
    comingSoon
  },

  data() {
    return {
      leId: null,
      finYear: null,
      customerInvoiceCount: {
        bill_info: 0,
        receiptInfo: 0,
        customer_os: 0,
        payment_gway: 0,
        interest_due: 0,
        security_deposit: 0,
        interest_pi_bill: 0,
        undefined_pi_bill: 0,
        total_pi_amount: 0
      },
      loader: false,
      requestType: null,
      searchModal: true,
      tableData: [
        {
          name: "SDFSDF#2",
        },
        {
          name: "SDFSDF#2",
        },
        {
          name: "SDFSDF#2",
        },
        {
          name: "SDFSDF#2",
        },
      ],
      tabValue: "osInfo",
      product1: process.env.VUE_APP_BASE_URL + "/img/card-2.jpg",
      product2: process.env.VUE_APP_BASE_URL + "/img/card-3.jpg",
      product3: process.env.VUE_APP_BASE_URL + "/img/card-1.jpg",
      seq2: 0,
      selected: [],
      currentFinYear: null,
      billingPeriod: null,
      countBaseAmount: null
    };
  },
  mounted() {
     this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.currentFinYear = sessionStorage.getItem("currentFinYear")
    ? sessionStorage.getItem("currentFinYear")
    : null;
    this.getCustomerCount();
    this.getPmcPaidUptoUrl();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === "shared/setLeId" || mutation.type === "shared/setFinYear") {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getCustomerCount();
        this.getPmcPaidUptoUrl();
      }
    });
  },
  methods: {
    getCustomerCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear && this.finYear !== "All" ? this.finYear : this.currentFinYear
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getCustomerCount", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.customerInvoiceCount = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }, 
    getPmcPaidUptoUrl() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
      };
      this.loader = true;
      this.$store
        .dispatch("dashboard/getPmcPaidUptoUrl", payload)
        .then((response) => {
          this.loader = false;
          this.billingPeriod = null;
          this.countBaseAmount = null;
          if (response.status === 200) {
            this.billingPeriod = response.data.data.billing_period;
            this.countBaseAmount = response.data.data.base_amount;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function (items) {
      this.selected = items;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.card-icon {
  margin-right: 0px !important;
}
.card-text {
  padding: 0px !important;
}
</style>
