<template>
  <div style="background-color: lightgrey; width:100%">
    <md-card>
      <h2 class="text-center"><b>{{ message }}</b></h2>
    </md-card>
  </div>
</template>
<script>
export default {
  name: "ComingSoon",
  data() {
    return {
      message: "Coming Soon",
    };
  },
};
</script>

