<template>
  <md-card-content>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <b-alert
      style="width:50%;"
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <!-- <div>
      <md-switch v-model="boolean"
        ><b style="color:black;"
          >Click here for International Bank</b
        ></md-switch
      >
    </div> -->
    <!-- <div class="md-layout" v-if="boolean"> -->
    <!-- <div class="md-layout-item md-small-size-100 md-size-33 position-rel">
        <md-field md-clearable>
          <label class="mandatory" for="search">Bank Name</label>
          <md-input
            v-model="bankName.text"
            :title="bankName.text"
            readonly
            @click="openValueSetModal('BANK_LIST')"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.bankName.text.$error">
          This field is required
        </div> -->
    <!-- <span
          aria-hidden="true"
          class="clearable-icon"
          v-if="bankName.text && editMode"
          @click="clearVsetValues(bankName.value)"
          >×</span
        > -->
    <!-- </div> -->
    <!-- <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Branch Name</label>
          <md-input
            v-model="branchName"
            :title="branchName"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.branchName.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Account No.</label>
          <md-input
            v-model="accountNo"
            :title="accountNo"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.accountNo.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">IFSC Code</label>
          <md-input
            v-model="ifsc"
            :title="ifsc"
            maxlength="11"
            :disabled="!editMode"
            @input="upperCaseFunction(ifsc)"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.ifsc.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Beneficiary Name</label>
          <md-input
            v-model="beneficiaryName"
            :title="beneficiaryName"
            :disabled="!editMode"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.beneficiaryName.$error">
          This field is required
        </div>
      </div> -->
    <!-- </div> -->
    <div class="md-layout">
      <div
        class="md-layout-item md-small-size-100 md-size-33"
        v-if="isBankValid || isBankSaved"
      >
        <md-field>
          <label class="mandatory" for="search">Bank Name</label>
          <md-input
            v-model="bankCode"
            :title="bankCode"
            :disabled="!editMode"
            @blur="checkEmptySpaces('bank_name')"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.bankCode.$error">
          This field is required
        </div>
      </div>

      <div
        class="md-layout-item md-small-size-100 md-size-33"
        v-if="isBankValid || isBankSaved"
      >
        <md-field>
          <label class="mandatory" for="search">Branch Name</label>
          <md-input
            v-model="branchName"
            :title="branchName"
            :disabled="!editMode"
            @blur="checkEmptySpaces('branch_name')"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.branchName.$error">
          This field is required
        </div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">Account No.</label>
          <md-input
            v-model="accountNo"
            :title="accountNo"
            @input="clearBankDetails()"
            :disabled="!editMode || foreignFlag"
            @blur="checkEmptySpaces('acc_no')"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.accountNo.$error">
          This field is required
        </div>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-field>
          <label class="mandatory" for="search">IFSC Code</label>
          <md-input
            v-model="ifsc"
            :title="ifsc"
            maxlength="11"
            :disabled="!editMode || foreignFlag"
            @input="upperCaseFunction(ifsc)"
            @blur="checkEmptySpaces('ifsc')"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.ifsc.$error">
          This field is required
        </div>
      </div>
      <div
        class="md-layout-item md-small-size-100 md-size-33"
        v-if="isBankValid || isBankSaved"
      >
        <md-field>
          <label class="mandatory" for="search">Beneficiary Name</label>
          <md-input
            v-model="beneficiaryName"
            :title="beneficiaryName"
            :disabled="!editMode"
            @blur="checkEmptySpaces('beneficiary_name')"
          >
          </md-input>
        </md-field>
        <div class="error" v-if="$v.beneficiaryName.$error">
          This field is required
        </div>
      </div>

      <div class="md-layout-item md-small-size-100 md-size-33">
        <md-button
          style="margin-top:20px; margin-left:4rem;"
          class="md-raised md-primary search-btn"
          @click="validateBankDetails()"
          v-if="!isBankValid && !isBankSaved"
          >Validate</md-button
        >
      </div>
      <div>
        <b-form-checkbox
          v-if="
            (bankRowDetails && bankRowDetails.foreign_bank_acc) ||
              !bankRowDetails
          "
          id="foreignBankAccount"
          :disabled="isBankValid"
          v-model="foreignBankAccount"
          name="foreignBankAccount"
          :value="true"
          :unchecked-value="false"
          @input="checkUncheckForeignAccount()"
        >
          Foreign Bank Account
        </b-form-checkbox>
      </div>
      <div>
        <md-button
          style="margin-left:15px;"
          class="md-raised md-primary search-btn"
          @click="addEditVendorBankDetails()"
          v-if="isBankSaved && foreignBankAccount"
          >Save</md-button
        >
      </div>
    </div>
    <div
      class="md-layout"
      v-if="
        isBankSaved && !isDocumentUploaded && approvalStatus !== 'INPROCESS'
      "
    >
      <span style="color:red; margin-top:2rem;">
        <b>*Maximum file size 15 MB</b><br />
        <b>*only pdf, jpg, jpeg, png, zip file allowed</b>
      </span>
    </div>
    <div
      class="md-layout"
      v-if="
        isBankSaved && !isDocumentUploaded && approvalStatus !== 'INPROCESS'
      "
    >
      <div
        v-if="ifsc"
        class="md-layout-item md-small-size-100 md-size-33 position-rel"
      >
        <md-field md-clearable>
          <label>Cancelled Cheque</label>
          <md-file required v-model="single" @md-change="selectFile" />
        </md-field>
      </div>
      <div>
        <md-button
          style="margin-top:20px; margin-left:15px;"
          class="md-raised md-primary search-btn"
          v-if="single"
          @click="getOpenKmAuthenticate()"
          >Upload Document</md-button
        >
      </div>
    </div>
    <div v-if="isDocumentUploaded" class="md-layout">
      <div style="margin-top:2.5rem;" class="md-layout-item md-size-50">
        <b>Document Name: </b>
        <span style="margin-top:1.8rem; cursor:pointer;">
          <a
            style="margin-left:0.5rem;"
            target="_blank"
            :href="
              downloadDocUrl +
                '/download/' +
                docDetailId +
                '?token=' +
                openKmAuth
            "
            ><b>{{ fileName }}</b></a
          >
        </span>
      </div>
      <div
        style="margin-top:2rem;"
        class="md-layout-item md-size-50"
        v-if="approvalStatus === 'DRAFT' || approvalStatus === 'REJECTED'"
      >
        <b style="margin-left:1rem;">Delete Document: </b>
        <span
          class="material-icons"
          style="cursor:pointer; margin-left:2rem;"
          @click="deleteDocument(docDetailId, openKmAuth)"
          >delete</span
        >
      </div>
    </div>
    <md-button
      style="margin-top:30px; margin-left:15px;"
      class="md-raised md-primary search-btn"
      @click="addEditVendorBankDetails()"
      v-if="!isBankSaved && isBankValid"
      >Save</md-button
    >
    <md-button
      style="margin-top:30px; margin-left:15px;"
      class="md-raised md-primary search-btn"
      @click="editModeBank()"
      v-if="!editMode"
      >Edit</md-button
    >
  </md-card-content>
</template>

<script>
import appStrings from "../../../utility/string.utility";
import URL_UTILITY from "../../../utility/url.utility";
import { required } from "vuelidate/lib/validators";
export default {
  name: "PartyOnboardingBank",
  props: ["bankRowDetails", "approvalStatus"],
  data() {
    return {
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      editMode: false,
      isBankSaved: false,
      bankId: 0,
      bankCode: null,
      branchName: null,
      accountNo: null,
      ifsc: null,
      beneficiaryName: null,
      showUploadDocument: false,
      selectedInvoiceData: null,
      single: null,
      isDocumentUploaded: false,
      openKmAuth: null,
      fileRef: null,
      menuId: 374,
      submenuId: 80,
      docDetailId: null,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      fileName: null,
      boolean: false,
      isBankValid: false,
      ifscDetails: null,
      uploadedFileName: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      foreignBankAccount: false,
      userId: null,
      foreignFlag: false,
    };
  },
  validations: {
    bankCode: { required },
    branchName: {
      required,
    },
    accountNo: {
      required,
    },
    ifsc: {
      required,
    },
    beneficiaryName: {
      required,
    },
  },
  watch: {
    showAlert(val) {
      if (val) {
        setTimeout(() => {
          this.showAlert = false;
        }, 2000);
      }
    },
  },
  mounted() {
    this.userId = JSON.parse(sessionStorage.getItem("userId"));
    if (this.bankRowDetails) {
      this.fillRecordForBankDetails(this.bankRowDetails);
      this.editMode = false;
      this.isBankSaved = true;
    } else {
      this.editMode = true;
    }
  },
  methods: {
    deleteDocument(docDetailId, token) {
      const promt = confirm("Are you sure, you want to delete this document?");
      if (promt) {
        const payload = {
          docDetailId: docDetailId,
          token: token,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/deleteOpenKmDoc", payload)
          .then((response) => {
            if (response.status === 200) {
              alert("Document Deleted Successfully");
              this.$emit("updatedList");
              this.getUploadedDoc();
            } else {
              alert(response.response.data.message || "Something went wrong");
            }
            this.loader = false;
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    clearBankDetails() {
      this.isBankValid = false;
      this.bankCode = null;
      this.branch = null;
      this.beneficiaryName = null;
    },
    validateBankDetails() {
      if (this.accountNo && this.ifsc && this.ifsc.length == 11) {
        const payload = {
          id_number: this.accountNo,
          ifsc: this.ifsc,
          ifsc_details: true,
        };
        this.loader = true;
        this.$store
          .dispatch("vendor/validateBankDetails", payload)
          .then((response) => {
            this.loader = false;
            if (response.status === 200) {
              const result = response.data.data;
              if (result === "Invalid Bank Details") {
                this.showAlert = true;
                this.responseMsg = response.data.data;
              } else if (result.message_code == "success") {
                if (this.foreignBankAccount) {
                  this.showAlert = true;
                  this.responseMsg = "Invalid Bank Details";
                } else {
                  this.ifscDetails = result.data.ifsc_details;
                  if (this.ifscDetails.bank) {
                    this.bankCode = this.ifscDetails.bank_name;
                    this.branchName = this.ifscDetails.branch;
                    this.beneficiaryName = result.data.full_name;
                    this.isBankValid = true;
                  } else {
                    this.showAlert = true;
                    this.isSuccess = false;
                    this.responseMsg = "Please Verify Your Details";
                  }
                }
              } else if (
                result === "Error occurred: 500 INTERNAL_SERVER_ERROR"
              ) {
                if (this.foreignBankAccount) {
                  this.isBankSaved = true;
                  this.editMode = true;
                  this.foreignFlag = true;
                } else {
                  this.showAlert = true;
                  this.responseMsg = "Invalid Bank Details";
                }
              }
            } else {
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      } else {
        alert("Please Enter Account No. and Complete IFSC Code");
      }
    },
    getOpenKmAuthenticate() {
      if (!this.bankId) {
        alert("Please save header first");
      } else {
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/getOpenKmAuthenticate")
          .then((response) => {
            this.loader = false;
            if (response.status === 200) {
              this.openKmAuth = response.data.data.token;
              if (!this.isDocumentUploaded) {
                this.uploadFile();
              }
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    selectFile(event) {
      if (event[0].size <= 15728640) {
        this.fileRef = event;
      } else {
        this.fileRef = null;
        this.showAlert = true;
        this.isSuccess = false;
        this.responseMsg = event[0].name + " file should be less than 15 MB.";
      }
    },
    uploadFile() {
      if (this.fileRef) {
        let formData = new FormData();
        formData.append("file", this.fileRef.item(0));
        formData.append("token", this.openKmAuth);
        formData.append("doc_detail_id", 0);
        formData.append("menu_id", this.menuId);
        formData.append("sub_menu_id", this.submenuId);
        formData.append("record_id", this.bankId);
        this.loader = true;
        this.$store
          .dispatch("uploadDocument/uploadFile", formData)
          .then((response) => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.responseMsg = response.data.message;
              this.isDocumentUploaded = true;
              this.docDetailId = response.data.data.doc_detail_id;
              this.fileName = response.data.data.original_file_name;
              this.$emit("updatedList");
            } else {
              this.isSuccess = false;
              this.responseMsg =
                response.data.message || "Something went wrong";
            }
          })
          .catch(() => {
            this.loader = false;
          });
      } else {
        alert("Please select file less than 5 MB");
      }
    },
    upperCaseFunction(ifsc) {
      this.ifsc = ifsc.toUpperCase();
      this.clearBankDetails();
    },
    getUploadedDoc() {
      const payload = {
        menu_id: this.menuId,
        sub_menu_id: this.submenuId,
        record_id: this.bankId,
        user_id: this.userId,
      };
      this.loader = true;
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.page[0];
            if (result) {
              this.fileName = result.original_file_name;
              this.docDetailId = result.doc_detail_id;
              this.isDocumentUploaded = true;
              this.getOpenKmAuthenticate();
            } else {
              this.fileName = null;
              this.docDetailId = null;
              this.isDocumentUploaded = false;
              this.single = null;
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditVendorBankDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        if (
          this.bankCode[0] === " " ||
          this.bankCode[this.bankCode.length - 1] === " " ||
          this.branchName[0] === " " ||
          this.branchName[this.branchName.length - 1] === " " ||
          this.beneficiaryName[0] === " " ||
          this.beneficiaryName[this.beneficiaryName.length - 1] === " " ||
          this.accountNo[0] === " " ||
          this.accountNo[this.accountNo.length - 1] === " " ||
          this.ifsc[0] === " " ||
          this.ifsc[this.ifsc.length - 1] === " "
        ) {
          alert("Please remove unwanted spaces");
        } else {
          const payload = {
            party_bank_req_id: this.bankId,
            party_req_id: this.$store.state.auth.partyId,
            bank_code: this.bankCode,
            branch_name: this.branchName,
            account_num: this.accountNo,
            ifsc: this.ifsc,
            beneficiary_name: this.beneficiaryName,
            foreign_bank_acc: this.foreignBankAccount,
          };
          this.loader = true;
          this.$store
            .dispatch("vendor/addEditVendorBankDetails", payload)
            .then((response) => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                const result = response.data.data;
                this.editMode = false;
                this.isSuccess = true;
                this.responseMsg = response.data.message;
                this.isBankSaved = true;
                this.bankId = result.party_bank_req_id;
                this.bankCode = result.bank_code;
                this.branchName = result.branch_name;
                this.accountNo = result.account_num;
                this.ifsc = result.ifsc;
                this.beneficiaryName = result.beneficiary_name;
                this.$emit("updatedList");
              } else {
                this.isSuccess = false;
                this.responseMsg = response.response.data.message;
              }
            })
            .catch(() => {
              this.loader = false;
              this.showAlert = true;
              this.isSuccess = false;
              this.responseMsg = appStrings.autoFailedMsg;
            });
        }
      }
    },
    editModeBank() {
      if (
        this.approvalStatus === "DRAFT" ||
        this.approvalStatus === "REJECTED"
      ) {
        this.editMode = true;
        this.isBankSaved = false;
        this.isBankValid = true;
      } else {
        alert("Record is in " + this.approvalStatus + " Stage");
      }
    },
    fillRecordForBankDetails(result) {
      this.bankId = result.party_bank_req_id;
      this.bankCode = result.bank_code;
      this.branchName = result.branch_name;
      this.accountNo = result.account_num;
      this.ifsc = result.ifsc;
      this.beneficiaryName = result.beneficiary_name;
      this.foreignBankAccount = result.foreign_bank_acc;
      this.getUploadedDoc();
    },
    getVendorBankDetailsById() {
      const payload = {
        bankId: this.bankRowDetails.party_bank_req_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorBankDetailsById", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data[0];
            this.fillRecordForBankDetails(result);
            this.getUploadedDoc();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkUncheckForeignAccount() {
      if (!this.foreignBankAccount) {
        this.isBankSaved = false;
      }
    },
    checkEmptySpaces(field) {
      if (
        field === "bank_name" &&
        (this.bankCode[0] === " " ||
          this.bankCode[this.bankCode.length - 1] === " ")
      ) {
        alert("Please remove spaces from Bank Name");
      } else if (
        field === "branch_name" &&
        (this.branchName[0] === " " ||
          this.branchName[this.branchName.length - 1] === " ")
      ) {
        alert("Please remove spaces from Branch Name");
      } else if (
        field === "acc_no" &&
        (this.accountNo[0] === " " ||
          this.accountNo[this.accountNo.length - 1] === " ")
      ) {
        alert("Please remove spaces from Account Number");
      } else if (
        field === "ifsc" &&
        (this.ifsc[0] === " " || this.ifsc[this.ifsc.length - 1] === " ")
      ) {
        alert("Please remove spaces from IFSC Code");
      } else if (
        field === "beneficiary_name" &&
        (this.beneficiaryName[0] === " " ||
          this.beneficiaryName[this.beneficiaryName.length - 1] === " ")
      ) {
        alert("Please remove spaces from Beneficiary Name");
      }
    },
  },
};
</script>
