<template>
  <md-card class="md-card-login" :class="{ 'md-card-hidden': cardHidden }">
    <md-card-header :class="getClass(headerColor)">
      <img
        :src="require('@/assets/img/delhivery-logo.png')"
        alt="Vatika Logo"
        class="vatika-logo"
      />
      <slot name="title"></slot>
    </md-card-header>

    <md-card-content style="padding: 5px 10px !important">
      <slot name="description"></slot>
      <slot name="inputs"></slot>
    </md-card-content>

    <md-card-actions>
      <slot name="footer"></slot>
    </md-card-actions>
  </md-card>
</template>

<script>
export default {
  name: "login-card",
  props: {
    headerColor: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cardHidden: true,
    };
  },
  beforeMount() {
    setTimeout(this.showCard, 400);
  },
  methods: {
    showCard: function() {
      this.cardHidden = false;
    },
    getClass: function(headerColor) {
      return "md-card-header-" + headerColor + "";
    },
  },
};
</script>

<style lang="css">
.vatika-logo {
  width: 40% !important;
  margin-right: 0 !important;
  float: none !important;
  border-radius: 0% !important;
}
.md-card-content {
  padding: 6px 10px !important;
}
</style>
