<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Tenant Agreement No.</label>
              <md-input type="text" v-model="tenant_agrmnt_no"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <!-- <md-datepicker md-immediately v-model="trx_date_from"
              ><label>From Date</label></md-datepicker
            > -->
            <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="From Date"
                v-model="trx_date_from"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15 DateFormate">
            <!-- <md-datepicker md-immediately v-model="trx_date_to"
              ><label>To Date</label></md-datepicker
            > -->
            <date-picker
                valueType="format"
                format="DD-MMM-YYYY"
                placeholder="To Date"
                v-model="trx_date_to"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-success search-btn"
                @click="getSecurityDepositDetails()"
                style="margin-right: 10px"
                >Search</md-button
              ><md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-warning"
        >
          <div class="card-text profile-card" style="padding: 5px !important">
            <h6 class="title">Security Deposit</h6>
          </div>
        </md-card-header>
        <md-card-content>
          <div class="md-layout-item">
            <b-table
              striped
              hover
              outlined
              sticky-header
              :items="gridData"
              :fields="gridField"
              show-empty
              class="bg-white"
            >
              <template #cell(le_short_name)="data">
                <span :title="data.item.le_full_name">{{
                  data.item.le_short_name
                }}</span></template
              >
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>
<script>
import commonHelper from "../../../utility/common.helper.utility";
import DatePicker from "vue2-datepicker";
export default {
  components: {
    DatePicker,
  },
  name: "SecurityDeposit",
  watch: {
    currentPage: function() {
      this.getSecurityDepositDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSecurityDepositDetails();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      tenant_agrmnt_no: null,
      trx_date_from: null,
      trx_date_to: null,
      gridData: [],
      gridField: [
        // {
        //   key: "lease_tenant_agreement_no",
        //   label: "Lease Tenant Agmnt. No.",
        //   class: "agmnt-col",
        // },
        {
          key: "Lease_prj_unit_no",
          label: "Lease Prj. Unit No.",
          class: "agmnt-col",
        },
        { key: "lease_security_deposit", class: "agmnt-col" },
        {
          key: "security_deposit_applied",
          class: "agmnt-col"
        },
        { key: "Billable_Area" },
        // { key: "from_date" },
        // { key: "to_date" },
      ],
      leId: null,
      finYear: null,
      unsubscribe: null,
      currentFinYear: null,
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.currentFinYear = sessionStorage.getItem("currentFinYear")
      ? sessionStorage.getItem("currentFinYear")
      : null;
    this.getSecurityDepositDetails();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
        this.getSecurityDepositDetails();
      }
    });
  },
  methods: {
    getSecurityDepositDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        tenant_agreement_no: this.tenant_agrmnt_no,
        date_from: commonHelper.formattedDate(this.trx_date_from),
        date_to: commonHelper.formattedDate(this.trx_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("customer/getSecurityDepositDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
    clearSearch() {
      this.tenant_agrmnt_no = null;
      this.trx_date_from = null;
      this.trx_date_to = null;
      this.getSecurityDepositDetails();
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.agmnt-col > div {
  width: 170px !important;
}
.md-size-14 {
  min-width: 14% !important;
  max-width: 10% !important;
  flex: 0 1 10%;
}
.search-icon {
  margin: 0 5px !important;
}
.search-btn > div {
  padding: 7px 20px !important;
}
</style>
