<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label for="font">Invoice No.</label>
              <md-input v-model="invoice_no"> </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value From</label>
              <md-input type="text" v-model="invoice_amount_from"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Value To</label>
              <md-input type="text" v-model="invoice_amount_to"></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="invoice_date_from"
              ><label>From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="invoice_date_to"
              ><label>To Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-success search-btn"
                style="margin-right: 10px"
                @click="checkInvoiceFuntion()"
                >Search
              </md-button>
              <!-- @click="getVendorInvoiceGridData()" -->
              <md-button
                class="md-raised md-info search-btn"
                @click="clearSearch()"
                >Clear</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      @click="getRejectedInvoice()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="rose" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">perm_identity</md-icon>
          </div>
          <p class="category"><strong>Rejected</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.rejected }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div
      @click="getGstrMissing()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="blue" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">equalizer</md-icon>
          </div>
          <p class="category"><strong>GSTR2A Missing</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.gst_missing }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div
      @click="getGstrMismatch()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="green" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">equalizer</md-icon>
          </div>
          <p class="category"><strong>GSTR2A Mismatch</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.gst_mismatch }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div
      @click="getScheduleToPay()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>Scheduled To Pay</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.schedule_to_pay }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div
      @click="getPendingApprovals()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>Pending Approvals</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.pending_approval }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div
      @click="getNewSubmission()"
      class="md-layout-item md-medium-size-25 md-xsmall-size-100 md-size-16"
    >
      <stats-card header-color="warning" class="pointer">
        <template slot="header">
          <div class="card-icon vendor-icon">
            <md-icon style="font-size: 20px !important">notifications</md-icon>
          </div>
          <p class="category"><strong>New Submission</strong></p>
          <span
            ><strong>{{ vendorInvoiceCount.new }}</strong></span
          >
        </template>
      </stats-card>
    </div>
    <div class="md-layout-item md-size-100">
      <md-card>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-size-100 text-right">
              <md-button
                class="md-raised md-success vendor-btn"
                @click="btnAction('rejectedReport')"
                >Rejected Report</md-button
              >
              <router-link to="/vendor/new-invoice-entry"
                ><md-button class="md-raised md-primary vendor-btn"
                  >New Invoice</md-button
                ></router-link
              >
              <!-- <md-button class="md-raised md-info vendor-btn"
                >Bulk Upload</md-button
              > -->
              <md-button class="md-raised md-warning vendor-btn"
                >Last Month Payment Report</md-button
              >
              <md-button class="md-raised md-danger vendor-btn"
                >New Credit Note</md-button
              >
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            md-height
            @row-clicked="rowSelected"
          >
            <template #cell(le_short_name)="data">
              <span :title="data.item.le_full_name">{{
                data.item.le_short_name
              }}</span></template
            >
            <template #cell(upload)="data">
              <a class="info; pointer" @click="openShowModal(true, data.item)"
                >Upload</a
              >
            </template>
            <template #cell(details)="data">
              <a
                class="info; pointer"
                @click="openDetailsModal(true, data.item.ap_invoice_id)"
                >Details</a
              >
            </template>
            <!-- v-if="data.item.invoice_source==='SOURCE'" -->
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Upload Attachment"
      @hidden="openShowModal(false, null)"
    >
      <PartyUploadInvoice :invoiceDetails="invoiceDetails" />
    </b-modal>
    <b-modal
      v-model="showDetailsModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="Mismatch Details"
      @hidden="openDetailsModal(false)"
    >
      <PartyMismatchDetails :apInvoiceId="apInvoiceId" />
    </b-modal>
  </div>
</template>

<script>
import { StatsCard } from "@/components";
import commonHelper from "../../../../utility/common.helper.utility";
import PartyUploadInvoice from "./PartyUploadInvoice.vue";
import PartyMismatchDetails from "./PartyMismatchDetails.vue";

export default {
  components: {
    StatsCard,
    PartyUploadInvoice,
    PartyMismatchDetails,
  },
  name: "VendorInvoice",
  watch: {
    currentPage: function () {
      this.tabDataSearch();
    },
    perPage: function () {
      this.currentPage = 1;
      this.tabDataSearch();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      invoiceDetails: null,
      apInvoiceId: null,
      vendorInvoiceCount: {
        new: 0,
        rejected: 0,
        schedule_to_pay: 0,
        pending_approval: 0,
        gst_mismatch: 0,
        gst_missing: 0,
      },
      tabName: null,
      gridData: [],
      invoice_no: null,
      leId: null,
      finYear: null,
      invoice_amount_from: null,
      invoice_amount_to: null,
      invoice_date_from: null,
      invoice_date_to: null,
      new: null,
      pending_approval: null,
      rejected: null,
      schedule_to_pay: null,
      gst_mismatch: null,
      gst_missing: null,
      unsubscribe: null,
      showModal: false,
      showDetailsModal: false,
      gridField: [
        {
          key: "upload",
          label: "Attachment",
        },
        {
          key: "le_short_name",
          label: "LE",
        },
        {
          key: "tax_invoice_no",
        },
        {
          key: "tax_invoice_date",
        },
        { key: "invoice_source", label: "Source" },
        {
          key: "invoice_num",
          label: "Invoice No.",
          class: "invocie-col",
        },
        { key: "invoice_date", label: "Date" },
        { key: "currency" },
        {
          key: "gross_amount",
        },
        {
          key: "gst_hold",
          label: "GST Hold",
        },
        {
          key: "amount_paid",
          label: "Paid",
        },
        {
          key: "2a_status",
          label: "2A Status",
        },
        {
          key: "approval_status_meaning",
          label: "Approval ",
        },
      ],
      selected: [],
      btnActionName: "rejectedReport",
      tableData: [],
      tabValue: "viewProfile",
      isNavigatedFromVendorOs: false,
      currentFinYear: null,
    };
  },
  mounted() {
    this.isNavigatedFromVendorOs = this.$store.state.shared.vendorOsFlag;
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.currentFinYear = sessionStorage.getItem("currentFinYear")
      ? sessionStorage.getItem("currentFinYear")
      : null;
    this.invoiceDetails = null;
    this.getVendorDashboardCount();
    this.getVendorDashboardCountGst();
    this.navigationBasedSearch();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;

        this.getVendorDashboardCount();
        this.getVendorDashboardCountGst();
        this.navigationBasedSearch();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.checkInvoiceFuntion();
  },
  methods: {
    checkInvoiceFuntion() {
      if (
        this.$route.query.params &&
        this.$route.query.params === "party-dashboard"
      ) {
        this.getVendorOsDetails();
      } else {
        this.getVendorInvoiceGridData();
      }
    },
    getVendorInvoiceGridData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : null,
      };
      // this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorInvoiceGridData", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
    getVendorDashboardCount() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDashboardCount", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const results = response.data.data;
            this.vendorInvoiceCount.rejected = results.rejected;
            this.vendorInvoiceCount.schedule_to_pay = results.schedule_to_pay;
            this.vendorInvoiceCount.pending_approval = results.pending_approval;
            this.vendorInvoiceCount.new = results.new;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorDashboardCountGst() {
      const payload = {
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDashboardCountGst", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const results = response.data.data;
            this.vendorInvoiceCount.gst_mismatch = results.gst_mismatch;
            this.vendorInvoiceCount.gst_missing = results.gst_missing;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getRejectedInvoice() {
      this.tabName = "reject";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getRejectedInvoice", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getGstrMismatch() {
      this.tabName = "mismatch";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getGstrMismatch", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "details", label: "Mismatch Details" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getGstrMissing() {
      this.tabName = "missing";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getGstrMissing", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getScheduleToPay() {
      this.tabName = "schedule";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getScheduleToPay", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getPendingApprovals() {
      this.tabName = "pending";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getPendingApprovals", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getNewSubmission() {
      this.tabName = "new";
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : this.currentFinYear,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getPendingApprovals", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorOsDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_no: this.invoice_no,
        invoice_amount_from: this.invoice_amount_from,
        invoice_amount_to: this.invoice_amount_to,
        invoice_date_from: commonHelper.formattedDate(this.invoice_date_from),
        invoice_date_to: commonHelper.formattedDate(this.invoice_date_to),
        le_id: this.leId !== "All" ? this.leId : null,
        fin_year: this.finYear !== "All" ? this.finYear : null,
      };
      // this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorOsDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridField[11] = { key: "2a_status", label: "2A Status" };
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
    clearSearch() {
      this.invoice_no = null;
      this.invoice_amount_from = null;
      this.invoice_amount_to = null;
      this.invoice_date_from = null;
      this.invoice_date_to = null;
      if (this.$route.query.params === "party-dashboard") {
        this.getVendorOsDetails();
      } else {
        this.getVendorInvoiceGridData();
      }
      // this.getVendorInvoiceGridData();
    },
    rowSelected(item) {
      this.openShowModal(true, item);
    },
    openShowModal(flag, item) {
      this.invoiceDetails = item;
      this.showModal = flag;
    },
    openDetailsModal(flag, apInvoiceId) {
      this.apInvoiceId = apInvoiceId;
      this.showDetailsModal = flag;
    },
    btnAction(actionName) {
      this.btnActionName = actionName;
    },
    selectedTab(tab) {
      this.tabValue = tab;
    },
    onSelect: function (items) {
      this.selected = items;
    },
    navigationBasedSearch() {
      if (this.isNavigatedFromVendorOs) {
        this.getVendorOsDetails();
      } else {
        this.getVendorInvoiceGridData();
      }
    },
    tabDataSearch() {
      if (this.tabName === "reject") {
        this.getRejectedInvoice();
      } else if (this.tabName === "missing") {
        this.getGstrMissing();
      } else if (this.tabName === "mismatch") {
        this.getGstrMismatch();
      } else if (this.tabName === "schedule") {
        this.getScheduleToPay();
      } else if (this.tabName === "pending") {
        this.getPendingApprovals();
      } else if (this.tabName === "new") {
        this.getNewSubmission();
      } else {
        this.navigationBasedSearch();
      }
    },
  },
  beforeDestroy() {
    this.$store.dispatch("shared/setVendorOsFlag", false);
    this.unsubscribe();
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}
.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}
.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}
.md-card .md-card-content {
  padding: 5px 0px !important;
}
.search-icon {
  margin: 0 5px !important;
}
.invocie-col > div {
  width: 160px !important;
}
</style>
