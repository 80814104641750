<template>
  <div style="background-color: lightgrey">
    <md-card>
      <div class="container">
        <b-alert
          :variant="isSuccess ? 'info' : 'danger'"
          :show="showAlert"
          dismissible
          fade
          @dismissed="showAlert = false"
          >{{ responseMsg }}
        </b-alert>
      </div>
       <div 
        style="margin-left: 70rem"
        v-if="$route.name !== componentName">
        <md-button
          class="md-raised md-success search-btn"
          @click="openContactModal(true)"
          >Add Contact</md-button
        >
        </div>
        <md-card-header
          v-if="$route.name !== componentName"
          class="md-card-header-text md-layout-item md-card-header-green"
          style="margin-top: -29px"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 160px; padding: 3px !important"
          >
            <h5 class="title">Existing Contact</h5>
          </div>
        </md-card-header>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            @row-clicked="rowSelected"
          >
            <template #cell(active)="data">
              <span>{{ data.item.active ? "Yes" : "No" }}</span>
            </template>
            <template #cell(communication)="data">
              <div>
                <md-button
                  class="md-raised search-btn"
                  @click="showComm(data.index, data)"
                  >View</md-button
                >
              </div>
            </template>
            <template #row-details="row">
              <b-card>
                <b-table
                  striped
                  hover
                  outlined
                  :items="row.item.communication_details"
                  :fields="communication_details_fields"
                  show-empty
                  class="bg-white"
                  sticky-header
                >
                  <template #cell(comm_type)="contact">
                    <b-form-input
                      v-model="contact.item.comm_type"
                      :disabled="
                        !existEditMode || $route.name === componentName
                      "
                      @click="
                        openValueSetModal(
                          'CONTACT_TYPE',
                          contact.index,
                          row.index,
                          'exist'
                        )
                      "
                      readonly
                    >
                    </b-form-input>
                  </template>
                  <template #cell(area_code)="contact">
                    <b-form-input
                      v-model="contact.item.area_code"
                      :disabled="
                        !existEditMode || $route.name === componentName
                      "
                    >
                    </b-form-input>
                  </template>
                  <template #cell(country_code)="contact">
                    <b-form-input
                      v-model="contact.item.country_code"
                      :disabled="
                        !existEditMode || $route.name === componentName
                      "
                    >
                    </b-form-input>
                  </template>
                  <template #cell(contact_num)="contact">
                    <b-form-input
                      maxlength="10"
                      v-model="contact.item.contact_num"
                      :disabled="!existEditMode || $route.name === componentName"
                    ></b-form-input>
                  </template>
                  <template #cell(email)="contact">
                    <b-form-input
                      v-model="contact.item.email"
                      :title="contact.item.email"
                      :disabled="
                        !existEditMode || $route.name === componentName
                      "
                    >
                    </b-form-input>
                  </template>
                  <template #cell(add)="">
                    <div @click="addExistingContactRow(row.index)">
                      <md-icon v-if="$route.name !== componentName"
                        >add_circle</md-icon
                      >
                    </div>
                  </template>
                  <template #cell(edit)="">
                    <div @click="editContactRow()">
                      <md-icon v-if="$route.name !== componentName"
                        >edit_document</md-icon
                      >
                    </div>
                  </template>
                  <template #cell(save)="contact">
                    <div
                      @click="
                        addEditExistingCommDetails(row.index, contact.index)
                      "
                    >
                      <md-icon v-if="$route.name !== componentName"
                        >check_circle</md-icon
                      >
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-model="existDtlCurrentPage"
                  :total-rows="existDtlTotalRows"
                  :per-page="existDtlPerPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-card>
            </template>
          </b-table>
          <b-pagination
            v-model="existHdrCurrentPage"
            :total-rows="existHdrTotalRows"
            :per-page="existHdrPerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
        <b-modal
          v-model="showModal"
          no-close-on-esc
          no-close-on-backdrop
          hide-footer
          size="xl"
          title="Add Contact"
          @hidden="openContactModal(false, null)"
        >
          <addContact
            :contactDetails="contactDetails"
            @updatedContactResponse="updatedContactResponse"
          />
        </b-modal>
        <valueSetModal
          v-if="showValueSetModal"
          @selectedvalueSet="selectedvalueSet"
          @closeValueSetModal="closeValueSetModal"
        ></valueSetModal>
    </md-card>
    <md-card v-if="$route.name !== componentName">
      <md-card-header
        class="md-card-header-text md-layout-item md-card-header-blue"
        style="margin-top: 8px"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 160px; padding: 3px !important"
        >
          <h5 class="title">Updated Contact</h5>
        </div>
      </md-card-header>
      <!-- <md-card-content> -->
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="updateData"
            :fields="updateField"
            show-empty
            class="bg-white"
            @row-clicked="rowSelected"
          >
            <template #cell(active)="data">
              <span>{{ data.item.active ? "Yes" : "No" }}</span>
            </template>
            <template #cell(communication)="data">
              <div>
                <md-button
                  class="md-raised search-btn"
                  @click="showUpdatedComm(data.index, data)"
                  >View</md-button
                >
              </div>
            </template>
            <template #row-details="row">
              <b-card>
                <b-table
                  striped
                  hover
                  outlined
                  :items="row.item.communication_details"
                  :fields="communication_details_fields"
                  show-empty
                  class="bg-white"
                  sticky-header
                >
                  <template #cell(comm_type)="contact">
                    <b-form-input
                      v-model="contact.item.comm_type"
                      :disabled="!updateEditMode"
                      @click="
                        openValueSetModal(
                          'CONTACT_TYPE',
                          contact.index,
                          row.index,
                          'update'
                        )
                      "
                      readonly
                    >
                    </b-form-input>
                  </template>
                  <template #cell(area_code)="contact">
                    <b-form-input
                      v-model="contact.item.area_code"
                      :disabled="!updateEditMode"
                    >
                    </b-form-input>
                  </template>
                  <template #cell(country_code)="contact">
                    <b-form-input
                      v-model="contact.item.country_code"
                      :disabled="!updateEditMode"
                    >
                    </b-form-input>
                  </template>
                  <template #cell(contact_num)="contact">
                    <b-form-input
                      maxlength="10"
                      v-model="contact.item.contact_num"
                      :disabled="!updateEditMode"
                    >
                    </b-form-input>
                  </template>
                  <template #cell(email)="contact">
                    <b-form-input
                      v-model="contact.item.email"
                      :title="contact.item.email"
                      :disabled="!updateEditMode"
                    >
                    </b-form-input>
                  </template>
                  <template #cell(add)="">
                    <div @click="addUpdatedContactRow(row.index)">
                      <md-icon>add_circle</md-icon>
                    </div>
                  </template>
                  <template #cell(edit)="">
                    <div @click="editUpdatedContactRow()">
                      <md-icon>edit_document</md-icon>
                    </div>
                  </template>
                  <template #cell(save)="contact">
                    <div
                      @click="
                        addEditUpdatedCommDetails(row.index, contact.index)
                      "
                    >
                      <md-icon>check_circle</md-icon>
                    </div>
                  </template>
                </b-table>
                <b-pagination
                  v-model="updateDtlCurrentPage"
                  :total-rows="updateDtlTotalRows"
                  :per-page="updateDtlPerPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-card>
            </template>
          </b-table>
          <b-pagination
            v-model="updateHdrCurrentPage"
            :total-rows="updateHdrTotalRows"
            :per-page="updateHdrPerPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
        </div>
        <valueSetModal
          v-if="showValueSetModal"
          @selectedvalueSet="selectedvalueSet"
          @closeValueSetModal="closeValueSetModal"
        ></valueSetModal>
      <!-- </md-card-content> -->
    </md-card>
  </div>
</template>
<script>
import addContact from "./AddContact.vue";
import appStrings from "../../../../utility/string.utility";
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  components: {
    addContact,
  },
  watch: {
    existHdrCurrentPage: function () {
      this.getExistPartyContactHdr();
    },
    existHdrPerPage: function () {
      this.existHdrCurrentPage = 1;
      this.getExistPartyContactHdr();
    },
    existDtlCurrentPage: function () {
      this.getExistPartyContactDetails();
    },
    existDtlPerPage: function () {
      this.existDtlCurrentPage = 1;
      this.getExistPartyContactDetails();
    },
    updateHdrCurrentPage: function () {
      this.getUpdatedBank();
    },
    updateHdrPerPage: function () {
      this.updateHdrCurrentPage = 1;
      this.getUpdatedBank();
    },
    updateDtlCurrentPage: function () {
      this.getUpdatePartyContactDetails();
    },
    updateDtlPerPage: function () {
      this.updateDtlCurrentPage = 1;
      this.getUpdatePartyContactDetails();
    },
  },
  data() {
    return {
      existHdrPerPage: commonHelper.perPageRecord,
      existHdrCurrentPage: 1,
      existHdrTotalRows: null,
      existDtlPerPage: commonHelper.perPageRecord,
      existDtlCurrentPage: 1,
      existDtlTotalRows: null,
      updateHdrPerPage: commonHelper.perPageRecord,
      updateHdrCurrentPage: 1,
      updateHdrTotalRows: null,
      updateDtlPerPage: commonHelper.perPageRecord,
      updateDtlCurrentPage: 1,
      updateDtlTotalRows: null,
      componentName: "Party Details",
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      showModal: false,
      contactDetails: null,
      communicationIndex: null,
      contactTypeList: [],
      showValueSetModal: false,
      vsetCode: null,
      commIndex: null,
      commDetailIndex: null,
      gridData: [
        {
          communication: null,
          salutation: null,
          salutation_lookup: null,
          first_name: null,
          middle_name: null,
          last_name: null,
          contact_purpose: null,
          contact_purpose_vset: null,
          start_date: null,
          end_date: null,
          active: false,
          party_contact_person_id: null,
          pp_party_contact_person_id: null,
          communication_details: [
            {
              contact_type_vset: null,
              comm_type: null,
              area_code: null,
              country_code: null,
              contact_num: null,
              email: null,
              active: null,
              party_contact_comm_id: 0,
              party_contact_person_id: null,
              pp_party_contact_comm_id: null,
            },
          ],
        },
      ],
      gridField: [
        {
          key: "communication",
        },
        {
          key: "salutation",
        },
        {
          key: "first_name",
        },
        {
          key: "middle_name",
        },
        {
          key: "last_name",
        },
        {
          key: "contact_purpose",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
        {
          key: "active",
        },
      ],
      communication_details_fields: [
        {
          key: "comm_type",
          label: "Contact Type",
        },
        {
          key: "area_code",
        },
        {
          key: "country_code",
        },
        {
          key: "contact_num",
          label: "Contact Number",
        },
        {
          key: "email",
        },
        {
          key: "add",
        },
        {
          key: "edit",
        },
        {
          key: "save",
        },
      ],
      updateData: [
        {
          salutation: null,
          active: false,
          party_contact_person_id: null,
          salutation_lookup: null,
          first_name: null,
          middle_name: null,
          last_name: null,
          contact_purpose_vset: null,
          contact_purpose: null,
          start_date: null,
          end_date: null,
          approval_status: null,
          approval_date: null,
          rejected_reason: null,
          posting_status: null,
          posting_status_meaning: null,
          pp_party_contact_person_id: null,
          communication_details: [
            {
              active: null,
              email: null,
              posting_status: null,
              rejected_reason: null,
              pp_party_contact_person_id: null,
              posting_status_meaning: null,
              approval_status: null,
              approval_date: null,
              contact_type_vset: null,
              pp_party_contact_comm_id: 0,
              party_contact_comm_id: null,
              contact_num: null,
              comm_type: null,
              area_code: null,
              country_code: null,
            },
          ],
        },
      ],
      updateField: [
        {
          key: "communication",
        },
        {
          key: "salutation",
          class: 'salutation-col'
        },
        {
          key: "first_name",
          class: 'firstname-col'
        },
        {
          key: "middle_name",
        },
        {
          key: "last_name",
        },
        {
          key: "contact_purpose",
        },
        {
          key: "start_date",
        },
        {
          key: "end_date",
        },
        {
          key: "active",
          class: 'active-col'
        },
        {
          key: 'approval_status',
          label: 'Status'
        },
        {
          key: 'rejected_reason',
          label: 'Reason'
        }
      ],
      contactDetailIndex: null,
      existEditMode: false,
      updateEditMode: false,
    };
  },
  mounted() {
    this.getExistPartyContactHdr();
    this.getUpdatePartyContactHdr();
    this.contactDetails = null;
  },
  methods: {
    selectContact(index) {
      this.contactDetailIndex = index;
    },
    getExistPartyContactHdr() {
      const payload = {
        _page: this.existHdrCurrentPage - 1,
        _limit: this.existHdrPerPage,
      };
      this.$store
        .dispatch("party/getExistPartyContactHdr", payload)
        .then((resp) => {
          if (resp.status === 200) {
            const results = resp.data.data.page;
            this.gridData = results.map((data) => {
              data.communication_details = null;
              return data;
            });
            this.gridData.forEach((key) => {
              this.getExistPartyContactDetails(key);
            });
            this.existHdrTotalRows = resp.data.data.total_elements;
          }
        });
    },
    getExistPartyContactDetails(item) {
      const payload = {
        _page: this.existDtlCurrentPage - 1,
        _limit: this.existDtlPerPage,
        party_contact_person_id: item.party_contact_person_id,
      };
      this.$store
        .dispatch("party/getExistPartyContactDetails", payload)
        .then((resp) => {
          if (resp.status === 200) {
            item.communication_details = resp.data.data.page;
            this.existDtlTotalRows = resp.data.data.total_elements;
            if (item.communication_details && item.communication_details.length === 0) {
              item.communication_details.push({
                contact_type_vset: null,
                comm_type: null,
                area_code: null,
                country_code: null,
                contact_num: null,
                email: null,
                active: null,
                party_contact_comm_id: 0,
                party_contact_person_id: null,
                pp_party_contact_comm_id: null,
              });
            }
          }
        });
    },
    getUpdatePartyContactHdr() {
      const payload = {
        _page: this.updateHdrCurrentPage - 1,
        _limit: this.updateHdrPerPage,
      };
      this.$store
        .dispatch("party/getUpdatePartyContactHdr", payload)
        .then((resp) => {
          if (resp.status === 200) {
            const results = resp.data.data.page;
            this.updateData = results.map((data) => {
              data.communication_details = null;
              return data;
            });
            this.updateData.forEach((key) => {
              this.getUpdatePartyContactDetails(key);
            });
            this.updateHdrTotalRows = resp.data.data.total_elements;
          }
        });
    },
    getUpdatePartyContactDetails(item) {
      const payload = {
        _page: this.updateDtlCurrentPage - 1,
        _limit: this.updateDtlPerPage,
        pp_party_contact_person_id: item.pp_party_contact_person_id,
      };
      this.$store
        .dispatch("party/getUpdatePartyContactDetails", payload)
        .then((resp) => {
          if (resp.status === 200) {
            item.communication_details = resp.data.data.page;
            this.updateDtlTotalRows = resp.data.data.total_elements;
             if (item.communication_details && item.communication_details.length === 0) {
              item.communication_details.push({
                active: null,
                email: null,
                posting_status: null,
                rejected_reason: null,
                pp_party_contact_person_id: null,
                posting_status_meaning: null,
                approval_status: null,
                approval_date: null,
                contact_type_vset: null,
                pp_party_contact_comm_id: 0,
                party_contact_comm_id: null,
                contact_num: null,
                comm_type: null,
                area_code: null,
                country_code: null,
              });
            }
          }
        });
    },
    rowSelected(item) {
      if(this.componentName !== this.$route.name)
      {
      this.openContactModal(true, item);
      } 
    },
    openContactModal(flag, item) {
      this.contactDetails = item;
      this.showModal = flag;
    },
    updatedContactResponse() {
      this.getUpdatePartyContactHdr();
    },
    openValueSetModal(vsetCode, contactIndex, commIndex, flag) {
      this.vsetCode = vsetCode;
      this.contactIndex = contactIndex;
      this.commIndex = commIndex;
      this.flag = flag;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.CONTACT_TYPE) {
        if (this.flag === "exist") {
          this.gridData[this.commIndex].communication_details[
            this.contactIndex
          ].contact_type_vset = item.value_code;
          this.gridData[this.commIndex].communication_details[
            this.contactIndex
          ].comm_type = item.value_meaning;
        } else if (this.flag === "update") {
          this.updateData[this.commIndex].communication_details[
            this.contactIndex
          ].contact_type_vset = item.value_code;
          this.updateData[this.commIndex].communication_details[
            this.contactIndex
          ].comm_type = item.value_meaning;
        }
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    addEditExistingCommDetails(rowIndex, contactIndex) {
      const communicationData =
        this.gridData[rowIndex].communication_details[contactIndex];
      const commDetails = [
        {
          party_contact_person_id:
            this.gridData[rowIndex].party_contact_person_id,
          pp_party_contact_comm_id: communicationData.pp_party_contact_comm_id,
          party_contact_comm_id: communicationData.party_contact_comm_id,
          contact_type_vset: communicationData.contact_type_vset,
          country_code: communicationData.country_code,
          area_code: communicationData.area_code,
          contact_no: communicationData.contact_num,
          email: communicationData.email,
          active: false,
        },
      ];
      const payload = {
        pp_party_contact_person_id: null,
        contact_comm_details: commDetails ? commDetails : null,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditCommDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getExistPartyContactHdr();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    addEditUpdatedCommDetails(rowIndex, contactIndex) {
      const communicationData =
        this.updateData[rowIndex].communication_details[contactIndex];
      const commDetails = [
        {
          party_contact_person_id:
            this.updateData[rowIndex].party_contact_person_id,
          pp_party_contact_comm_id: communicationData.pp_party_contact_comm_id,
          party_contact_comm_id: communicationData.party_contact_comm_id,
          contact_type_vset: communicationData.contact_type_vset,
          country_code: communicationData.country_code,
          area_code: communicationData.area_code,
          contact_no: communicationData.contact_num,
          email: communicationData.email,
          active: false,
        },
      ];
      const payload = {
        pp_party_contact_person_id:
          this.updateData[rowIndex].pp_party_contact_person_id,
        contact_comm_details: commDetails ? commDetails : null,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditCommDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getUpdatePartyContactHdr();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    showComm(index, item) {
      this.commDetailIndex = index;
      item.toggleDetails();
    },
    addExistingContactRow(index) {
      if (this.existEditMode) {
        this.gridData[index].communication_details.push({
          contact_type_vset: null,
          comm_type: null,
          area_code: null,
          country_code: null,
          email: null,
          party_contact_comm_id: 0,
          pp_party_contact_comm_id: null,
          active: null,
          contact_num: null,
        });
      }
    },
    editContactRow() {
      this.existEditMode = true;
    },
    showUpdatedComm(index, item) {
      this.commDetailIndex = index;
      item.toggleDetails();
    },
    addUpdatedContactRow(index) {
      if (this.updateEditMode) {
        this.updateData[index].communication_details.push({
          active: null,
          email: null,
          posting_status: null,
          rejected_reason: null,
          pp_party_contact_person_id: null,
          posting_status_meaning: null,
          approval_status: null,
          approval_date: null,
          contact_type_vset: null,
          pp_party_contact_comm_id: 0,
          party_contact_comm_id: null,
          contact_num: null,
          comm_type: null,
          area_code: null,
          country_code: null,
        });
      }
    },
    editUpdatedContactRow() {
      this.updateEditMode = true;
    },
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.salutation-col > div {
  width: 80px !important;
}
.firstname-col > div {
  width: 80px !important;
}
.active-col > div {
  width: 60px !important
}
</style>
