<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-175 tab-heading">
      <md-tabs class="md-primary">
        <md-tab
          id="tab-details"
          md-label="Details"
          @click="selectedTab('details')"
        >
          <DetailsComponent v-if="activeTab === 'details'" />
        </md-tab>

        <md-tab
          id="tab-address"
          md-label="Address"
          @click="selectedTab('address')"
        >
          <Address v-if="activeTab === 'address'" />
        </md-tab>

        <md-tab id="tab-bank" md-label="Bank" @click="selectedTab('bank')">
          <Bank v-if="activeTab === 'bank'" />
        </md-tab>
        <md-tab
          id="tab-contact"
          md-label="Contact Details"
          @click="selectedTab('contactDetails')"
        >
          <Contact v-if="activeTab === 'contactDetails'" />
        </md-tab>
        <md-tab
          id="tab-indirect"
          md-label="Indirect Tax"
          @click="selectedTab('indirectTax')"
        >
          <IndirectTax v-if="activeTab === 'indirectTax'" />
        </md-tab>
        <md-tab
          id="tab-direct"
          md-label="Direct Tax"
          @click="selectedTab('directTax')"
        >
          <DirectTax v-if="activeTab === 'directTax'" />
        </md-tab>
        <md-tab
        v-if="$route.name !== componentName"
          id="tab-update-status"
          md-label="Update Status"
          @click="selectedTab('details')"
        >
          <UpdateStatus v-if="activeTab === 'details'" />
        </md-tab>
      </md-tabs>
      <span class="blink" v-if="activeTab === 'details'"><div class="marque-type" >
        <p class="marque">
          Kindly update your KYC details <br/>
          Go to update your info
          
        </p>
      </div>
      </span>
    </div>
  </div>
</template>
<script>

import {
  DetailsComponent,
  Address,
  Bank,
  Contact,
  IndirectTax,
  DirectTax,
  UpdateStatus,
} from "@/pages/Dashboard/Party/PartyInfo";

export default {
  name: "party-details",
  components: {
    // NavTabsCard
    DetailsComponent,
    Address,
    Bank,
    Contact,
    IndirectTax,
    DirectTax,
    UpdateStatus,
  },
  watch:{
     '$route.name': function() {
        this.selectedTab('details');
     }
  },
  data() {
    return {
      activeTab: 'details',
      componentName: 'Party Details', 
    };
  },
  created(){
    this.selectedTab('details');
  },
  methods: {
    selectedTab(tabVal) {
      this.activeTab = tabVal;
    }
  }
};
</script>
<style>
.tab-heading {
  margin-top: 45px !important;
}
.marque {
  color: white;
  margin: 2px;
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
}
.marque-type {
  float: right;
  margin-top: 10px;
  background-color: red;
  width: 20rem;
  text-align: center;
  border-radius: 20px;
}
.blink {
        animation: blink-animation 1s steps(5, start) infinite;
        -webkit-animation: blink-animation 1s steps(5, start) infinite;
      }
      @keyframes blink-animation {
        to {
          visibility: hidden;
        }
      }
      @-webkit-keyframes blink-animation {
        to {
          visibility: hidden;
        }
}
</style>