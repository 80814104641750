import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import alerts from "./modules/alerts-module";
import profile from "./modules/profile-module";
import users from "./modules/users-module";
import party from "./modules/party";
import customer from "./modules/customer";
import shared from "./modules/shared";
import vendor from "./modules/vendor";
import dashboard from "./modules/dashboard";
import valueSet from "./modules/valueSetModal";
import uploadDocument from "./modules/uploadDocument"

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    alerts,
    profile,
    users,
    party,
    customer,
    shared,
    vendor,
    dashboard,
    valueSet,
    uploadDocument
  },
});
