<template>
  <div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <div class="alert alert-danger" style="z-index: 9 !important">
        <strong>Generic Vendor Invoice Entry</strong>
        <router-link to="/vendor/invoice"
          ><button class="pull-right back-btn">Back</button></router-link
        >
      </div>
      <md-card>
        <div style="display: flex">
          <md-card-header
            class="md-card-header-text md-layout-item md-card-header-rose"
            style="flex: 790%"
          >
            <div class="card-text profile-card">
              <h6 class="title">Header Details [HD]</h6>
            </div>
          </md-card-header>
        </div>
        <md-card-content>
          <div class="md-layout">
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Vendor Inv. No.</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Currency</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Gross Amount</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Tax Amount</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Taxable Value</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Bussines Place</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Rax Rate </label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-datepicker md-immediately
                ><label>Invoice Date</label></md-datepicker
              >
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>GST Partner</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Vendor GST No.</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>POS</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Tax Type</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Requester Name</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>LUT No.</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-datepicker md-immediately
                ><label>LUT Expiry Date</label></md-datepicker
              >
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Eway Bill No.</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Reason For Delay</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>TCS Amount</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100 md-size-25">
              <md-field>
                <label>Invoice Prior NDC Reason</label>
                <md-input type="text"></md-input>
              </md-field>
            </div>

            <!-- <div class="md-layout-item md-size-100 text-right">
              <md-button class="md-raised md-success">Submit</md-button>
            </div> -->
          </div>
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      style="margin-top: 35px"
    >
      <md-card>
        <md-card-header class="md-card-header-text md-layout-item">
          <div
            class="card-text profile-card"
            style="background-color: #9c27b0 !important"
          >
            <h6 class="title">Invoice Details (DT)</h6>
          </div>
        </md-card-header>
        <md-card-content>
          <md-table
            :value="table"
            class="paginated-table table-striped table-hover"
          >
            <!-- <md-table-toolbar>
              <md-field>
                <label>Per page</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
            </md-table-toolbar> -->

            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Name" md-sort-by="name">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Email" md-sort-by="email">{{
                item.email
              }}</md-table-cell>
              <md-table-cell md-label="Created At" md-sort-by="created_at">{{
                item.created_at
              }}</md-table-cell>
              <md-table-cell md-label="Actions">
                <md-button
                  class="md-icon-button md-raised md-round md-info"
                  @click="onProFeature"
                  style="margin: 0.2rem"
                >
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button
                  class="md-icon-button md-raised md-round md-danger"
                  @click="onProFeature"
                  style="margin: 0.2rem"
                >
                  <md-icon>delete</md-icon>
                </md-button>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">
                        {{ item }}
                      </div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
          <md-card-actions md-alignment="space-between">
            <div class="">
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
              </p>
            </div>
            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            />
          </md-card-actions>

          <!-- <md-table v-model="tableData" table-header-color="green">
            <md-table-row slot="md-table-row" slot-scope="{ item }">
              <md-table-cell md-label="Description">{{
                item.name
              }}</md-table-cell>
              <md-table-cell md-label="Line Amount">N/A</md-table-cell>
              <md-table-cell md-label="Tax Parameter 2(%)">N/A</md-table-cell>
              <md-table-cell md-label="Tax Amount">N/A</md-table-cell>
              <md-table-cell md-label="Charged Entity">N/A</md-table-cell>
              <md-table-cell md-label="PO Number">N/A</md-table-cell>
              <md-table-cell md-label="PO Line Number">N/A</md-table-cell>
              <md-table-cell md-label="HSN/SAC">N/A</md-table-cell>
            </md-table-row>
          </md-table> -->
        </md-card-content>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      style="margin-top: 35px"
    >
      <md-card>
        <md-card-content style="display: flex; flex-wrap: wrap">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <md-field>
              <label>Please Enter Remarks</label>
              <md-textarea></md-textarea>
            </md-field>
          </div>
          <div
            class="md-layout-item md-small-size-100 md-size-100"
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 20px;
              padding: 0;
            "
          >
            <div class="md-layout-item md-small-size-50 md-size-50">
              <md-field>
                <label>Upload files</label>
                <md-file placeholder="A nice input placeholder" />
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-50 md-size-20">
              <md-button class="md-raised md-success">Submit</md-button>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    pagination: Pagination,
  },
  name: "NewInvoiceEntry",
  computed: {
    sort() {
      if (this.sortation.order === "desc") {
        return `-${this.sortation.field}`;
      }

      return this.sortation.field;
    },

    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  data() {
    return {
      table: [],
      footerTable: [
        "Description",
        "Line Amount",
        "Tax Parameter 2(%)",
        "Charged Entity",
        "PO Number",
        "PO Line Number",
        "HSN/SAC",
      ],

      query: null,

      sortation: {
        field: "created_at",
        order: "asc",
      },

      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 1,
    };
  },
};
</script>

<style>
.alert {
  padding: 10px 10px !important;
}
.back-btn {
  background-color: #10ddca;
  width: 80px;
  height: 30px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.alert-danger > a {
  margin-bottom: 5px !important;
}
</style>