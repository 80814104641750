<template>
  <div class="adminPageBody">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="bgImg"></div>
    <div class="cards md-rose">
      <div class="headerCard md-rose">
        <h1
          style="font-size: 15px; font-weight:500; margin-left:14%; color:white"
        >
          Admin Dashboard
        </h1>
      </div>
      <div class="inputCard">
        <md-field>
          <label style="color:white">Select Vendor</label>
          <md-input
            v-model="vendor.text"
            :title="vendor.text"
            readonly
            @click="openValueSetModal('PARTY_REQ_VENDORS')"
          ></md-input>
        </md-field>
      </div>
    </div>
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
    ></valueSetModal>
  </div>
</template>
<script>
// import appStrings from "../../../utility/string.utility";
export default {
  components: {},
  name: "AdminPage",
  watch: {},
  data() {
    return {
      loader: false,
      vendor: { value: null, text: null },
      showValueSetModal: false,
    };
  },
  mounted() {},
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === "PARTY_REQ_VENDORS") {
        if (item.value_set_dtl_id) {
          this.$store.commit("vendor/setVendorId", item.value_set_dtl_id);
          sessionStorage.setItem(
            "VendorId",
            JSON.stringify(item.value_set_dtl_id)
          );
        }
        sessionStorage.setItem("partyId", item.value_code);
        this.$store.dispatch("setPartyId", item.value_code);
        // sessionStorage.setItem("userId", item.user_id);
        // this.$store.dispatch("setUserId", item.user_id);
        this.$router.push({ name: "Home" });
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
};
</script>
<style>
.cards {
  margin-left: auto;
  margin-right: auto;
  padding: 1rem 3rem;
  width: 300px;
  height: 300px;
  background-color: #fff;
  border-radius: 1em;
  margin-top: 0;
  z-index: 1;
  opacity: 1;
  position: absolute;
  bottom: 200px;
  left: 525px;
}
.inputCard {
  width: 250px;
  margin: auto;
  border-radius: 5px;
  margin-left: -20px;
  margin-top: 100px;
}
.bgImg {
  background-image: url("../../../assets/img/delhivery.jpg");
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  margin: 0;
  padding: 0;
  z-index: -1;
  opacity: 0.9;
  margin-top: -4%;
  overflow-y: hidden;
}
.headerCard {
  width: 200px;
  margin: auto;
  padding: 5px;
  background-color: #ec407a;
  border-radius: 10px;
  font-size: 30px;
  margin-bottom: 20%;
}
</style>
