<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    // this.disableCtrlShiftI();
  },
  methods: {
    disableCtrlShiftI() {
      window.addEventListener("keydown", (event) => {
        if (
          (event.ctrlKey && event.shiftKey && event.key == "I") ||
          (event.ctrlKey && event.shiftKey && event.key == "J") ||
          (event.ctrlKey && event.shiftKey && event.key == "C") ||
          event.key == "F12"
        ) {
          alert("Not Allowed!");
          event.preventDefault();
        }
      });
    },
  },
};
</script>
