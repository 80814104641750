<template>
  <div class="md-layout">
    <div class="overlay" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header class="md-card-header-text md-card-header-green">
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 30px !important">summarize</md-icon>
            </div>
          </md-card-header>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-field>
              <label>Invoice No.</label>
              <md-input
                v-model="invNum"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="fromDate"
              ><label>Invoice From Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <md-datepicker md-immediately v-model="toDate"
              ><label>Invoice To Date</label></md-datepicker
            >
          </div>
          <div class="md-layout-item md-small-size-100 md-size-30">
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-primary search-btn"
                @click="getSecurityDepositData()"
              >
                Search
              </md-button>
              <md-button
                class="md-raised md-rose search-btn"
                style="margin-left: 10px"
                @click="clear()"
              >
                Clear
              </md-button>
            </div>
          </div>
        </div>
      </md-card>
     
    </div> 
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-rose"
        >
          <div class="card-text">
            <h6 class="title">Vendor Security Deposit</h6>
          </div>
        </md-card-header>
        <div class="md-layout-item" style="margin-top: 15px">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            md-height
          >
          </b-table>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </md-card>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  components: {
  },
  name: "",
  watch: {
    currentPage: function() {
      this.getSecurityDepositData();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getSecurityDepositData();
    },
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      gridData: [],
      invNum: null,
      leId: null,
      finYear: null,
      fromDate: null,
      toDate: null,
      unsubscribe: null,
      loader: false,
      vendor: null,
      gridField: [
        {
          key: "invoice_num",
          label: "Invoice No.",
        },
        {
          key: "invoice_date"
        },
        {
          key: "invoice_amount"
        },
      ],
    };
  },
  mounted() {
    this.leId = sessionStorage.getItem("leId")
      ? sessionStorage.getItem("leId")
      : "All";
    this.finYear = sessionStorage.getItem("finYear")
      ? sessionStorage.getItem("finYear")
      : "All";
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (
        mutation.type === "shared/setLeId" ||
        mutation.type === "shared/setFinYear"
      ) {
        this.leId = state.shared.leId;
        this.finYear = state.shared.finYear;
      }
    });
    this.getSecurityDepositData();
  },
  methods: {
    getSecurityDepositData() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leId !== "All" ? this.leId : null,
        invoice_num: this.invNum,
        invoice_date_from: commonHelper.formattedDate(this.fromDate),
        invoice_date_to: commonHelper.formattedDate(this.toDate),
      };
      // this.$store.dispatch("shared/setLoader", true);
      this.loader = true;
      this.$store
        .dispatch("vendor/getSecurityDepositData", payload)
        .then((resp) => {
          // this.$store.dispatch("shared/setLoader", false);
             this.loader = false;
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          // this.$store.dispatch("shared/setLoader", false);
          this.loader = false;
        });
    },
    clear() {
      this.fromDate = null;
      this.toDate = null;
      this.gridData = [];
      this.investorName = null;
      this.securityDepositRaised = null;
      this.gstn = null;
    },
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
<style>
.prj-col > div {
  width: 180px !important;
}
</style>
