<template>
  <b-modal
    v-model="showModal"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    size="xl"
    @hidden="hideModal()"
    title="Value Set"
    ><div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <b-col md="6">
      <b-alert
        :variant="isSuccess ? 'success' : 'danger'"
        :show="showAlert"
        dismissible
        fade
        @dismissed="showAlert = false"
        >{{ responseMsg }}</b-alert
      >
    </b-col>
    <div class="md-layout font-fix">
      <label for="filter">Value Set Name: {{ valueSetName }}</label>
      <!-- <b-form-input
          type="text"
          v-model="searchText"
          placeholder="Search by value set name"
        >
        </b-form-input> -->
      <div class="md-layout-item md-small-size-100 md-size-25">
        <md-field style="margin-top: -5px">
          <label>Search by value</label>
          <md-input type="text" v-model="searchText"></md-input>
        </md-field>
      </div>
      <div class="md-layout">
        <div style="margin-top: -7px">
          <md-button
            class="md-raised md-success search-btn"
            @click="getValueSetList()"
            style="margin-left: 15px"
            >Search</md-button
          ><md-button
            class="md-raised md-info search-btn"
            @click="clearSearch()"
            style="margin-left: 10px"
            >Clear</md-button
          >
        </div>
      </div>
    </div>
    <b-table
      style="margin-top: 10px"
      class="font-fix"
      striped
      hover
      outlined
      :items="valueSetList"
      :fields="valueSetFields"
      show-empty
      sticky-header
      @row-clicked="rowSelected"
    >
      <template #cell(multiselect)="data">
        <b-form-checkbox
          v-model="data.item.multiselect"
          :value="true"
          :unchecked-value="false"
          @change="selectBoxChecked($event, data.index, data.item)"
        >
        </b-form-checkbox>
      </template>
    </b-table>
    <b-row>
      <b-col md="4">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination
      ></b-col>
    </b-row>
  </b-modal>
</template>
<script>
import store from "../../../store";
import appStrings from "../../../utility/string.utility";
export default {
  name: "ValueSetModal",
  props: [
    "parent_value_set_id",
    "tablePrameter",
    "isDependent",
    "catTypeForSpec",
    "mandatoryParamObj",
  ],
  watch: {
    currentPage: function() {
      this.getLOVBySetType(this.valueSetName);
    },
  },
  data() {
    return {
      valueSetName: null,
      multiSelect: false,
      showModal: false,
      loader: false,
      showAlert: false,
      responseMsg: "",
      isSuccess: false,
      searchText: null,
      valueSetList: [],
      valueSetFields: [
        {
          key: "multiselect",
          class: "d-none",
        },
      ],
      multiSelectList: [],
      totalRows: null,
      currentPage: 1,
      perPage: null,
    };
  },
  mounted() {
    this.eventBus.$off("valueSetCode");
    this.eventBus.$on("valueSetCode", (valueObj) => {
      this.valueSetName = valueObj.valueSetName;
      this.multiSelect = valueObj.multiFlag ? valueObj.multiFlag : null;
      if (!this.isParentLOVSelected(this.valueSetName)) {
        this.alertByValuesetType(this.valueSetName);
      } else {
        this.showModal = true;
        this.getLOVBySetType(this.valueSetName);
      }
    });
  },
  methods: {
    getLOVBySetType(valueSetName) {
      this.loader = true;
      const payload = {
        valueSetName: valueSetName,
        dependentVariable: {
          search_key: this.searchText,
          _page: this.currentPage - 1,
        },
      };
      if (
        valueSetName === appStrings.VALUESETTYPE.OU_LIST ||
        valueSetName === appStrings.VALUESETTYPE.NFA_LOCATION ||
        valueSetName === appStrings.VALUESETTYPE.FMS_PROJECT ||
        valueSetName === appStrings.VALUESETTYPE.BILLING_CYCLE ||
        valueSetName === appStrings.VALUESETTYPE.GL_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST
      ) {
        payload.dependentVariable.le_id = this.parent_value_set_id;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT
      ) {
        payload.dependentVariable.ou_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_STATE) {
        payload.dependentVariable.country_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BRANCH_LIST) {
        payload.dependentVariable.bank_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CITY) {
        payload.dependentVariable.state_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_PINCODE) {
        payload.dependentVariable.state_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ERP_SITE_NAME) {
        payload.dependentVariable.vendor_site_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ERP_INVENTORY_NAME) {
        payload.dependentVariable.inv_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.ERP_LOCATION_NAME) {
        payload.dependentVariable.loc_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LOCATION) {
        payload.dependentVariable.inv_org_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.FMS_COMP_TABLE) {
        payload.dependentVariable.fms_comp_group_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_POCKET) {
        payload.dependentVariable.sector_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_PROJECT) {
        payload.dependentVariable.pocket_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        payload.dependentVariable.lease_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        payload.dependentVariable.lease_tower_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST) {
        payload.dependentVariable.lease_floor_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.BILLING_PLAN) {
        payload.dependentVariable.billing_cycle_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_TOWER) {
        payload.dependentVariable.crm_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR) {
        payload.dependentVariable.crm_tower_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_UNIT) {
        payload.dependentVariable.crm_floor_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CRM_SECTOR) {
        payload.dependentVariable.crm_master_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.CRM_POCKET) {
        payload.dependentVariable.crm_sector_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.PRJ_SECTOR) {
        payload.dependentVariable.master_prj_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_POCKET) {
        payload.dependentVariable.sector_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_PROJECT) {
        payload.dependentVariable.pocket_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_TOWER) {
        payload.dependentVariable.prj_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_FLOOR) {
        payload.dependentVariable.tower_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_UNIT) {
        payload.dependentVariable.floor_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (valueSetName === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT) {
        payload.dependentVariable.unit_id = this.parent_value_set_id;
        payload.dependentVariable.user_id = store.state.auth.userId;
      } else if (
        valueSetName === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
      ) {
        payload.dependentVariable.tax_group_id = this.parent_value_set_id;
      } else if (valueSetName === appStrings.VALUESETTYPE.INV_MASTER_ORG) {
        payload.dependentVariable.is_master_org = this.parent_value_set_id;
      } else {
        payload.dependentVariable.user_id = store.state.auth.userId;
        payload.dependentVariable.res_id = store.state.shared.responsibilityId;
        payload.dependentVariable.parent_value_set_id = this.parent_value_set_id;
        payload.dependentVariable.table_name =
          valueSetName === appStrings.VALUESETTYPE.TABLE_COLUMNS
            ? this.tablePrameter
            : null;
        payload.dependentVariable.cat_type =
          valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC
            ? this.catTypeForSpec
            : null;
      }
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((response) => {
          this.loader = false;
          if (response.status === 200) {
            this.valueSetFields = [];
            const result = response.data.data;
            // const firstObj = result.data[0];

            const firstObj =
              result.data && result.data.length > 0
                ? result.data[0]
                : result.page && result.page.length > 0
                ? result.page[0]
                : [];
            if (result.columns) {
              const tabelFields = result.columns.map((column) => {
                return {
                  key: column.name,
                  class: !column.display ? "d-none" : "",
                };
              });
              this.valueSetFields = tabelFields;
              if (this.multiSelect) {
                this.valueSetFields.unshift({
                  key: "multiselect",
                });
              }
              const newResult = result.data.map((data) => {
                data.multiSelect = false;
                return data;
              });
              this.valueSetList = newResult;
              this.totalRows = result.total_elements;
            } else {
              for (let val in firstObj) {
                if (typeof firstObj[val] === "string") {
                  this.valueSetFields.push({ key: val });
                } else {
                  this.valueSetFields.push({ key: val, class: "d-none" });
                }
              }
              this.valueSetList = result.page;
              this.totalRows = result.total_elements;
            }
          }
        })
        .catch((error) => {
          this.loader = false;
          alert(error.message);
        });
    },
    isParentLOVSelected(valueSetName) {
      return ((valueSetName === appStrings.VALUESETTYPE.OU_LIST ||
        valueSetName === appStrings.VALUESETTYPE.GL_PERIOD ||
        valueSetName === appStrings.VALUESETTYPE.GST_FIRST_PARTY_GSTN ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_TOWER ||
        (valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_PROJECT &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_FLOOR ||
        valueSetName === appStrings.VALUESETTYPE.SELECT_CRM_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.CRM_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_PROJECT ||
        valueSetName === appStrings.VALUESETTYPE.CRM_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.NFA_FMS_TOWER ||
        valueSetName === appStrings.VALUESETTYPE.NFA_FMS_FLOOR ||
        valueSetName === appStrings.VALUESETTYPE.NFA_FMS_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.NFA_FMS_SUB_UNIT ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_SECTOR ||
        valueSetName === appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET ||
        valueSetName === appStrings.VALUESETTYPE.UNIT_SUB_CAT ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_TOWER_LIST ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST ||
        valueSetName === appStrings.VALUESETTYPE.LEASE_UNIT_LIST ||
        valueSetName === appStrings.VALUESETTYPE.PRJ_POCKET ||
        valueSetName === appStrings.VALUESETTYPE.INVENTORY_ORG_LIST ||
        valueSetName === appStrings.VALUESETTYPE.NFA_LOCATION ||
        (valueSetName === appStrings.VALUESETTYPE.LOCATION &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.NFA_SUB_CATEGORY ||
        valueSetName === appStrings.VALUESETTYPE.DESIGNATION ||
        (valueSetName === appStrings.VALUESETTYPE.POSITION &&
          this.isDependent) ||
        valueSetName === appStrings.VALUESETTYPE.TABLE_COLUMNS ||
        valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT) &&
        (this.parent_value_set_id === null ||
          this.parent_value_set_id === "" ||
          this.tablePrameter === null ||
          this.tablePrameter === "")) ||
        (valueSetName === appStrings.VALUESETTYPE.WF_CRITERIA_SPEC &&
          (this.catTypeForSpec === null || this.catTypeForSpec === ""))
        ? false
        : true;
    },
    alertByValuesetType(valueSetName) {
      switch (valueSetName) {
        case appStrings.VALUESETTYPE.WF_CRITERIA_SPEC:
          alert(appStrings.DEPENDENTVSETMSG.SUBCATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.WF_CRITERIA_SUB_CAT:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.OU_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.TABLE_COLUMNS:
          alert(appStrings.DEPENDENTVSETMSG.SYSTEMTABLESMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_LOCATION:
          alert(appStrings.DEPENDENTVSETMSG.LEMSG);
          break;
        case appStrings.VALUESETTYPE.NFA_SUB_CATEGORY:
          alert(appStrings.DEPENDENTVSETMSG.CATEGORYMSG);
          break;
        case appStrings.VALUESETTYPE.DESIGNATION:
          alert(appStrings.DEPENDENTVSETMSG.BANDMSG);
          break;
        case appStrings.VALUESETTYPE.POSITION:
          alert(appStrings.DEPENDENTVSETMSG.DESIGNATIONMSG);
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          alert(appStrings.DEPENDENTVSETMSG.TAXGROUPMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.PRJMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.PRJSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.PRJ_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.PRJPOCKETMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_TOWER_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASEPRJMSG);
          break;
        case appStrings.VALUESETTYPE.LEASE_FLOOR_LIST:
          alert(appStrings.DEPENDENTVSETMSG.LEASETWRMSG);
          break;
        case appStrings.VALUESETTYPE.INVENTORY_ORG_LIST:
          alert(appStrings.DEPENDENTVSETMSG.OUMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_SECTOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMMASTERPRJMSG);
          break;
        case appStrings.VALUESETTYPE.CRM_POCKET:
          alert(appStrings.DEPENDENTVSETMSG.CRMSECTORMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_TOWER:
          alert(appStrings.DEPENDENTVSETMSG.CRMPROJECTMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_FLOOR:
          alert(appStrings.DEPENDENTVSETMSG.CRMTOWERMSG);
          break;
        case appStrings.VALUESETTYPE.SELECT_CRM_PROJECT:
          alert(appStrings.DEPENDENTVSETMSG.CRMPOCKETMSG);
          break;

        default:
          alert("Please select Parent LOV");
          break;
      }
    },
    rowSelected(item) {
      if (!this.multiSelect) {
        this.showModal = false;
        this.$emit("selectedvalueSet", item);
      }
    },
    hideModal() {
      this.showModal = false;
      this.$emit("closeValueSetModal");
      if (this.multiSelect) {
        this.$emit("selectedvalueSet", this.multiSelectList);
      }
    },
    getValueSetList() {
      this.getLOVBySetType(this.valueSetName);
    },
    clearSearch() {
      this.searchText = null;
      this.getLOVBySetType(this.valueSetName);
    },
    selectBoxChecked(flag, index, item) {
      if (flag) {
        this.multiSelectList.push(item);
      } else {
        this.multiSelectList.splice(index, 1);
      }
    },
  },
  destroyed() {
    this.eventBus.$off("valueSetCode");
  },
};
</script>
<style>
.font-fix {
  font-size: 14px !important;
}
</style>
