var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:[
    { 'nav-open': _vm.$sidebar.showSidebar },
    { rtl: _vm.$route.meta.rtlActive } ],style:(this.$store.state.auth.userType === 'PARTY'
      ? 'margin-left: 0px'
      : _vm.$route.name == 'Routing'
      ? 'margin-left: 0px'
      : this.$store.state.auth.userType === 'VENDOR'
      ? 'margin-left: 0px'
      : 'margin-left: 0px')},[_c('side-bar',{attrs:{"active-color":_vm.sidebarBackground,"background-image":_vm.sidebarBackgroundImage,"data-background-color":_vm.sidebarBackgroundColor}},[_c('mobile-menu'),_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"link":{
          name: 'Dashboard',
          icon: 'dashboard',
          path: '/party-dashboard',
        }}}),_c('sidebar-item',{attrs:{"closed":"","link":{ name: 'Information', icon: 'I' }}},[_c('sidebar-item',{attrs:{"link":{
            name: 'Party Details',
            path: '/party/party-details',
            icon: 'dashboard',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Update Info',
            path: '/party/update-info',
          }}}),_c('sidebar-item',{attrs:{"link":{
            name: 'Party Help Desk',
            path: '/customer/query',
          }}})],1),(_vm.partyType === 'BOTH' || _vm.partyType === 'VENDOR')?_c('sidebar-item',{attrs:{"closed":"","link":{ name: 'Vendor', icon: 'V' }}},_vm._l((_vm.partyVendorMenus),function(menu,index){return _c('sidebar-item',{key:(index + "-vendor"),attrs:{"link":{ name: menu.title, path: menu.url }}})}),1):_vm._e(),(_vm.partyType === 'BOTH' || _vm.partyType === 'CUSTOMER')?_c('sidebar-item',{attrs:{"closed":"","link":{ name: 'Customer', icon: 'C' }}},_vm._l((_vm.customerMenus),function(menu,index){return _c('sidebar-item',{key:index,attrs:{"link":{ name: menu.title, path: menu.url }}})}),1):_vm._e(),_c('sidebar-item',{attrs:{"closed":"","link":{ name: 'Settings', icon: 'S' }}},_vm._l((_vm.settings),function(menu,index){return _c('sidebar-item',{key:index,attrs:{"link":{ name: menu.title, path: menu.url }}})}),1)],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('div',{class:{ content: !_vm.$route.meta.hideContent }},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }