<template>
<div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
          <div class="md-layout-item">
        <b-table
          striped
          hover
          outlined
          sticky-header
          :items="gridData"
          :fields="gridField"
          show-empty
          class="bg-white"
          md-height
        >
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
          </div>
      </md-card>
    </div>
</template>
<script>
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  name: "MismatchDetails",
  props: ["apInvoiceId"],
  watch: {
    currentPage: function () {
      this.getMismatchDetails();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getMismatchDetails();
    },
  },
  data(){
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      gridData: [],
      gridField: [
        {
          key: "book_month",
        },
        {
          key: "gstr2a_month",
          label: "GSTR2A Month"
        },
        {
          key: "gst2ra_igst",
          label: "GSTR2A IGST"
        },
        {
          key: "gst2ra_cgst",
          label: "GSTR2A CGST"
        },
        {
          key: "book_igst_amount",
          label: "Book IGST Amount"
        },
        {
          key: "book_cgst_amount",
          label: "Book CGST Amount"
        },
        {
          key: "book_document_amount"
        },
        {
          key: "book_sgst_or_utgst_amount",
          label: "Book SGST Or UTGST Amount"
        },
        {
          key: "gstr2a_final_comments",
          label: "GSTR2A Final Comments"
        },
        {
          key: "book_document_date"
        },
        {
          key: "book_taxable_value"
        },
        {
          key: "gstr2a_document_date",
          label: "GSTR2A Document Date"
        },
        {
          key: "gstr2a_document_value",
          label: "GSTR2A Document Value"
        },
        {
          key: "gstr2a_document_taxable_value",
          label: "GSTR2A Document Taxable value"
        },
        {
          key: "gst2ra_sgst_utgst",
          label: "GSTR2A SGST UTGST"
        }
      ],
    }
  },
  mounted() {
    this.getMismatchDetails();
  },
  methods: {
     getMismatchDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        ap_invoice_id: this.apInvoiceId,
      };
      // this.gridData = [];
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getMismatchDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.gridData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        });
    },
  }
};
</script>