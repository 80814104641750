<template>
  <div class="md-layout">
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout">
          <md-card-header
            class="md-card-header-text md-card-header-green search-icon"
          >
            <div class="card-icon vendor-icon">
              <md-icon style="font-size: 20px !important">search</md-icon>
            </div>
          </md-card-header>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label for="font">PO Number</label>
              <md-input v-model="poNum"> </md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Amount From</label>
              <md-input type="text" v-model="poAmtFrom"></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Amount To</label>
              <md-input type="text" v-model="poAmtTo"></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>Location</label>
              <md-input type="text" v-model="location"></md-input>
            </md-field>
          </div>
          <div
            style="min-width:10%;"
            class="md-layout-item md-small-size-100 md-size-10"
          >
            <md-field>
              <label>PO Type</label>
              <md-select v-model="poType">
                <md-option value="STANDARD">Standard PO</md-option>
                <md-option value="BLANKET">Blanket PO</md-option>
              </md-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-14 DateFormate">
            <date-picker
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="From Date"
              v-model="creationDateFrom"
              @change="creationDateTo = null"
            ></date-picker>
          </div>
          <div
            style="margin-left:-1%;"
            class="md-layout-item md-small-size-100 md-size-14 DateFormate"
          >
            <date-picker
              md-immediately
              valueType="format"
              format="DD-MMM-YYYY"
              placeholder="To Date"
              v-model="creationDateTo"
              :disabled-date="disabledDates"
              :disabled="!creationDateFrom"
            ></date-picker>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-22">
            <div style="margin-top: 6%">
              <md-button
                class="md-raised md-success search-btn"
                style="margin-left: -8%; margin-right: 5%"
                @click="isVendorApproved()"
                >Search</md-button
              >
              <md-button
                class="md-raised md-info search-btn"
                style="margin-right: 4%"
                @click="clearSearch()"
                >Clear</md-button
              >
              <md-button
                v-if="gridData.length"
                style="height:28px;"
                class="md-warning md-just-icon"
                @click="downloadCustomExcel()"
                title="Download Excel"
                ><md-icon>file_download</md-icon></md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <div class="md-layout-item">
          <b-table
            striped
            hover
            outlined
            sticky-header
            :items="gridData"
            :fields="gridField"
            show-empty
            class="bg-white"
            style="max-height:calc(100vh - 220px)"
            @row-clicked="rowSelected"
          >
            <template #head(Invoice_type)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(po_number)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(po_status)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(po_type)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(revision_num)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(creation_date)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(legel_entity)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(full_name)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(first_party_reg)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(third_party_reg)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(location_code)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(currency_code)="data">
              <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
            </template>
            <template #head(total_value)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(total_value)="data">
              <span class="text-fix-po">
                {{
                  data.item.total_value !== 0
                    ? formattedCurrency(data.item.total_value)
                    : data.item.total_value
                }}</span
              >
            </template>
            <template #head(open_value)="data">
              <div style="text-align:center !important;">
                <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
              </div>
            </template>
            <template #cell(open_value)="data">
              <span class="text-fix-po">
                {{
                  data.item.open_value !== 0
                    ? formattedCurrency(data.item.open_value)
                    : data.item.open_value
                }}</span
              >
            </template>
            <template #cell(download_report)="data">
              <a
                href="#"
                style="cursor:pointer;"
                @click="isRecordIdAvail(data.item)"
                ><span
                  v-if="data.item.po_header_id"
                  style="margin-left:2rem; color: #007bff;"
                  class="material-icons"
                >
                  file_download
                </span></a
              >
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
          <div class="md-layout pagination-count">
            <div style="width:18%;">
              <div v-if="gridData.length && totalRows <= perPage">
                <p>
                  Showing
                  {{
                    totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1
                  }}
                  to {{ totalRows }} of {{ totalRows }} entries
                </p>
              </div>
              <div v-else-if="gridData.length">
                <p>
                  Showing {{ perPage * (currentPage - 1) + 1 }} to
                  {{
                    perPage * currentPage >= totalRows
                      ? totalRows
                      : perPage * currentPage
                  }}
                  of {{ totalRows }}
                  entries
                </p>
              </div>
            </div>
            <div v-if="gridData.length" style="margin-top:-0.5%; width:7%">
              <div class="md-layout-item">
                <md-field>
                  <md-select v-model="perPage">
                    <md-option
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div style="color:red; margin-left:50%;">
              <span>Click on the Field Header for more information</span>
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showModal"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="wide-modal"
      title="PO Line Details"
      @hidden="openShowModal(false)"
    >
      <PoLineDetails :poLineRowDetails="poLineRowDetails" />
    </b-modal>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import PoLineDetails from "./PoLineDetails.vue";
import DatePicker from "vue2-datepicker";
import HeaderSummary from "../HeaderSummary.vue";
import URL_UTILITY from "../../../utility/url.utility";

export default {
  components: {
    PoLineDetails,
    DatePicker,
    HeaderSummary,
  },
  name: "PoDetails",
  watch: {
    currentPage: function() {
      this.getVendorPoDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getVendorPoDetails();
    },
  },
  data() {
    return {
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: commonHelper.getPageOption(),
      },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      loader: null,
      gridData: [],

      poNum: null,
      poAmtFrom: null,
      poAmtTo: null,
      creationDateFrom: null,
      creationDateTo: null,
      poLineRowDetails: null,
      showModal: false,
      PoLineDetails: null,
      gridField: [
        {
          key: "legel_entity",
          label: "Legal Entity",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        {
          key: "po_status",
          label: "PO Status",
        },
        {
          key: "po_type",
          label: "PO Type",
        },
        {
          key: "revision_num",
          label: "Revision Number",
        },
        {
          key: "creation_date",
        },
        { key: "release_date" },
        {
          key: "full_name",
          label: "Buyer Name",
          class: "party-col",
        },
        {
          key: "first_party_reg",
          label: "Company GSTN",
          class: "party-col",
        },
        {
          key: "third_party_reg",
          label: "Third Party GSTN",
          class: "party-col",
        },
        {
          key: "location_code",
          class: "party-col",
        },
        {
          key: "total_value",
          class: "text-fix-po",
        },
        {
          key: "open_value",
          class: "text-fix-po",
        },
        {
          key: "currency_code",
          label: "Currency",
          class: "width-fix",
        },
        {
          key: "download_report",
        },
      ],
      selected: [],
      location: null,
      totalPayload: null,
      menuId: null,
      vendorId: null,
      poType: null,
      requestNumId: null,
      requestStatus: null,
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("PODetails"));
    this.vendorId = JSON.parse(sessionStorage.getItem("VendorId"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.vendorId) {
      this.getVendorPoDetails();
    }
  },
  methods: {
    downloadExcelFile() {
      this.loader = true;
      /**
       * @param(payload, 'action name', 'file name')
       */
      const downloadpayload = { ...this.totalPayload };
      downloadpayload._limit = this.totalRows;
      this.downloadExcel.downloadData(
        downloadpayload,
        "vendor/getVendorPoDetails",
        "po-details",
        () => (this.loader = false)
      );
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(Number(num));
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.creationDateFrom);
    },
    isVendorApproved() {
      if (this.vendorId) {
        this.getVendorPoDetails();
      } else {
        alert("Vendor is Not Approved");
      }
    },
    getVendorPoDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        amount_from: this.poAmtFrom ? this.poAmtFrom : "",
        amount_to: this.poAmtTo ? this.poAmtTo : "",
        vendor_id: this.vendorId,
        po_num: this.poNum ? this.poNum : "",
        po_creation_from_date: this.creationDateFrom,
        po_creation_to_date: this.creationDateTo,
        location_code: this.location,
        po_type: this.poType,
      };
      this.totalPayload = payload;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPoDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.gridData = result.map((elem) => {
              elem.creation_date = commonHelper.formatDate(elem.creation_date);
              elem.release_date = commonHelper.formatDate(elem.release_date);
              return elem;
            });
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    downloadCustomExcel() {
      this.totalPayload._limit = this.totalRows;
      this.loader = true;
      this.$store
        .dispatch("vendor/getVendorPoDetails", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.creation_date = commonHelper.formatDate(elem.creation_date);
            elem.total_value =
              elem.total_value !== 0
                ? commonHelper.formatAmount(elem.total_value)
                : elem.total_value;
            elem.open_value =
              elem.open_value !== 0
                ? commonHelper.formatAmount(elem.open_value)
                : elem.open_value;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: obj.legel_entity,
            po_number: obj.only_po_number,
            revision_num: obj.revision_num,
            creation_date: obj.creation_date,
            buyer_name: obj.full_name,
            company_gstn: obj.first_party_reg,
            third_party_gstn: obj.third_party_reg,
            location_code: obj.location_code,
            total_value: obj.total_value,
            open_value: obj.open_value,
            currency: obj.currency_code,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(customDownloadData, "po-details");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    clearSearch() {
      this.poNum = null;
      this.poAmtFrom = null;
      this.poAmtTo = null;
      this.creationDateFrom = null;
      this.creationDateTo = null;
      this.location = null;
      this.poType = null;
      this.getVendorPoDetails();
    },

    rowSelected(item) {
      this.poLineRowDetails = item;
      this.openShowModal(true);
    },
    openShowModal(flag) {
      this.showModal = flag;
    },
    isRecordIdAvail(item) {
      const payload = {
        poHdrId: item.po_header_id,
        revisionNo: item.revision_num,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/isRecordIdAvail", payload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data;
          if (result) {
            window.location.href =
              URL_UTILITY.getVendorUrl + "/download/" + result.po_req_id;
          } else {
            this.saveSubmitRequest(item);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest(item) {
      const payload = {
        InputParameters: [
          {
            REQUEST_NAME: "DEL_POGRN",
            OU_NAME: item.ou_name,
            ARGUMENT1: item.ou_id,
            ARGUMENT2: item.only_po_number,
            ARGUMENT3: "",
            ARGUMENT4: "",
            ARGUMENT5: "",
            ARGUMENT6: "",
            ARGUMENT7: "",
            ARGUMENT8: "",
            ARGUMENT9: "",
            ARGUMENT10: "",
            ARGUMENT11: "",
            ARGUMENT12: "",
            ARGUMENT13: "",
            ARGUMENT14: "",
            ARGUMENT15: "",
          },
        ],
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/poDetailSubmitRequest", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestNumId =
              resp.data.data.data.OutputParameters[0].REQUEST_ID;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      const payload = {
        requestId: this.requestNumId,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getSubmitRequest", payload)
        .then((resp) => {
          if (resp.status === 200) {
            const result = resp.data.data.data;
            if (result) {
              this.requestStatus = result.status_code;
              if (this.requestStatus === "C") {
                this.loader = false;
                alert('Request Completed Successfully')
                window.location.href =
                  URL_UTILITY.getVendorUrl + "/download/" + this.requestNumId;
              } else {
                  this.getSubmitRequest();
              }
            } else {
              setTimeout(() => {
                this.getSubmitRequest();
              }, 3000);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
  },
};
</script>
<style>
.vendor-icon {
  margin-right: 0px !important;
  padding: 0px !important;
}

.vendor-icon i {
  font-size: 20px !important;
  line-height: 60px !important;
  width: 30px !important;
  height: 30px !important;
  text-align: center;
}

.vendor-btn {
  margin-right: 10px;
  border-radius: 15px;
  margin-top: 5px !important;
}

.md-card .md-card-content {
  padding: 5px 0px !important;
}

.search-icon {
  margin: 0 5px !important;
}

.invocie-col > div {
  width: 160px !important;
}
.DateFormate {
  margin-top: 17px !important;
}
.total-value {
  max-width: 45px !important;
  text-align: right !important;
}
.width-fix {
  max-width: 80px !important;
}
.party-col > div {
  width: 160px !important;
}
.text-fix-po {
  margin-right: 23%;
  text-align: right;
}
.pagination-count {
  font-weight: bold;
  margin-top: -3.2% !important;
}
</style>
