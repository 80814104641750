<template>
  <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
    <div class="md-layout" style="justify-content: space-between">
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Legal Entity</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>PAN</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Finantial Year</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Quarter</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Customer TAN</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>CN NO.</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Amount INR</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>Date</label>
          <md-input type="text"></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-30">
        <md-field>
          <label>TDS Certificate</label>
          <md-file />
        </md-field>
      </div>
    </div>
    <div class="md-layout-item md-small-size-100 md-size-30">
      <div>
        <md-button class="md-raised md-info search-btn">Save TDS</md-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AddTdsCertificate",
};
</script>
