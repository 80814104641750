import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
};

const mutations = {};

const actions = {
  getValueSet(context, payload) {
    const url = URL_UTILITY.getValueSetUrl.replace(
      "{valueSetName}",
      payload.valueSetName
    );
    return http.getApi(url, payload.dependentVariable);
  },
};

const getters = {};

const valueSet = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default valueSet;
