import axios from "axios";
import router from "../router";
// import store from '../../store';
const redirectUnAuth = function(response) {
  if (response.status === 401) {
    sessionStorage.clear();
    // store.dispatch('auth/logout');
    window.location.reload();
    // router.replace({ name: "Login" });
  }
};

axios.interceptors.request.use(
  function(request) {
    const token = sessionStorage.getItem("authToken");
    if (token) {
      request.headers = request.headers || {};
      request.headers.Authorization = `Bearer ${token}`;
      request.headers.languageCode = "en";
      request.headers.lang_code = "en";
    }
    return request;
  },
  function requestError(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    if (response.headers.authorization) {
      sessionStorage.setItem("authToken", response.headers.authorization);
    }
    redirectUnAuth(response);
    return response;
  },
  function responseError(error) {
    const { response } = error;
    /**
     * Checks if token is either expired and apis return .
     */
    redirectUnAuth(response);
    if (response) {
      return response;
    }
    // return Promise.resolve({response: response});
    if (process.env.VUE_APP_DEBUG) {
      // commenting due to build failure.
      // const {
      //   response,
      //   config: request
      // } = error;
    }
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axios;
