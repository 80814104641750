import http from "@/services/httpServices";
import URL_UTILITY from "@/utility/url.utility";

const state = {
  list: {},
};

const mutations = {};

const actions = {
  getDetails(context, detailType) {
    return http.getApi(URL_UTILITY.getPartyDetailsUrl + "/" + detailType);
  },
  addEditPartyDetails(context, payload) {
    return http.postApi(URL_UTILITY.getPartyDetailsUrl + "-details", payload);
  },
  getUpdatedPartyDetails(context) {
    return http.getApi(URL_UTILITY.getPartyDetailsUrl + "-details/updated");
  },
  getAddresses(context, payload) {
    return http.getApi(URL_UTILITY.getPartyDetailsUrl + "/addresses", payload);
  },
  addEditAddress(context, payload) {
    return http.postApi(URL_UTILITY.getPartyDetailsUrl + "/addresses", payload);
  },
  getUpdatedAddress(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-addresses/updated",
      payload
    );
  },
  getLovByLookupType(context, LOOKUPTYPE) {
    return http.getApi(URL_UTILITY.getLovByLookupTypeUrl + "/" + LOOKUPTYPE);
  },
  getCountryLov(context) {
    return http.getApi(URL_UTILITY.getCountryLovUrl);
  },
  getCountryState(context, countryId) {
    return http.getApi(
      URL_UTILITY.getStatesLovByCountryIdUrl.replace("{countryId}", countryId)
    );
  },
  getStateCity(context, stateId) {
    return http.getApi(
      URL_UTILITY.getCitiesLovByStateIdUrl.replace("{stateId}", stateId)
    );
  },
  getCityPincode(context, stateId) {
    return http.getApi(
      URL_UTILITY.getPincodesLovByStateIdUrl.replace("{stateId}", stateId)
    );
  },
  getPartyBank(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/drawee-banks",
      payload
    );
  },
  getUpdatedBank(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-drawee-banks/updated",
      payload
    );
  },
  addEditBank(context, payload) {
    return http.postApi(
      URL_UTILITY.getPartyDetailsUrl + "/drawee-banks",
      payload
    );
  },
  getTaxGroupLov(context, taxType) {
    return http.getApi(
      URL_UTILITY.getTaxGroupLovUrl.replace("{taxType}", taxType)
    );
  },
  getPartyIndirectTax(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/indirect-tax-details",
      payload
    );
  },
  getUpdatedIndirectTax(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-indirect-tax-details/updated",
      payload
    );
  },
  addEditIndirectTax(context, payload) {
    return http.postApi(
      URL_UTILITY.getPartyDetailsUrl + "/indirect-tax-details",
      payload
    );
  },
  getPartyDirectTax(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/direct-tax-details",
      payload
    );
  },
  getUpdatedDirectTax(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-direct-tax-details/updated",
      payload
    );
  },
  addEditDirectTax(context, payload) {
    return http.postApi(
      URL_UTILITY.getPartyDetailsUrl + "/direct-tax-details",
      payload
    );
  },
  getExistPartyContactHdr(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-comm-hdr",
      payload
    );
  },
  getExistPartyContactDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-comm-dtl",
      payload
    );
  },
  getUpdatePartyContactHdr(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-comm-hdr/updated",
      payload
    );
  },
  getUpdatePartyContactDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-comm-dtls/updated",
      payload
    );
  },
  addEditContact(context, payload) {
    return http.postApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-person",
      payload
    );
  },
  addEditCommDetails(context, payload) {
    return http.postApi(
      URL_UTILITY.getPartyDetailsUrl + "/contact-comm",
      payload
    );
  },
  changePassword(context, payload) {
    return http.postApi(URL_UTILITY.changePasswordUrl, payload);
  },
  getUpdateStatus(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "/update-status",
      payload
    );
  },
  getPpIdDirectTaxByDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-direct-tax/existed/ppId",
      payload
    );
  },
  deletePartyDirectTaxById(context, ppId) {
    return http.deleteApi(
      URL_UTILITY.getPartyDetailsUrl + "-direct-tax/" + ppId + "/delete"
    );
  },
  getPpIdIndirectTaxByDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-indirect-tax/existed/ppId",
      payload
    );
  },
  deletePartyIndirectTaxById(context, ppId) {
    return http.deleteApi(
      URL_UTILITY.getPartyDetailsUrl + "-indirect-tax/" + ppId + "/delete"
    );
  },
  getPpIdBankByDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-drawee-banks/existed/ppId",
      payload
    );
  },
  deleteBankById(context, ppId) {
    return http.deleteApi(
      URL_UTILITY.getPartyDetailsUrl + "-drawee-banks/" + ppId + "/delete"
    );
  },
  getPpIdAddressByDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-addresses/existed/ppId",
      payload
    );
  },
  deleteAddressById(context, ppId) {
    return http.deleteApi(
      URL_UTILITY.getPartyDetailsUrl + "-addresses/" + ppId + "/delete"
    );
  },
  getSubMenuById() {
    return http.getApi(URL_UTILITY.getPartyDetailsUrl + "-sub-menus");
  },
  getPpIdPartyDetails(context, payload) {
    return http.getApi(
      URL_UTILITY.getPartyDetailsUrl + "-details/existed/ppId",
      payload
    );
  },
  deletePartyDetailsById(context, ppId) {
    return http.deleteApi(
      URL_UTILITY.getPartyDetailsUrl + "-details/" + ppId + "/delete"
    );
  },
};

const getters = {};

const party = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default party;
