<template>
  <div class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100">
    <md-card>
      <md-card-header
        class="md-card-header-text md-layout-item md-card-header-green"
      >
        <div
          class="text-center card-text profile-card"
          style="height: 30px; width: 180px; padding: 3px !important"
        >
          <h5 class="title">Invoice Details</h5>
        </div>
      </md-card-header>
      <div class="md-layout" style="margin-top: 10px">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Legal Entity</label>
            <md-input type="text" v-model="legal_entity" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Source</label>
            <md-input type="text" v-model="source" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Invoice No.</label>
            <md-input type="text" v-model="invoice_no" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Date</label>
            <md-input type="text" v-model="date" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Currency</label>
            <md-input type="text" v-model="currency" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Gross Amount</label>
            <md-input type="text" v-model="gross_amount" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>GST Hold</label>
            <md-input type="text" v-model="gst_hold" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Paid</label>
            <md-input type="text" v-model="paid" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>GSTR2A Status</label>
            <md-input type="text" v-model="gstr2a_status" disabled></md-input>
          </md-field>
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-field>
            <label>Approval</label>
            <md-input
              type="text"
              v-model="approval"
              :disabled="editMode"
            ></md-input>
          </md-field>
        </div>
      </div>
      <div></div>
    </md-card>
    <div style="margin-top: 15px">
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-warning"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 180px; padding: 3px !important"
          >
            <h4 class="title">Tax Invoice Details</h4>
          </div>
        </md-card-header>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Vendor Tax Invoice No.</label>
              <md-input
                type="text"
                v-model="vendor_tax_invoice_no"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field v-if="approval_status === 'APPROVED'">
              <label>Vendor Tax Invoice Date</label>
              <md-input type="text" disabled v-model="vendor_tax_invoice_date">
              </md-input>
            </md-field>

            <md-datepicker
              v-else
              md-immediately
              v-model="vendor_tax_invoice_date"
            >
              <label>Vendor Tax Invoice Date</label>
            </md-datepicker>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-21">
            <md-field v-if="approval_status === 'APPROVED'">
              <label>Tax Type</label>
              <md-input
                type="text"
                v-model="tax_type_code.value"
                disabled
              ></md-input>
            </md-field>

            <md-field v-else>
              <label class="model-select-label">Tax Type</label>
              <model-select
                v-model="tax_type_code"
                :options="taxTypeList"
                placeholder="Tax Type"
              >
              </model-select>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Tax Rate</label>
              <md-input
                type="text"
                v-model="tax_rate"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Invoice Amount</label>
              <md-input
                type="text"
                v-model="invoice_amount"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Taxable Amount</label>
              <md-input
                type="text"
                v-model="taxable_amount"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>CGST Amount</label>
              <md-input
                type="text"
                v-model="cgst_amount"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>SGST Amount</label>
              <md-input
                type="text"
                v-model="sgst_amount"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>IGST Amount</label>
              <md-input
                type="text"
                v-model="igst_amount"
                :disabled="approval_status === 'APPROVED'"
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Approval Status</label>
              <md-input
                type="text"
                v-model="approval_status"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Approval Date</label>
              <md-input type="text" disabled v-model="approval_date">
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Approved By</label>
              <md-input type="text" v-model="approved_by" disabled></md-input>
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field>
              <label>Posting Status</label>
              <md-input
                type="text"
                v-model="posting_status"
                disabled
              ></md-input>
            </md-field>
          </div>
          <div
            v-if="approval_status !== 'APPROVED' && approval_status !== null"
            class="md-layout-item md-small-size-100 md-size-20"
          >
            <div style="margin-top: 10px">
              <md-button
                class="md-raised md-primary search-btn"
                @click="addEditTaxInvoiceDetail()"
                style="margin-right: 10px"
                >{{
                  approval_status === "DRAFT" ? "Submit" : "Re-Submit"
                }}</md-button
              >
            </div>
          </div>
        </div>
      </md-card>
    </div>
    <div style="margin-top: 15px">
      <md-card>
        <md-card-header
          class="md-card-header-text md-layout-item md-card-header-blue"
        >
          <div
            class="text-center card-text profile-card"
            style="height: 30px; width: 180px; padding: 3px !important"
          >
            <h5 class="title">Upload Document</h5>
          </div>
          <label style="margin-left: 10px"
            >Uploaded Documents : {{ uploadCount }}</label
          >
        </md-card-header>
        <div>
          <div class="md-layout-item md-small-size-50 md-size-20">
            <md-field style="margin: 10px">
              <label>Upload files</label>
              <md-input readonly @click="getOpenKmAuthenticate()"> </md-input>
            </md-field>
          </div>
        </div>
      </md-card>
    </div>
    <b-modal
      v-model="showHistory"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideHistory(false, null)"
    >
      <PartyUploadDocument
        :approval_status="approval_status"
        :wfHdrId="invoiceDetails.ap_invoice_id"
        :openKmAuth="openKmAuth"
        :selectedInvoiceData="selectedInvoiceData"
        @closeOpenKmModal="showHideHistory(false, null)"
        :propMenuId="menuId"
        :propSubMenuId="subMenuId"
        >Invoice No.:- {{ invoice_no }}
      </PartyUploadDocument>
    </b-modal>
  </div>
</template>

<script>
import PartyUploadDocument from "../../../PartyUploadDocument.vue";
import commonHelper from "../../../../utility/common.helper.utility";
import { ModelSelect } from "vue-search-select";
import appStrings from "../../../../utility/string.utility";
export default {
  components: {
    PartyUploadDocument,
    ModelSelect,
  },
  name: "UploadInvoice",
  props: ["invoiceDetails"],
  data() {
    return {
      taxTypeList: [],
      tax_type_code: {
        value: null,
        text: null,
      },
      uploadCount: 0,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      editMode: true,
      legal_entity: null,
      source: null,
      invoice_no: null,
      date: null,
      currency: null,
      gross_amount: null,
      gst_hold: null,
      paid: null,
      gstr2a_status: null,
      approval: null,
      vendor_tax_invoice_no: null,
      vendor_tax_invoice_date: null,
      taxInvoiceDate: null,
      tax_type: null,
      tax_rate: null,
      invoice_amount: null,
      taxable_amount: null,
      cgst_amount: null,
      sgst_amount: null,
      igst_amount: null,
      approval_status: "DRAFT",
      approval_date: null,
      approved_by: null,
      posting_status: null,
      openKmAuth: null,
      showHistory: false,
      selectedInvoiceData: null,
      menuId: 327,
      subMenuId: 0,
    };
  },
  mounted() {
    this.getTaxTypeValueSet();
    this.getUploadedDocDtl();
    if (this.invoiceDetails) {
      this.fillRecordInvoiceDetails();
    }
    this.getByIdTaxInvoiceDetails(this.invoiceDetails.pp_vendor_invoice_id);
  },
  methods: {
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 327,
        sub_menu_id: 0,
        record_id: this.invoiceDetails.ap_invoice_id,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadCount = response.data.data.total_elements;
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    fillRecordInvoiceDetails() {
      this.legal_entity = this.invoiceDetails.le_short_name;
      this.source = this.invoiceDetails.invoice_source;
      this.invoice_no = this.invoiceDetails.invoice_num;
      this.date = this.invoiceDetails.invoice_date;
      this.currency = this.invoiceDetails.currency;
      this.gross_amount = this.invoiceDetails.gross_amount;
      this.gst_hold = this.invoiceDetails.gst_amount_hold;
      this.paid = this.invoiceDetails.amount_paid;
      this.gstr2a_status = this.invoiceDetails.gstr2A_status;
      this.approval = this.invoiceDetails.approval_status_meaning;
    },
    addEditTaxInvoiceDetail() {
      const payload = {
        ap_invoice_id: this.invoiceDetails.ap_invoice_id,
        pp_vendor_invoice_id: this.invoiceDetails.pp_vendor_invoice_id
          ? this.invoiceDetails.pp_vendor_invoice_id
          : 0,
        vendor_tax_invoice_no: this.vendor_tax_invoice_no,
        vendor_tax_invoice_date: commonHelper.formattedDate(
          this.vendor_tax_invoice_date
        ),
        tax_type: this.tax_type_code.text,
        tax_rate: this.tax_rate,
        invoice_amount: this.invoice_amount,
        taxable_amount: this.taxable_amount,
        cgst_amount: this.cgst_amount,
        sgst_amount: this.sgst_amount,
        igst_amount: this.igst_amount,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/addEditTaxInvoiceDetail", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getByIdTaxInvoiceDetails(ppVendorInvoiceId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getByIdTaxInvoiceDetails", ppVendorInvoiceId)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            const results = response.data.data;
            this.vendor_tax_invoice_no = results.vendor_tax_invoice_no;
            this.tax_type_code.value = results.tax_type;
            this.tax_rate = results.tax_rate;
            this.invoice_amount = results.invoice_amount;
            this.taxable_amount = results.taxable_amount;
            this.cgst_amount = results.cgst_amount;
            this.sgst_amount = results.sgst_amount;
            this.igst_amount = results.igst_amount;
            this.approval_status = results.approval_status;
            if (this.approval_status === "APPROVED") {
              this.vendor_tax_invoice_date = results.vendor_tax_invoice_date;
            } else {
              this.vendor_tax_invoice_date = new Date(
                results.vendor_tax_invoice_date
              );
            }
            this.approval_date = results.approval_date;
            this.approved_by = results.approved_by;
            this.posting_status = results.posting_status_meaning;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getOpenKmAuthenticate() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
      if (flag === false) {
        this.getUploadedDocDtl();
      }
    },
    getTaxTypeValueSet() {
      const payload = {
        valueSetName: appStrings.VALUESETTYPE.TAX_TYPE_INVOICE,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("valueSet/getValueSet", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.value_code,
                text: type.value_meaning,
              };
            });
            this.taxTypeList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
<style>
.model-select-label {
  top: -16px !important;
}
.ui.selection.dropdown {
  border: none !important;
}
</style>
