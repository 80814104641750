<template >
  <md-card-content>
    <b-table
          striped
          hover
          outlined
          sticky-header
          :items="gridData"
          :fields="gridField"
          show-empty
          class="bg-white"
        >
        </b-table>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
  </md-card-content>
</template>
<script>
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  watch: {
    currentPage: function () {
      this.getUpdateStatus();
    },
    perPage: function () {
      this.currentPage = 1;
      this.getUpdateStatus();
    }
  },
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      gridData: [],
      gridField: [
        {
          key: "request_date",
          label: "Date of Request"
        },
        {
          key: "approval_status",
          label: "Status",
        },
        {
          key: "updation_type",
        },
        {
          key: "approval_date"
        },
        {
          key: "rejected_reason",
          label: "Reason",
        }
      ]
    };
  },
  mounted() {
     this.getUpdateStatus();
  },
  methods: {
    getUpdateStatus(){
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getUpdateStatus", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.gridData = response.data.data.page;
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    }
  },
};
</script>
<style>
.table-transparent {
  background-color: transparent !important;
}

.mt-0 {
  margin-top: 0 !important;
}
</style>
