<template>
  <b-row>
    <div class="overlay" v-if="loader">
      <b-spinner class="loader" variant="primary" label="Spinning"></b-spinner>
    </div>
    <div class="md-layout">
      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-40"
      >
        <b-alert
          :variant="isSuccess ? 'info' : 'danger'"
          :show="showAlert"
          dismissible
          fade
          @dismissed="showAlert = false"
          >{{ respMessage }}
        </b-alert>
      </div>
    </div>
    <slot></slot>
    <b-row v-if="approval_status !== 'APPROVED'">
      <b-col>
        <input
          type="file"
          ref="file"
          id="FileUpload1"
          style="display: none"
          @change="selectFile()"
        />
        <md-button
          class="md-info"
          style="margin-left: 15px"
          @click="browseFile()"
          >Add Files..</md-button
        >
        <md-button
          class="md-warning"
          style="margin-left: 5px"
          @click="uploadFile()"
          >Start Upload</md-button
        >
        <md-button class="md-danger" style="margin-left: 5px"
          >Cancel Upload</md-button
        >
      </b-col>
    </b-row>
    <div class="md-layout-item" style="margin-top: 5px !important">
      <b-table
        striped
        hover
        outlined
        :items="uploadDocList"
        :fields="uploadDocFields"
        show-empty
        class="bg-white"
        sticky-header
      >
        <template #cell(file_name)="data">
          <a
            target="_blank"
            :href="
              downloadDocUrl +
                '/download/' +
                data.item.docDetailId +
                '?token=' +
                openKmAuth
            "
          >
            {{ data.item.file_name }}</a
          >
        </template>
        <template #cell(leName)="data">
          <b-form-input
            type="text"
            v-model="data.item.leName"
            readonly
          ></b-form-input>
        </template>
        <template #cell(categoryName)="data">
          <b-form-input
            type="text"
            v-model="data.item.categoryName"
            readonly
          ></b-form-input>
        </template>
        <template #cell(subcategoryName)="data">
          <b-form-input
            type="text"
            v-model="data.item.subcategoryName"
            @click="openValueSetModal('OKM_SUB_CAT_DOC', data.index)"
            readonly
          ></b-form-input>
        </template>
        <template #cell(comments)="data">
          <b-form-input
            type="text"
            :disabled="data.item.docDetailId !== 0"
            v-model="data.item.comments"
          ></b-form-input>
        </template>
        <template #cell(delete)="data">
          <div @click="deleteRow(data.index, data.item.docDetailId)">
            <md-icon v-if="data.item.docDetailId">delete</md-icon>
          </div>
        </template>
      </b-table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      align="fill"
      size="sm"
      class="my-0"
    ></b-pagination>
    <valueSetModal
      v-if="showValueSetModal"
      @selectedvalueSet="selectedvalueSet"
      @closeValueSetModal="closeValueSetModal"
    ></valueSetModal>
  </b-row>
</template>
<script>
import URL_UTILITY from "../utility/url.utility";
import commonHelper from "../utility/common.helper.utility";
import appStrings from "../utility/string.utility";
export default {
  name: "uploadDocument",
  props: [
    "wfHdrId",
    "openKmAuth",
    "selectedInvoiceData",
    "approval_status",
    "propMenuId",
    "PropSubMenuId",
    "documentFlag",
  ],
  watch: {
    currentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUploadedDocDtl();
    },
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      progressBarMaxValue: 100,
      invoiceNumber: null,
      prgressbarValue: 0,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      selectedFilesArr: [],
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      uploadDocList: [],
      selectedObjectData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null,
      },
      uploadDocFields: [
        {
          key: "file_name",
        },
        {
          key: "file_size",
        },
        {
          key: "delete",
        },
      ],
      menuId: null,
      subMenuId: null,
      showValueSetModal: false,
      vsetCode: null,
      index: null,
    };
  },
  mounted() {
    if (this.documentFlag) {
      this.uploadDocFields.unshift({
        key: "subcategoryName",
        label: "Document",
      });
    }
    if (this.selectedInvoiceData.nfa_cat) {
      this.selectedObjectData = this.selectedInvoiceData;
    }
    this.selectedObjectData = this.selectedInvoiceData;
    this.menuId = this.propMenuId;
    this.subMenuId = this.PropSubMenuId;
    this.getUploadedDocDtl();
    // this.invoiceNumber = this.selectedInvoiceData.invoice_num;
  },
  methods: {
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.menuId,
        sub_menu_id: this.subMenuId,
        record_id: this.wfHdrId,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: data.doc_sub_category_vset
                    ? data.doc_sub_category_vset
                    : this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: data.doc_sub_category_meaning
                    ? data.doc_sub_category_meaning
                    : this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    uploadFile() {
      const filterDocData = this.uploadDocList.filter(
        (data) => data.docDetailId === 0
      );
      let checkSubCategory = true;
      if (this.documentFlag) {
        checkSubCategory = this.uploadDocList.every(
          (data) => data.subcategoryCode !== null
        );
      }
      if (checkSubCategory) {
        if (filterDocData.length > 0) {
          filterDocData.forEach((data) => {
            let formData = new FormData();
            formData.append("file", data.fileRef.item(0));
            formData.append("token", this.openKmAuth);
            formData.append(
              "category",
              data.categoryCode ? data.categoryCode : "DOCUMENT"
            );
            formData.append(
              "sub_category",
              data.subcategoryCode ? data.subcategoryCode : "TAN"
            );
            formData.append("comment", data.comments ? data.comments : "TAN");
            formData.append("doc_detail_id", 0);
            formData.append("menu_id", this.menuId);
            formData.append("sub_menu_id", this.subMenuId);
            // formData.append("le_id", data.leId);
            formData.append("record_id", this.wfHdrId);
            this.$store.dispatch("shared/setLoader", true);
            this.$store
              .dispatch("uploadDocument/uploadFile", formData)
              .then((response) => {
                this.$store.dispatch("shared/setLoader", false);
                this.showAlert = true;
                if (response.status === 200) {
                  this.$emit("updateStatusFlag", true);
                  this.getUploadedDocDtl();
                  this.editMode = false;
                  this.isSuccess = true;
                  this.respMessage = response.data.message;
                } else {
                  this.isSuccess = false;
                  this.respMessage =
                    response.data.message || "Something went wrong";
                }
              })
              .catch(() => {
                this.$store.dispatch("shared/setLoader", false);
              });
          });
        }
      } else {
        alert("Please Select Document Name");
      }
    },
    browseFile() {
      this.$refs.file.click();
    },
    selectFile() {
      this.selectedFilesArr.push(this.$refs.file.files);
      this.uploadDocList.push({
        fileRef: this.$refs.file.files,
        file_name: this.$refs.file.files[0].name,
        // leId: this.selectedObjectData.le_id,
        // leName: this.selectedObjectData.le_name,
        categoryCode: this.selectedObjectData.nfa_cat_vset,
        categoryName: this.selectedObjectData.nfa_cat,
        subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
        subcategoryName: this.selectedObjectData.nfa_sub_cat,
        comments: "",
        version: 0,
        file_size: this.formatBytes(this.$refs.file.files[0].size),
        action: null,
        docDetailId: 0,
      });
    },
    deleteRow(index, docDetailId) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/delteOpenKmDoc", docDetailId)
        .then((response) => {
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.respMessage = response.data.message;
            this.uploadDocList.splice(index, 1);
          } else {
            this.isSuccess = false;
            this.respMessage =
              response.response.data.message || "Something went wrong";
          }
          this.$store.dispatch("shared/setLoader", false);
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });

      this.uploadDocList.splice(index, 1);
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },

    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.index = index;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null,
        };
        this.eventBus.$emit("valueSetCode", vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.OKM_SUB_CAT_DOC) {
        this.uploadDocList[this.index].subcategoryCode = item.value_code;
        this.uploadDocList[this.index].subcategoryName = item.value_meaning;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
  },
  updated() {
    this.$emit("uploadedDocLists", this.uploadDocList);
  },
};
</script>
