<template>
  <!-- <md-card-content> -->
    <div>
        <p>
      Dear <b>{{partyName}},</b> <br>
      Welcome to RealApp - Vatika's Tenant Portal for accessing all the accounting information about your leased space with us.
      We have incorporated all the rental accounts data on the portal and even
      the maintenance accounting data will be available on the portal soon. 
      <br><br>
      To provide access to all the information about your lease for a seamless
      experience with our accounts team, we kindly request you to upload the
      necessary documents to initiate the process of onboarding on the tenant's
      portal. Your cooperation in this matter will help us serve you better.
      <br><br>
      To upload your KYC documents, please follow these simple steps: 
      <br><br>
      Step 1.  Navigate to "updateinfo" >>>> 2.click on indirect tax tab >>> 3. Click on add indirect tax >>> 4. Click on Next and Upload Document
      <br><br>
      * Please ensure that all the uploaded files are in PDF only. 
      <br><br>
      Please note your privacy and data security are our top
      priorities. Hence, all uploaded documents are encrypted and stored
      securely. 
      <br><br>
      If you encounter any difficulties during this process, our
      dedicated support team is ready to assist you. Simply click on the "Party
      helpdesk tab" for assistance. 
      <br><br>
      We look forward for your continuous support
      for using this online portal extensively for all your queries.
        </p>
    </div>
  <!-- </md-card-content> -->
</template>

<script>
export default {
  components: {},
  name: "",
  props: [""],
  data() {
    return {
        partyName: this.getName(),
    };
  },
  mounted() {},
  methods: {
    getName() {
      this.$store.dispatch("party/getDetails", "details").then((resp) => {
        if (resp.status === 200) {
          this.partyName = resp.data.data.party_details.party_name;
        }
      });
  },
  }
};
</script>
