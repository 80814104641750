<template>
  <div>
    <div class="overlayLoader" v-if="loader">
      <md-progress-spinner
        :md-diameter="100"
        class="md-accent spinner-postion"
        :md-stroke="7"
        md-mode="indeterminate"
      ></md-progress-spinner>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Legal Entity</label>
          <md-input
            v-model="advancePaymentHdrDetails.legal_entity"
            :title="advancePaymentHdrDetails.legal_entity"
            disabled
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Advance Invoice Number</label>
          <md-input v-model="advancePaymentHdrDetails.invoice_num" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Advance Invoice Amount</label>
          <md-input v-model="advancePaymentHdrDetails.invoice_amount" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Amount Paid</label>
          <md-input v-model="advancePaymentHdrDetails.amount_paid" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Amount Adjusted</label>
          <md-input v-model="advancePaymentHdrDetails.amount_adjusted" disabled>
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Amount Unadjusted</label>
          <md-input
            v-model="advancePaymentHdrDetails.amount_unadjusted"
            disabled
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Currency</label>
          <md-input
            v-model="advancePaymentHdrDetails.invoice_currency_code"
            :title="advancePaymentHdrDetails.invoice_currency_code"
            disabled
          >
          </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">TDS</label>
          <md-input v-model="advancePaymentHdrDetails.tds" disabled> </md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-20">
        <md-field>
          <label for="font">Advance Invoice Date</label>
          <md-input v-model="advancePaymentHdrDetails.invoice_date" disabled>
          </md-input>
        </md-field>
      </div>
    </div>
    <div class="md-layout-item tabLayout">
      <b-table
        bordered
        striped
        hover
        outlined
        sticky-header
        :items="advanceAdjDtlsData"
        :fields="advanceAdjDtlsField"
        class="bg-white"
        show-empty
        md-height
      >
        <template #cell(invoice_amount)="data"
          >{{ formattedCurrency(data.item.invoice_amount) }}
        </template>
        <template #cell(applied_amount)="data"
          >{{ formattedCurrency(data.item.applied_amount) }}
        </template>
        
      <template #head(invoice_num)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(invoice_amount)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(invoice_date)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
        <template #head(applied_amount)="data">
          <HeaderSummary :headerLabel="data.label" :menuId="menuId" />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      ></b-pagination>
      <div
        v-if="advanceAdjDtlsData.length && totalRows <= perPage"
        class="pagination-count"
      >
        <p>
          Showing
          {{ totalRows == 0 ? totalRows : perPage * (currentPage - 1) + 1 }}
          to {{ totalRows }} of {{ totalRows }} entries
        </p>
      </div>
      <div v-else-if="advanceAdjDtlsData.length" class="pagination-count">
        <p>
          Showing {{ perPage * (currentPage - 1) + 1 }} to
          {{
            perPage * currentPage >= totalRows
              ? totalRows
              : perPage * currentPage
          }}
          of {{ totalRows }} entries
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import HeaderSummary from "../HeaderSummary.vue";

export default {
  name: "AdvanceReportAdjustedDetails",
  watch: {
    currentPage: function() {
      this.getAdvanceAdjDetails(this.advanceDetails);
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAdvanceAdjDetails(this.advanceDetails);
    },
  },
  props: ["advanceDetails"],
  components:{
    HeaderSummary,
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      loader: false,
      advancePaymentHdrDetails: {
        legal_entity: null,
        invoice_num: null,
        invoice_amount: null,
        amount_paid: null,
        amount_adjusted: null,
        amount_unadjusted: null,
        invoice_currency_code: null,
        tds: null,
        invoice_date: null,
      },
      advanceAdjDtlsData: [],
      advanceAdjDtlsField: [
        {
          key: "invoice_num",
          label: "Invoice Number",
        },
        {
          key: "po_number",
          label: "PO Number",
        },
        {
          key: "invoice_amount",
        },
        {
          key: "invoice_date",
        },
        {
          key: "applied_amount",
        },
      ],
      menuId: null,
    };
  },
  mounted() {
    const menuItem = JSON.parse(sessionStorage.getItem("OpenAdvance"));
    if (menuItem) {
      this.menuId = menuItem.menu_id;
    }
    if (this.advanceDetails) {
      this.fillRecordForAdvAdjDetails(this.advanceDetails);
      this.getAdvanceAdjDetails(this.advanceDetails);
    }
  },
  methods: {
    getAdvanceAdjDetails(item) {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        invoice_id: item.unpaid_inv_id,
      };
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceAdjDetails", payload)
        .then((resp) => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data.data.page;
            this.advanceAdjDtlsData = result;
            this.totalRows = resp.data.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    fillRecordForAdvAdjDetails(result) {
      this.advancePaymentHdrDetails = result;
      this.advancePaymentHdrDetails.invoice_amount = this.formattedCurrency(
        result.invoice_amount
      );
      this.advancePaymentHdrDetails.amount_paid = this.formattedCurrency(
        result.amount_paid
      );
      this.advancePaymentHdrDetails.amount_adjusted = this.formattedCurrency(
        result.amount_adjusted
      );
      this.advancePaymentHdrDetails.amount_unadjusted = this.formattedCurrency(
        result.amount_unadjusted
      );
    },
    downloadCustomExcel() {
      this.totalPayload._limit = 1000;
      this.loader = true;
      this.$store
        .dispatch("vendor/getAdvanceAdjDetails", this.totalPayload)
        .then((resp) => {
          this.loader = false;
          const result = resp.data.data.data.page.map((elem) => {
            elem.check_date = commonHelper.formatDate(elem.check_date);
            elem.invoice_date = commonHelper.formatDate(elem.invoice_date);
            elem.amount =
              elem.amount !== 0
                ? commonHelper.formatAmount(elem.amount)
                : elem.amount;
            elem.invoice_amount =
              elem.invoice_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_amount)
                : elem.invoice_amount;
            elem.invoice_payment_amount =
              elem.invoice_payment_amount !== 0
                ? commonHelper.formatAmount(elem.invoice_payment_amount)
                : elem.invoice_payment_amount;
            return elem;
          });
          const customDownloadData = result.map((obj) => ({
            legal_entity: this.advanceDetails.legal_entity,
            advance_invoice_number: this.advanceDetails.invoice_num,
            invoice_amount: this.advanceDetails.invoice_amount,
            amount_paid: this.advanceDetails.amount_paid,
            amount_adjusted: this.advanceDetails.amount_adjusted,
            amount_unadjusted: this.advanceDetails.amount_unadjusted,
            tds: this.advanceDetails.tds,
            invoice_date: this.advanceDetails.invoice_date,
            invoice_number: obj.invoice_num,
            invoice_amount: obj.invoice_amount,
            applied_amount: obj.applied_amount,
            invoice_date: obj.invoice_date,
            invoice_currency_code: this.advanceDetails.invoice_currency_code,
          }));
          if (customDownloadData) {
            commonHelper.downloadExcel(
              customDownloadData,
              "vendor-payment-details"
            );
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formattedCurrency(num) {
      return commonHelper.formatAmount(num);
    },
  },
};
</script>

<style>
.text-align-chk-amt {
  text-align: right !important;
  margin-right: 32% !important;
}
.text-align-inv-amt {
  text-align: right !important;
  margin-right: 35% !important;
}
</style>
