<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item md-small-size-100 md-size-25">
        <b-col md="3">
          <label>Vendor Name</label>
          <p>{{ vendorDetails.name }}</p>
        </b-col>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <b-col>
          <label>PAN No.</label>
          <p>{{ vendorDetails.pan_no }}</p>
        </b-col>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <b-col>
          <label>Aproval Status</label>
          <p>{{ vendorDetails.approval_status }}</p>
        </b-col>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-25">
        <b-col>
          <label>PAN Document</label>
          <p>
            <span style="margin-top:1.8rem; cursor:pointer;">
              <a
                style="margin-left:0.5rem;"
                target="_blank"
                :href="
                  downloadDocUrl +
                    '/download/' +
                    vendorDetails.document_det_id +
                    '?token=' +
                    openKmAuth
                "
                ><b>{{ vendorDetails.original_file_name }}</b></a
              >
            </span>
          </p>
        </b-col>
      </div>
    </div>
    <!-- <md-toolbar class="md-primary md-dense" style="width:10rem;"> -->
    <h5 style="margin-top:0rem; margin-bottom:-0.2rem; margin-left:0.5rem;">
      <b>Address Details</b>
    </h5>
    <!-- </md-toolbar> -->
    <md-card-content>
      <b-table
        striped
        hover
        outlined
        sticky-header
        :items="vendorAddressData"
        :fields="vendorAddressField"
        show-empty
        class="bg-white"
      >
        <template #cell(gstn_not_avail)="data">
          <span>{{ data.item.gstn_not_avail ? "Yes" : "No" }}</span>
        </template>
        <template #cell(sez)="data">
          <span>{{ data.item.sez ? "Yes" : "No" }}</span>
        </template>
        <template #cell(original_file_name)="data">
          <a
            href="#"
            style="cursor:pointer;"
            @click="
              getOpenKmAuthenticate(
                data.item.document_det_id,
                data.item.original_file_name
              )
            "
            >{{ data.item.original_file_name }}</a
          >
        </template>
      </b-table>
    </md-card-content>

    <h5 style="margin-top:0rem; margin-bottom:-0.2rem; margin-left:0.5rem;">
      <b> Contact Details</b>
    </h5>
    <md-card-content>
      <b-table
        striped
        hover
        outlined
        sticky-header
        :items="vendorContactData"
        :fields="vendorContactField"
        show-empty
        class="bg-white"
      >
      </b-table>
    </md-card-content>

    <h5 style="margin-top:0rem; margin-bottom:-0.2rem; margin-left:0.5rem;">
      <b> Bank Details</b>
    </h5>
    <md-card-content>
      <b-table
        striped
        hover
        outlined
        sticky-header
        :items="vendorBankData"
        :fields="vendorBankFields"
        show-empty
        class="bg-white"
      >
        <template #cell(original_file_name)="data">
          <a
            href="#"
            style="cursor:pointer;"
            @click="
              getOpenKmAuthenticate(
                data.item.document_det_id,
                data.item.original_file_name
              )
            "
            >{{ data.item.original_file_name }}</a
          >
        </template>
      </b-table>
    </md-card-content>

    <h5 style="margin-top:0rem; margin-bottom:-0.2rem; margin-left:0.5rem;">
      <b> Compliance Details</b>
    </h5>
    <md-card-content>
      <b-table
        striped
        hover
        outlined
        sticky-header
        :items="vendorComplianceData"
        :fields="vendorComplianceFields"
        show-empty
        class="bg-white"
      >
        <template #cell(original_file_name)="data">
          <a
            href="#"
            style="cursor:pointer;"
            @click="
              getOpenKmAuthenticate(
                data.item.document_det_id,
                data.item.original_file_name
              )
            "
            >{{ data.item.original_file_name }}</a
          >
        </template>
      </b-table>
    </md-card-content>

    <h5 style="margin-top:0rem; margin-bottom:-0.2rem; margin-left:0.5rem;">
      <b> Additional Details</b>
    </h5>
    <md-card-content>
      <b-table
        striped
        hover
        outlined
        sticky-header
        :items="vendorAdditionalData"
        :fields="vendorAdditionalFields"
        show-empty
        class="bg-white"
      >
        <template #cell(add_info_value)="data">
          <span
            v-if="
              data.item.add_info_vset === 'MSME' ||
                data.item.add_info_vset === 'E-Invoice'
            "
          >
            {{ data.item.add_info_value === "true" ? "Yes" : "No" }}</span
          >
          <span v-else> {{ data.item.add_info_value }}</span>
        </template>
      </b-table>
    </md-card-content>
  </div>
</template>

<script>
import commonHelper from "../../../utility/common.helper.utility";
import URL_UTILITY from "../../../utility/url.utility";
export default {
  components: {},
  name: "PartyOnboardingReview",
  props: [],
  data() {
    return {
      partyReqId: this.$store.state.auth.partyId,
      vendorAddressData: [],
      vendorAddressField: [
        {
          key: "address_type_lookup_meaning",
          label: "Address Type",
        },
        {
          key: "address",
        },
        {
          key: "gstn",
          label: "GSTN",
        },
        {
          key: "gstn_not_avail",
          label: "GSTN Not Available",
          class: "tla-col",
        },
        {
          key: "sez",
          label: "SEZ",
        },
        {
          key: "section",
          label: "TDS Section",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
      ],
      vendorContactData: [],
      vendorContactField: [
        {
          key: "name",
        },
        {
          key: "mob",
          label: "Mobile",
        },
        {
          key: "email",
        },
      ],
      vendorBankData: [],
      vendorBankFields: [
        {
          key: "bank_code_meaning",
          label: "Bank Name",
        },
        {
          key: "branch_name",
        },
        {
          key: "account_num",
          label: "Account Number",
        },
        {
          key: "ifsc",
          label: "IFSC",
        },
        {
          key: "beneficiary_name",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
      ],
      vendorComplianceData: [],
      vendorComplianceFields: [
        {
          key: "serial_no",
          label: "Serial No.",
        },
        {
          key: "iso_name",
          label: "Certificate Name",
        },
        {
          key: "is_applicable",
        },
        {
          key: "expiry_date",
        },
        {
          key: "original_file_name",
          label: "File Name",
        },
      ],
      vendorAdditionalData: [],
      vendorAdditionalFields: [
        {
          key: "add_info_vset",
          label: "Additional Info",
        },
        {
          key: "add_info_value",
          label: "Additional Value",
        },
        {
          key: "certificate_no",
        },
      ],
      openKmAuth: null,
      downloadDocUrl: URL_UTILITY.getDocumentDownloadUrl,
      vendorDetails: {
        name: null,
        pan_no: null,
        approval_status: null,
        document_det_id: null,
        original_file_name: null,
      },
    };
  },
  mounted() {
    this.getOpenKmAuthenticateUpload();
    this.getVendorDetails();
    this.getVendorAddressDetails();
    this.getVendorContactDetails();
    this.getVendorBankDetails();
    this.getVendorComplianceDetails();
    this.getAdditionalDetailsById();
  },
  methods: {
    getOpenKmAuthenticateUpload() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            // if (!this.isDocumentUploaded) {
            //   this.uploadFile();
            // }
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorDetails() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorDetails = result;

            // if (result.document_det_id) {
            //   this.isDocumentUploaded = true;
            //   this.getOpenKmAuthenticateUpload();
            // } else {
            //   this.isDocumentUploaded = false;
            // }
            // this.vendorId = result.ora_party_id;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorAddressDetails() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorAddressDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorAddressData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorContactDetails() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorContactDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorContactData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorBankDetails() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorBankDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorBankData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getVendorComplianceDetails() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getVendorComplianceDetails", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorComplianceData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getAdditionalDetailsById() {
      const payload = {
        party_req_id: this.partyReqId,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("vendor/getAdditionalDetailsById", payload)
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const result = resp.data.data;
            this.vendorAdditionalData = result;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getOpenKmAuthenticate(docDtlId, fileName) {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            const url =
              this.downloadDocUrl +
              "/download/" +
              docDtlId +
              "?token=" +
              this.openKmAuth;
            commonHelper.downloadFile(url, fileName);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
