<template>
  <md-card-content>
    <b-alert
      :variant="isSuccess ? 'primary' : 'danger'"
      :show="showAlert"
      dismissible
      fade
      @dismissed="showAlert = false"
      >{{ responseMsg }}
    </b-alert>
    <div class="md-layout">
      <div class="md-layout-item md-size-50">
        <md-field>
          <label class="model-select-label">Tax Group</label>
          <model-select
            :options="taxGroupList"
            v-model="taxGroup"
            :isDisabled="!editMode"
          ></model-select>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>GSTIN/TAN No.</label>
          <md-input
            type="text"
            v-model="gstinTanNo"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-field>
          <label>Issuing Authority</label>
          <md-input
            type="text"
            v-model="issuingAuthority"
            :disabled="!editMode"
          ></md-input>
        </md-field>
      </div>
      <div class="md-layout-item md-small-size-100 md-size-50">
        <md-checkbox
          v-model="active"
          :value="true"
          :unchecked-value="false"
          :disabled="!editMode"
          >Active</md-checkbox
        >
      </div>
      <div
        class="md-layout-item md-small-size-100 md-size-50"
        style="margin-top: 10px"
      >
        <b-row>
          <md-button
            class="md-raised md-info search-btn"
            v-if="showNextButton"
            @click="editButton()"
            >{{ !addFlag ? "edit" : "next" }}</md-button
          >
          <md-button
            style="margin-left: 10px"
            class="md-raised md-info search-btn"
            v-if="showUploadButton"
            @click="uploadButton()"
            >Upload</md-button
          >
          <md-button
            style="margin-left: 10px"
            class="md-raised md-info search-btn"
            v-if="showSaveButton && !addFlag"
            @click="saveButton()"
            >Save</md-button
          >
        </b-row>
      </div>
    </div>
    <b-modal
      v-model="showHistory"
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      title="Upload Document"
      @hidden="showHideHistory(false, null)"
    >
      <uploadDocument
        :openKmAuth="openKmAuth"
        @closeOpenKmModal="showHideHistory(false, null)"
        :propMenuId="menuId"
        :PropSubMenuId="subMenuId"
        :wfHdrId="ppId"
        :selectedInvoiceData="selectedInvoiceData"
        @updateStatusFlag="changeUploadFlag"
      ></uploadDocument>
    </b-modal>
  </md-card-content>
</template>
<script>
import { ModelSelect } from "vue-search-select";
import appStrings from "../../../../utility/string.utility";
import store from "../../../../store";
import uploadDocument from "../../../uploadDocument.vue";
import commonHelper from "../../../../utility/common.helper.utility";
export default {
  components: {
    ModelSelect,
    uploadDocument,
  },
  watch: {
    ppIndirectTaxId: function() {
      this.ppId = this.ppIndirectTaxId;
    },
  },
  name: "addEditIndirectTax",
  props: ["indirectTaxDetails", "ppIndirectTaxId", "recordType"],
  data() {
    return {
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      currentPage: 1,
      totalRows: null,
      showAlert: false,
      loader: false,
      isSuccess: false,
      responseMsg: "",
      taxGroup: {
        value: null,
        text: null,
      },
      taxGroupList: [],
      gstinTanNo: null,
      issuingAuthority: null,
      ppPartyIndirectTaxId: 0,
      partyIndirectTaxId: null,
      editMode: false,
      isRecordSaved: false,
      addFlag: false,
      openKmAuth: null,
      showHistory: false,
      ppId: null,
      menuId: 326,
      subMenuId: null,
      selectedInvoiceData: null,
      uploadStatusFlag: false,
      showUploadButton: false,
      showSaveButton: false,
      showNextButton: true,
      taxRecordType: null,
      active: false,
    };
  },
  mounted() {
    this.taxRecordType = this.recordType;
    if (this.indirectTaxDetails) {
      this.fillIndirectTaxDetails(this.indirectTaxDetails);
    } else {
      this.editMode = true;
      this.addFlag = true;
    }
    this.getTaxGroupLov();
    this.getSubMenuById();
  },
  methods: {
    fillIndirectTaxDetails(item) {
      this.taxGroup = {
        value: item.tax_group_id,
        text: item.tax_group,
      };
      this.taxGroupList = [
        {
          value: item.tax_group_id,
          text: item.tax_group,
        },
      ];
      this.gstinTanNo = item.tax_num;
      this.issuingAuthority = item.issuing_authority;
      this.ppPartyIndirectTaxId = item.pp_party_indirect_tax_id;
      this.partyIndirectTaxId = item.party_indirect_tax_id;
      this.ppId = item.pp_party_indirect_tax_id;
      this.active = item.active;
    },
    editButton() {
      if (this.taxRecordType === "exist" || this.taxRecordType === "new") {
        this.isRecordSaved = true;
        this.addEditIndirectTax();
      } else {
        this.editMode = true;
        this.showNextButton = false;
        this.showUploadButton = true;
      }
    },
    saveButton() {
      this.isRecordSaved = false;
      this.addEditIndirectTax();
    },
    uploadButton() {
      this.getOpenKmAuthenticate();
    },
    addEditIndirectTax() {
      const payload = {
        pp_party_indirect_tax_id: this.ppPartyIndirectTaxId,
        party_indirect_tax_id: this.partyIndirectTaxId,
        party_id: store.state.auth.partyId,
        tax_group_id: this.taxGroup.value,
        tax_num: this.gstinTanNo,
        issuing_authority: this.issuingAuthority,
        active: this.active,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/addEditIndirectTax", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          if (response.status === 200) {
            if (!this.showSaveButton) {
              this.showUploadButton = true;
            }
            this.showNextButton = false;
            if (this.isRecordSaved) {
              this.editMode = true;
            } else {
              this.editMode = false;
            }
            this.isSuccess = true;
            if (!this.editMode) {
              this.showAlert = true;
            } else {
              this.showAlert = false;
            }
            this.responseMsg = response.data.message;
            if (!this.ppPartyIndirectTaxId) {
              this.ppPartyIndirectTaxId = this.ppIndirectTaxId;
            }
            const uploadEnableFlag = this.showSaveButton ? false : true;
            this.$emit("updatedindirectTaxResponse", uploadEnableFlag);
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .then(() => {
          if (this.indirectTaxDetails) {
            this.getPpIdIndirectTaxByDetails();
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    getTaxGroupLov() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getTaxGroupLov", "INDIRECTTAX")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            const results = resp.data.data;
            const typeList = results.map((type) => {
              return {
                value: type.tax_group_id,
                text: type.tax_group,
              };
            });
            this.taxGroupList = typeList;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getOpenKmAuthenticate() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("uploadDocument/getOpenKmAuthenticate")
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.openKmAuth = response.data.data.token;
            this.selectedInvoiceData = {
              //   ...this.invoiceDetails,
              nfa_cat_vset: null,
              nfa_cat: null,
              nfa_sub_cat_vset: null,
              nfa_sub_cat: null,
            };
            this.showHideHistory(true, "uploadDocument");
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    showHideHistory(flag) {
      this.showHistory = flag;
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: 326,
        sub_menu_id: this.subMenuId,
        record_id: this.ppId,
      };
      this.$store
        .dispatch("uploadDocument/getUploadedDocDtl", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map((data) => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id,
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    getPpIdIndirectTaxByDetails() {
      const payload = {
        party_indirect_tax_id: this.indirectTaxDetails.party_indirect_tax_id,
      };
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getPpIdIndirectTaxByDetails", payload)
        .then((response) => {
          this.$store.dispatch("shared/setLoader", false);
          if (response.status === 200) {
            this.ppId = response.data.data.pp_id;
            this.$emit("deletePartyIndirectTaxById", this.ppId);
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
    changeUploadFlag(value) {
      this.uploadStatusFlag = value;
      this.showSaveButton = true;
      this.showUploadButton = false;
      this.$emit("saveButtonCloseModal");
    },
    getSubMenuById() {
      this.$store.dispatch("shared/setLoader", true);
      this.$store
        .dispatch("party/getSubMenuById")
        .then((resp) => {
          this.$store.dispatch("shared/setLoader", false);
          if (resp.status === 200) {
            this.subMenuId = resp.data.data.pp_party_indirect_tax_sub_menu;
          }
        })
        .catch(() => {
          this.$store.dispatch("shared/setLoader", false);
        });
    },
  },
};
</script>
